/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    PageCustomizationsOptions
} from "sirius-platform-support-library/models/page-customizations/page-customization.models";
import {
    IPageCustomizationsHandler,
    IPageCustomizationsHandlerTypeName
} from "sirius-platform-support-library/shared/page-customizations/page-customizations-handler.interface";
import {
    IPageCustomizationsService
} from "sirius-platform-support-library/shared/page-customizations/page-customizations-service.interface";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {InternalCustomizationsMapper} from "./options/internal-customizations.mapper";
import {
    IPlatformBrowserNavigationEventsReceiver
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";
import {AggregatedPageCustomizationsProvider} from "./providers/aggregated-page-customizations.provider";
import {SectionMarkerCodes} from "sirius-platform-support-library/shared/page-customizations/section-marker-codes";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    AllPageCustomizationTypes,
    PageCustomizationsConstants
} from "sirius-platform-support-library/shared/page-customizations/page-customizations.constants";
import {Customizations} from "./options/customizations.constants";
import {InternalCustomizationOptions} from "./options/internal-customization.options";
import {RoutingUtilities} from "sirius-platform-support-library/utilities/routing-utilities";
import {DynamicMenuService} from "../dynamic-menu/dynamic-menu.service";
import {DynamicSectionCodeMapper} from "../dynamic-menu/mappers/dynamic-section-code.mapper";
import {DynamicMenuSection} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-section.enum";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IAuthorizationService
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";
import _ from "lodash";


export const PageCustomizationsServiceTypeName = 'PageCustomizationsService';

export class PageCustomizationsService implements IPageCustomizationsService, IPlatformBrowserNavigationEventsReceiver, IAfterPlatformReadyInit {
    private static readonly DEFAULT_BACKGROUND_COLOR = 'transparent';

    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly aggregatedPageCustomizationsProvider: AggregatedPageCustomizationsProvider;
    private readonly customizationsMapper: InternalCustomizationsMapper;
    private readonly dynamicMenuService: DynamicMenuService;
    private readonly serviceCollection: IServiceCollection;

    private handlers: Record<string, Set<IPageCustomizationsHandler>> = {};
    private previousRoute?: string;
    private currentCustomizations?: PageCustomizationsOptions;
    private readonly DEFAULT_CUSTOMIZATIONS: PageCustomizationsOptions;

    private readonly taskQueue: any[] = [];
    private _defaultCustomizations: PageCustomizationsOptions;

    public constructor(
        window: Window,
        tenant: ITenant,
        aggregatedPageCustomizationsProvider: AggregatedPageCustomizationsProvider,
        customizationsMapper: InternalCustomizationsMapper,
        dynamicMenuService: DynamicMenuService,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.tenant = tenant;
        this.aggregatedPageCustomizationsProvider = aggregatedPageCustomizationsProvider;
        this.customizationsMapper = customizationsMapper;
        this.dynamicMenuService = dynamicMenuService;
        this.serviceCollection = serviceCollection;

        this.DEFAULT_CUSTOMIZATIONS = this.mapDefaultCustomizations();
        this._defaultCustomizations = _.cloneDeep(this.DEFAULT_CUSTOMIZATIONS);
    }

    public static build(
        window: Window,
        tenant: ITenant,
        aggregatedPageCustomizationsProvider: AggregatedPageCustomizationsProvider,
        customizationsMapper: InternalCustomizationsMapper,
        dynamicMenuService: DynamicMenuService,
        authorizationService: IAuthorizationService,
        serviceCollection: IServiceCollection
    ): PageCustomizationsService {
        let instance = ObjectUtility.getFromObjectPath<PageCustomizationsService>(PageCustomizationsConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new PageCustomizationsService(
                window,
                tenant,
                aggregatedPageCustomizationsProvider,
                customizationsMapper,
                dynamicMenuService,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(PageCustomizationsConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IPageCustomizationsService {
        return ObjectUtility.getFromObjectPath<IPageCustomizationsService>(PageCustomizationsConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        setInterval(async () => {
            await this.processTaskQueue();
        }, 0);

        for (const handler of this.serviceCollection.resolveAll<IPageCustomizationsHandler>(IPageCustomizationsHandlerTypeName)) {
            await this.registerHandler(handler);
        }

        this.aggregatedPageCustomizationsProvider.onRefreshRequested(this.onRefreshRequested.bind(this));
        this.dynamicMenuService.onMenuVisibilityStateOverridden(this.onRefreshRequested.bind(this));
    }

    public async apply(customizations: PageCustomizationsOptions, saveAsDefault: boolean = false): Promise<void> {
        if (!customizations) {
            return;
        }
        this.currentCustomizations = _.cloneDeep(customizations);
        if (saveAsDefault) {
            this._defaultCustomizations = _.cloneDeep(customizations);
        }
        const sections = SectionMarkerCodes.ALL_MARKERS.map(sectionMarkerCode => {
            return this.customizationsMapper.map(sectionMarkerCode, customizations);
        }).filter(s => s);
        for (const options of sections) {
            await this.applyDynamicMenu(options);
            const others: Record<string, AllPageCustomizationTypes> = {};
            sections.filter(m => m.sectionMarkerCode !== options.sectionMarkerCode).forEach((opt: InternalCustomizationOptions) => {
                others[opt.sectionMarkerCode] = opt?.customizations;
            });

            const handlers = this.handlers[options.sectionMarkerCode]?.values() ?? [];
            for (const handler of handlers) {
                await this.applySpecificSection(handler, options, others);
            }
        }
    }

    public async restore(clean: boolean = false): Promise<void> {
        await this.apply(clean ? this.DEFAULT_CUSTOMIZATIONS : this._defaultCustomizations, clean);
    }

    public async refresh(): Promise<void> {
        const route = this.window.location.pathname;
        await this.applyMatchingRouteCustomization(route);
    }

    public async registerHandler(handler: IPageCustomizationsHandler): Promise<void> {
        if (!handler) {
            return;
        }
        try {
            const sectionMarkerCode = handler.getSectionMarkerCode();
            // eslint-disable-next-line no-prototype-builtins
            if (!this.handlers.hasOwnProperty(sectionMarkerCode)) {
                this.handlers[sectionMarkerCode] = new Set<IPageCustomizationsHandler>();
            }
            this.handlers[sectionMarkerCode].add(handler);
            const internalCustomizations = this.customizationsMapper.map(sectionMarkerCode, this.currentCustomizations ?? this._defaultCustomizations ?? this.DEFAULT_CUSTOMIZATIONS);
            if (!internalCustomizations) {
                return;
            }
            const others: Record<string, AllPageCustomizationTypes> = {};
            SectionMarkerCodes.ALL_MARKERS.filter(m => m !== sectionMarkerCode).forEach((smc: string) => {
                const internalCustomizations = this.customizationsMapper.map(smc, this.currentCustomizations ?? this._defaultCustomizations ?? this.DEFAULT_CUSTOMIZATIONS);
                if (!internalCustomizations) {
                    return;
                }
                others[smc] = internalCustomizations?.customizations;
            });
            await this.applyDynamicMenu(internalCustomizations);
            await this.applySpecificSection(handler, internalCustomizations, others);
        } catch (e) {
            console.error(e);
        }
    }

    public async onBeforeNavigate(): Promise<void> {
    }

    public async onAfterNavigate(url: string): Promise<void> {
        url = RoutingUtilities.getPathName(url);
        if (!this.previousRoute || this.previousRoute !== url) {
            this.previousRoute = url; // stop race condition
            await this.applyMatchingRouteCustomization(url);
        }
        this.previousRoute = url;
    }

    private async applyMatchingRouteCustomization(route: string): Promise<void> {
        const defaultCustomizations = _.cloneDeep(this._defaultCustomizations ?? this.DEFAULT_CUSTOMIZATIONS);
        let customizations = await this.aggregatedPageCustomizationsProvider.getCustomization(route);
        if (!customizations) {
            customizations = defaultCustomizations;
        } else {
            customizations = _.merge({}, defaultCustomizations, customizations);
        }
        this.taskQueue.push({
            target: this.apply,
            context: this,
            args: [customizations, false]
        });
    }

    private async applySpecificSection(handler: IPageCustomizationsHandler, internalCustomizations: InternalCustomizationOptions, otherSections: Record<string, AllPageCustomizationTypes>) {
        try {
            const clonedCustomizations = _.cloneDeep(internalCustomizations.customizations);
            if (clonedCustomizations) {
                const dynamicMenuSectionCode = DynamicSectionCodeMapper.MAP[internalCustomizations.sectionMarkerCode] as DynamicMenuSection;
                if (dynamicMenuSectionCode) {
                    const overriddenVisibility = this.dynamicMenuService.getMenuOverriddenVisibilityState(dynamicMenuSectionCode);
                    (clonedCustomizations as any).visible = ObjectUtility.isDefined(overriddenVisibility) ? overriddenVisibility : ((clonedCustomizations as any)?.visible ?? true);
                }
            }
            handler.apply(internalCustomizations.sectionMarkerCode, clonedCustomizations, _.cloneDeep(otherSections)).catch(e => console.error(e));
        } catch (e) {
            console.error(e);
        }
    }

    private async applyDynamicMenu(internalCustomizations: InternalCustomizationOptions): Promise<void> {
        try {
            const dynamicMenuSectionCode = DynamicSectionCodeMapper.MAP[internalCustomizations.sectionMarkerCode] as DynamicMenuSection;
            if (!dynamicMenuSectionCode) {
                return;
            }
            // @ts-ignore
            const menu = internalCustomizations?.customizations?.menu;
            if (!menu?.items) {
                await this.dynamicMenuService.reset(dynamicMenuSectionCode);
                return;
            }
            await this.dynamicMenuService.populateCustomizedMenuItems(dynamicMenuSectionCode, menu?.items, menu?.localizedResources, this);
        } catch (e) {
            console.error(e);
        }
    }

    private async onRefreshRequested(): Promise<void> {
        await this.refresh();
    }

    private mapDefaultCustomizations(): PageCustomizationsOptions {
        const defaultTenantContextCustomizations = {
            header: {
                visible: this.tenant.getContext()?.site?.top?.header?.visible,
                features: (this.tenant.getContext()?.site?.top?.header?.features ?? []).map(f => {
                    return {code: f.code, enabled: f.enabled, authorization: f.authorization};
                })
            },
            navbar: {
                visible: this.tenant.getContext()?.site?.top?.navbar?.visible,
                features: (this.tenant.getContext()?.site?.top?.navbar?.features ?? []).map(f => {
                    return {code: f.code, enabled: f.enabled, authorization: f.authorization};
                })
            },
            quicknav: {
                visible: this.tenant.getContext()?.site?.top?.quicknav?.visible,
                features: (this.tenant.getContext()?.site?.top?.quicknav?.features ?? []).map(f => {
                    return {code: f.code, enabled: f.enabled, authorization: f.authorization};
                })
            },
            footer: {
                visible: this.tenant.getContext()?.site?.footer?.visible,
                features: (this.tenant.getContext()?.site?.footer?.features ?? []).map(f => {
                    return {code: f.code, enabled: f.enabled, authorization: f.authorization};
                })
            }
        };

        const customizations: PageCustomizationsOptions = Object.assign({}, Customizations.DEFAULTS, defaultTenantContextCustomizations);
        const context = this.tenant.getContext();
        const siteBackgroundColor = context?.site?.backgroundColor;
        customizations.header.backgroundColor = context?.site?.top?.header?.backgroundColor ?? siteBackgroundColor ?? PageCustomizationsService.DEFAULT_BACKGROUND_COLOR;
        customizations.navbar.backgroundColor = context?.site?.top?.navbar?.backgroundColor ?? siteBackgroundColor ?? PageCustomizationsService.DEFAULT_BACKGROUND_COLOR;
        customizations.content.backgroundColor = context?.site?.backgroundColor ?? PageCustomizationsService.DEFAULT_BACKGROUND_COLOR;
        customizations.footer.backgroundColor = context?.site?.footer?.backgroundColor ?? siteBackgroundColor ?? PageCustomizationsService.DEFAULT_BACKGROUND_COLOR;
        return customizations;
    }

    private async processTaskQueue(): Promise<void> {
        if (this.taskQueue.length === 0) {
            return;
        }
        const task = this.taskQueue.shift();
        if (!task?.target) {
            return;
        }
        try {
            await task.target.apply(task.context, task.args);
        } catch (e) {
            console.error(e);
        }
        await this.processTaskQueue();
    }
}

