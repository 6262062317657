/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {MicroFrontendComponent} from "../models/micro-frontend-component.model";
import {UniqueNameGenerator} from "./unique-name-generator";
import {
    IDependencyContainer
} from "sirius-platform-support-library/dependency-injection/generic/dependency-container.interface";
import {
    Footer,
    Header,
    MicroFrontend,
    MicroFrontendResource,
    Navbar,
    QuickNav,
    Route,
    SiteConfig,
    TenantContext,
    Visibility,
    Widgets
} from "sirius-platform-support-library/tenants/tenant-context";
import {
    ActionTypeEnum,
    Data,
    Feature,
    Features,
    Items,
    LocalizedResources
} from "sirius-platform-support-library/models/common";
import {
    MicroFrontendProperties
} from "sirius-platform-support-library/models/micro-frontends/micro-frontend-properties";
import {
    IMicroFrontendLoaderFactory
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loader-factory.interface";
import {
    MicroFrontendLoadingOptions
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loading.options";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    VirtualRoutesProviderHolder
} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-routes-provider.holder";
import {
    StandardFeatures
} from "sirius-platform-support-library/shared/micro-frontends/features/standard-features.constants";
import {StandardActions} from "sirius-platform-support-library/shared/micro-frontends/actions/standard-actions";

export const SiteConfigToMicroFrontendsMapperTypeName = 'SiteConfigToMicroFrontendsMapper';

export class SiteConfigToMicroFrontendsMapper extends VirtualRoutesProviderHolder {
    private readonly uniqueNameGenerator: UniqueNameGenerator;
    private readonly microFrontendLoaderFactory: IMicroFrontendLoaderFactory;
    private readonly dependencyContainer: IDependencyContainer;

    public constructor(
        microFrontendLoaderFactory: IMicroFrontendLoaderFactory,
        dependencyContainer: IDependencyContainer,
        serviceCollection: IServiceCollection
    ) {
        super(serviceCollection);

        this.uniqueNameGenerator = new UniqueNameGenerator();
        this.microFrontendLoaderFactory = microFrontendLoaderFactory;
        this.dependencyContainer = dependencyContainer;
    }

    public mapSectionsAndRoutes(tenantContext: TenantContext, siteConfig: SiteConfig): Record<string, MicroFrontendComponent> {
        const collection: Record<string, MicroFrontendComponent> = {};

        const headerMicroFrontend = this.mapSection<Header>(siteConfig.top?.header, UniqueNameGenerator.HEADER_ROLE, tenantContext);
        if (headerMicroFrontend) {
            collection[headerMicroFrontend.uniqueName] = headerMicroFrontend;
        }

        const navbarMicroFrontend = this.mapSection<Navbar>(siteConfig.top?.navbar, UniqueNameGenerator.NAVBAR_ROLE, tenantContext);
        if (navbarMicroFrontend) {
            collection[navbarMicroFrontend.uniqueName] = navbarMicroFrontend;
        }

        const quickNavMicroFrontend = this.mapSection<QuickNav>(siteConfig?.top?.quicknav, UniqueNameGenerator.QUICKNAV_ROLE, tenantContext);
        if (quickNavMicroFrontend) {
            collection[quickNavMicroFrontend.uniqueName] = quickNavMicroFrontend;
        }

        siteConfig.routing.routes.forEach(route => {
            const routeMicroFrontend = this.mapRoute<Route>(route, UniqueNameGenerator.CONTENT_ROLE, tenantContext);
            if (routeMicroFrontend) {
                collection[routeMicroFrontend.uniqueName] = routeMicroFrontend;
            }
        });

        const footerMicroFrontend = this.mapSection<Footer>(siteConfig.footer, UniqueNameGenerator.FOOTER_ROLE, tenantContext);
        if (footerMicroFrontend) {
            collection[footerMicroFrontend.uniqueName] = footerMicroFrontend;
        }

        this.addVirtualRoutes(collection);

        return collection;
    }

    private mapRoute<T extends Route & Data & LocalizedResources>(route: T, role: string, tenantContext: TenantContext): MicroFrontendComponent {
        const microFrontendComponent = this.mapMicroFrontendResource(route.microFrontend, role, tenantContext);
        microFrontendComponent.properties.dependencies = this.dependencyContainer;
        microFrontendComponent.properties.localization = tenantContext.localization;
        microFrontendComponent.properties.data = route.data;
        microFrontendComponent.properties.localizedResources = route.localizedResources;
        return microFrontendComponent;
    }

    private mapSection<T extends MicroFrontend & Visibility & Features & Data & Items & Widgets & LocalizedResources>(section: T, role: string, tenantContext: TenantContext): MicroFrontendComponent {
        if (!section?.microFrontend) {
            return undefined;
        }
        const microFrontendComponent = this.mapMicroFrontendResource(section.microFrontend, role, tenantContext);
        microFrontendComponent.properties.dependencies = this.dependencyContainer;
        microFrontendComponent.properties.localization = tenantContext.localization;
        microFrontendComponent.properties.data = section.data;
        microFrontendComponent.properties.features = section.features;
        microFrontendComponent.properties.items = section.items;
        microFrontendComponent.properties.widgets = section.widgets;
        microFrontendComponent.properties.localizedResources = section.localizedResources;
        switch (role) {
            case UniqueNameGenerator.HEADER_ROLE:
                this.applyDefaultFeaturesForHeader(microFrontendComponent?.properties?.features ?? []);
                break;
            case UniqueNameGenerator.NAVBAR_ROLE:
                this.applyDefaultFeaturesForNavbar(microFrontendComponent?.properties?.features ?? []);
                break;
            case UniqueNameGenerator.QUICKNAV_ROLE:
                this.applyDefaultFeaturesForQuickNav(microFrontendComponent?.properties?.features ?? []);
                break;
            case UniqueNameGenerator.FOOTER_ROLE:
                this.applyDefaultFeaturesForFooter(microFrontendComponent?.properties?.features ?? []);
                break;
        }
        return microFrontendComponent;
    }

    private mapMicroFrontendResource(microFrontend: MicroFrontendResource, role: string, tenantContext: TenantContext): MicroFrontendComponent {
        let defaultIconSet;
        switch (role) {
            case UniqueNameGenerator.HEADER_ROLE:
                defaultIconSet = tenantContext?.site?.top?.header?.iconSet?.toLowerCase() ?? tenantContext?.behaviour?.iconsRegistry?.defaultIconSet?.toLowerCase() ?? 'dds';
                break;
            case UniqueNameGenerator.NAVBAR_ROLE:
                defaultIconSet = tenantContext?.site?.top?.navbar?.iconSet?.toLowerCase() ?? tenantContext?.behaviour?.iconsRegistry?.defaultIconSet?.toLowerCase() ?? 'dds';
                break;
            case UniqueNameGenerator.QUICKNAV_ROLE:
                defaultIconSet = tenantContext?.site?.top?.quicknav?.iconSet?.toLowerCase() ?? tenantContext?.behaviour?.iconsRegistry?.defaultIconSet?.toLowerCase() ?? 'dds';
                break;
            case UniqueNameGenerator.FOOTER_ROLE:
                defaultIconSet = tenantContext?.site?.footer?.iconSet?.toLowerCase() ?? tenantContext?.behaviour?.iconsRegistry?.defaultIconSet?.toLowerCase() ?? 'dds';
                break;
            default:
                defaultIconSet = 'dds';
        }
        const uniqueName = this.uniqueNameGenerator.getUniqueName(microFrontend, role);
        const loadingOptions = this.mapMicroFrontendResourceToLoadingOptions(microFrontend);
        const loader = this.microFrontendLoaderFactory.get(microFrontend.type);
        return {
            uniqueName: uniqueName,
            loadingOptions: loadingOptions,
            loaderFunction: loader.load.bind(loader),
            properties: {
                shadowed: microFrontend.shadowed,
                defaultIconSet: defaultIconSet
            } as MicroFrontendProperties
        };
    }

    private mapMicroFrontendResourceToLoadingOptions(resource: MicroFrontendResource): MicroFrontendLoadingOptions {
        return {
            remoteEntry: resource.url,
            remoteName: resource.name,
            component: resource.component
        }
    }

    private addVirtualRoutes(collection: Record<string, MicroFrontendComponent>): void {
        const virtualRoutes = this.getVirtualRoutes();
        virtualRoutes.forEach(virtualRoute => {
            if (!virtualRoute.code || !virtualRoute.route || !virtualRoute.loadingFunction) {
                return;
            }
            const virtualMicroFrontend = {
                uniqueName: this.getVirtualRouteUniqueName(virtualRoute),
                loaderFunction: virtualRoute.loadingFunction,
                properties: {
                    shadowed: true
                } as MicroFrontendProperties
            };

            if (virtualMicroFrontend) {
                collection[virtualMicroFrontend.uniqueName] = virtualMicroFrontend;
            }
        });
    }

    private applyDefaultFeaturesForHeader(features: Feature[]): void {
        const hasLogoFeature = features.some(f => f.code === StandardFeatures.HEADER_LOGO);
        if (!hasLogoFeature) {
            features.push(
                {
                    code: StandardFeatures.HEADER_LOGO,
                    localizationCode: 'PAGE.TITLE',
                    enabled: true,
                    options: {
                        imageSrc: '/assets/dell_round_logo.svg',
                        mobileImageSrc: '/assets/dell_round_logo.svg'
                    },
                    actions: [
                        {
                            code: StandardActions.LOGO_LINK,
                            localizationCode: 'PAGE.TITLE',
                            type: ActionTypeEnum.URL,
                            action: '/'
                        }
                    ]
                }
            );
        }
        const hasTitleFeature = features.some(f => f.code === StandardFeatures.HEADER_TITLE);
        if (!hasTitleFeature) {
            features.push({
                code: StandardFeatures.HEADER_TITLE,
                localizationCode: 'PAGE.TITLE',
                enabled: true,
                actions: [
                    {
                        code: StandardActions.TITLE_LINK,
                        localizationCode: 'PAGE.TITLE',
                        type: ActionTypeEnum.URL,
                        action: '/'
                    }
                ],
            });
        }
        const hasMenuFeature = features.some(f => f.code === StandardFeatures.HEADER_MENU);
        if (!hasMenuFeature) {
            features.push({
                code: StandardFeatures.HEADER_MENU,
                enabled: true,
            });
        }
        const hasWidgetsFeature = features.some(f => f.code === StandardFeatures.HEADER_WIDGETS);
        if (!hasWidgetsFeature) {
            features.push({
                code: StandardFeatures.HEADER_WIDGETS,
                enabled: true,
            });
        }
    }

    private applyDefaultFeaturesForNavbar(features: Feature[]): void {
        const hasHomeFeature = features.some(f => f.code === StandardFeatures.NAVBAR_HOME);
        if (!hasHomeFeature) {
            features.push({
                code: StandardFeatures.NAVBAR_HOME,
                enabled: true
            });
        }
        const hasMenuFeature = features.some(f => f.code === StandardFeatures.NAVBAR_MENU);
        if (!hasMenuFeature) {
            features.push({
                code: StandardFeatures.NAVBAR_MENU,
                enabled: true
            });
        }
        const hasWidgetsFeature = features.some(f => f.code === StandardFeatures.NAVBAR_WIDGETS);
        if (!hasWidgetsFeature) {
            features.push({
                code: StandardFeatures.NAVBAR_WIDGETS,
                enabled: true
            });
        }
    }

    private applyDefaultFeaturesForQuickNav(features: Feature[]): void {
        const hasMenuFeature = features.some(f => f.code === StandardFeatures.QUICKNAV_MENU);
        if (!hasMenuFeature) {
            features.push({
                code: StandardFeatures.QUICKNAV_MENU,
                enabled: false
            });
        }
    }

    private applyDefaultFeaturesForFooter(features: Feature[]): void {
        const hasMenuFeature = features.some(f => f.code === StandardFeatures.NAVBAR_MENU);
        if (!hasMenuFeature) {
            features.push({
                code: StandardFeatures.FOOTER_MENU,
                enabled: true
            });
        }
        const hasWidgetsFeature = features.some(f => f.code === StandardFeatures.NAVBAR_WIDGETS);
        if (!hasWidgetsFeature) {
            features.push({
                code: StandardFeatures.FOOTER_WIDGETS,
                enabled: true
            });
        }
    }
}
