/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const ALIASES: Record<string, string[]> = {
    "laptop-generic": ["computer"],
    "globe-simple": ["world"],
    "chevron-up": ["angle"],
    "mail": ["envelope"],
    "close-cir": ["times-circle"],
    "refresh-spin": ["refresh"],
    "close-x": ["window-close"],
    "task-check": ["success-standard"],
    "alert-notice": ["exclamation-triangle", "warning-standard"],
    "alert-error": ["error-standard", "info-standard"],
    "cube": ["block"]
};
