/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    PageCustomizationsOptions
} from "sirius-platform-support-library/models/page-customizations/page-customization.models";
import {SectionMarkerCodes} from "sirius-platform-support-library/shared/page-customizations/section-marker-codes";
import {InternalCustomizationOptions} from "./internal-customization.options";

export const InternalCustomizationsMapperTypeName = 'InternalCustomizationsMapper';

export class InternalCustomizationsMapper {
    private readonly internalMap: Record<string, string> = {};

    public constructor() {
        this.internalMap[SectionMarkerCodes.BANNER_ALERTS_MARKER] = 'bannerAlerts';
        this.internalMap[SectionMarkerCodes.TOASTS_MARKER] = 'toasts';
        this.internalMap[SectionMarkerCodes.HEADER_MARKER] = 'header';
        this.internalMap[SectionMarkerCodes.NAVBAR_MARKER] = 'navbar';
        this.internalMap[SectionMarkerCodes.QUICKNAV_MARKER] = 'quicknav';
        this.internalMap[SectionMarkerCodes.CONTENT_MARKER] = 'content';
        this.internalMap[SectionMarkerCodes.FOOTER_MARKER] = 'footer';
    }

    public map(sectionMarkerCode: string, customizations: PageCustomizationsOptions): InternalCustomizationOptions {
        const sectionCustomizations = (customizations || {})[this.internalMap[sectionMarkerCode] || ''];
        return {
            sectionMarkerCode: sectionMarkerCode,
            customizations: sectionCustomizations
        }
    }
}
