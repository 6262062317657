/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    Action,
    ActionTargetEnum,
    ActionTypeEnum,
    ButtonStyle,
    ButtonType,
    RequiredAuthorization
} from "sirius-platform-support-library/models/common";
import {BannerAlertAction} from "sirius-platform-support-library/shared/banner-alerts/banner-alert-action";
import {ILocalizable} from "sirius-platform-support-library/shared/localization/localizable.interface";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";
import {BannerAlertActionTemplate} from "./templates/banner-alert-action.template";
import {IActionHandler} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {IButtonStyleProcessor} from "../common-ui/buttons/button-style.processor.interface";

export class RenderableBannerAlertAction implements BannerAlertAction, ILocalizable {
    public static readonly ACTION_CODE_PLACEHOLDER = '{{actionCode}}';
    public static readonly CLASSES_PLACEHOLDER = '{{classes}}';
    public static readonly LOCALIZED_TEXT_PLACEHOLDER = '{{localizedText}}';
    public type: ActionTypeEnum;
    public code: string;
    public localizationCode: string;
    public localizationActionCode?: string;
    public action: any;
    public target?: ActionTargetEnum;
    public data?: any;
    public dismissAfterAction: boolean;
    public buttonType: ButtonType;
    public buttonStyle: ButtonStyle;
    public localizedText: string;
    public localizedAction?: string;
    public isActionTranslatable?: boolean = false;
    public authorization?: RequiredAuthorization;
    private readonly translationService: ITranslationService;
    private readonly actionHandler: IActionHandler;
    private readonly buttonStyleProcessor: IButtonStyleProcessor;

    public constructor(
        action: BannerAlertAction,
        translationService: ITranslationService,
        actionHandler: IActionHandler,
        buttonStyleProcessor: IButtonStyleProcessor,
    ) {
        this.translationService = translationService;
        this.actionHandler = actionHandler;
        this.buttonStyleProcessor = buttonStyleProcessor;

        this.type = action.type;
        this.code = action.code;
        this.action = action.action;
        this.target = action.target;
        this.data = action.data;

        this.dismissAfterAction = action.dismissAfterAction;

        this.buttonType = action.buttonType ?? ButtonType.FLAT;
        this.buttonStyle = action.buttonStyle ?? ButtonStyle.UNSET;

        this.localizationCode = action.localizationCode;
        this.localizationActionCode = action.action;
        this.localizedText = this.localizationCode;
        this.localizedAction = this.localizationActionCode;
        this.authorization = action.authorization;
        this.isActionTranslatable = action?.isActionTranslatable;
    }

    public async localize(): Promise<void> {
        if (!this.localizationCode) {
            return;
        }
        try {
            this.localizedText = await this.translationService.translate(this.localizationCode!!);
            this.localizedAction = await this.translationService.translate(this.localizationActionCode!!);
        } catch (e) {
            console.warn('Could not localize banner alert action', this, e);
        }
    }

    public toHtml(): string {
        return BannerAlertActionTemplate
            .replaceAll(RenderableBannerAlertAction.ACTION_CODE_PLACEHOLDER, this.code)
            .replaceAll(RenderableBannerAlertAction.CLASSES_PLACEHOLDER, this.getSuitableClasses().join(' '))
            .replaceAll(RenderableBannerAlertAction.LOCALIZED_TEXT_PLACEHOLDER, this.localizedText);
    }

    public toBannerAlertAction(): BannerAlertAction {
        return {
            type: this.type,
            code: this.code,
            localizationCode: this.localizationCode,
            action: this.action,
            target: this.target,
            data: this.data,
            dismissAfterAction: this.dismissAfterAction,
            buttonType: this.buttonType,
            buttonStyle: this.buttonStyle,
            authorization: this.authorization
        }
    }

    private getSuitableClasses(): string[] {
        const classes = ['btn', 'btn-sm'];
        const extendedClasses = this.buttonStyleProcessor.getClasses(this);
        if (extendedClasses?.length) {
            classes.push(...extendedClasses);
        }

        const constructedAction = {
            type: this.type,
            code: this.code,
            localizationCode: this.localizationCode,
            authorization: this.authorization
        } as Action;

        const isActionHidden = !this.actionHandler.isActionAuthorized(constructedAction) && this.actionHandler.getButtonStyle(constructedAction) === 'hidden';
        const isActionDisabled = !this.actionHandler.isActionAuthorized(constructedAction) && this.actionHandler.getButtonStyle(constructedAction) === 'disabled';

        const hiddenAction = isActionHidden ? 'action-hidden' : '';
        const disabledAction = isActionDisabled ? 'action-disabled' : '';

        if (hiddenAction) {
            classes.push(hiddenAction);
        }

        if (disabledAction) {
            classes.push(disabledAction);
        }

        return classes;
    }
}
