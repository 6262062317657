/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {QuickNavMenuService, QuickNavMenuServiceTypeName} from "./quicknav-menu.service";
import {
    IQuickNavMenuService,
    IQuickNavMenuServiceTypeName
} from "sirius-platform-support-library/shared/quicknav-menu/quicknav-menu-service.interface";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";
import {DynamicMenuService, DynamicMenuServiceTypeName} from "../dynamic-menu/dynamic-menu.service";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";

export class QuickNavMenuDependencyModule extends TypedDependencyModule {
    public register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IQuickNavMenuService, QuickNavMenuService>(DependencyType.SHARED, IQuickNavMenuServiceTypeName, QuickNavMenuServiceTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const dynamicMenuService = di.resolve<DynamicMenuService>(DynamicMenuServiceTypeName);
                return QuickNavMenuService.build(
                    eventBus,
                    dynamicMenuService
                );
            })
            .addInterfacesOnSingleton(QuickNavMenuServiceTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const quickNavMenuDependencyModule = new QuickNavMenuDependencyModule();
