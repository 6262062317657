/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IPlaceholderReplacer
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholder-replacer.interface";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";

export const LocalePlaceholderReplacerTypeName = 'LocalePlaceholderReplacer';

export class LocalePlaceholderReplacer implements IPlaceholderReplacer {
    private static readonly CODE = 'LOCALE_PLACEHOLDER_REPLACER';
    private static readonly LANGUAGE_CODE_REPLACER = '{languageCode}';
    private static readonly COUNTRY_CODE_REPLACER = '{countryCode}';
    private readonly localizationService: ILocalizationService;

    constructor(localizationService: ILocalizationService) {
        this.localizationService = localizationService;
    }

    public getPlaceholderReplacerCode(): string {
        return LocalePlaceholderReplacer.CODE;
    }

    public replace(input: string): string {
        const currentLocaleCode = this.localizationService.getCurrentLocaleCode();
        const currentLocaleCodeArray = currentLocaleCode.split("_");
        const languageCode = currentLocaleCodeArray[0];
        const countryCode = currentLocaleCodeArray[1];
        const result = input
            .replaceAll(new RegExp(LocalePlaceholderReplacer.LANGUAGE_CODE_REPLACER, 'g'), languageCode)
            .replaceAll(new RegExp(LocalePlaceholderReplacer.COUNTRY_CODE_REPLACER, 'g'), countryCode);
        return result;
    }
}
