/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IEnvironmentSpecificConfigurator
} from "sirius-platform-support-library/shared/environment/environment-specific-configurator.interface";
import {
    IEnvironmentCodeProvider
} from "sirius-platform-support-library/shared/environment/environment-code-provider.interface";
import {EnvironmentDefinition, EnvironmentType} from "sirius-platform-support-library/tenants/tenant-context";

export const EnvironmentSpecificConfiguratorTypeName = 'EnvironmentSpecificConfigurator';

export class EnvironmentSpecificConfigurator implements IEnvironmentSpecificConfigurator {
    private readonly environmentCodeProvider: IEnvironmentCodeProvider

    constructor(environmentCodeProvider: IEnvironmentCodeProvider) {
        this.environmentCodeProvider = environmentCodeProvider;
    }

    public applyConfigurationWithGivenEnvironments(environments: Record<string, EnvironmentDefinition>, targetConfig: any, sourceConfig: any, environmentType?: EnvironmentType | string) {
        const code = this.environmentCodeProvider.getCodeWithGivenEnvironments(environments, environmentType);
        return this.applyConfigurationWithGivenEnvironmentCode(code, targetConfig, sourceConfig);
    }

    public applyConfiguration(targetConfig: any, sourceConfig: any, environmentType?: EnvironmentType | string): any {
        const code = this.environmentCodeProvider.getCode(environmentType);
        return this.applyConfigurationWithGivenEnvironmentCode(code, targetConfig, sourceConfig);
    }

    private applyConfigurationWithGivenEnvironmentCode(code: string | undefined, targetConfig: any, sourceConfig: any): any {
        try {
            code = code?.toLowerCase();
            if (!code) {
                return targetConfig;
            }
            let envCode = "";
            for (const property in sourceConfig) {
                if (property.toLowerCase() === code) {
                    envCode = property;
                    break;
                }
            }
            const envSpecific = sourceConfig[envCode];
            if (!envSpecific) {
                return;
            }
            return Object.assign(targetConfig, envSpecific);
        } catch (e) {
            return targetConfig;
        }
    }
}
