/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {BannerAlertAction} from "sirius-platform-support-library/shared/banner-alerts/banner-alert-action";
import {ActionValidator} from "sirius-platform-support-library/shared/actions/validators/action.validator";
import {ActionTypeEnum} from "sirius-platform-support-library/models/common";
import {ValidationError} from "sirius-platform-support-library/validators/validation-error";

export class BannerAlertActionValidator {
    private readonly actionValidator: ActionValidator;

    public constructor() {
        this.actionValidator = new ActionValidator()
    }

    public validate(action: BannerAlertAction): void {
        const errors: Error[] = [];
        if (action.type === ActionTypeEnum.CALLBACK) {
            errors.push(new Error('type: Unsupported type when action is used in banner alerts'));
        } else {
            try {
                this.actionValidator.validate(action);
            } catch (e) {
                errors.push(...e.errors);
            }
        }
        if (errors.length > 0) {
            throw new ValidationError('Validation errors detected for the given banner alert action', errors);
        }
    }
}
