/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {Tenant, TenantTypeName} from "./tenant";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {ITenantContextLoader, ITenantContextLoaderTypeName} from "./context/tenant-context.loader.interface";
import {TenantContextLoader, TenantContextLoaderTypeName} from "./context/tenant-context.loader";
import {SystemJs, SystemJsTypeName} from "../dynamic-imports/system-js";
import {
    ITenantCustomBootstrapCodeLoader,
    ITenantCustomBootstrapCodeLoaderTypeName
} from "./custom-code/tenant-custom-bootstrap-code.loader.interface";
import {
    TenantCustomBootstrapCodeLoader,
    TenantCustomBootstrapCodeLoaderTypeName
} from "./custom-code/tenant-custom-bootstrap-code.loader";
import {
    RuntimeConfigurationLoader,
    RuntimeConfigurationLoaderTypeName
} from "../runtime-configuration/runtime-configuration.loader";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";

export class TenantDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<ITenant, Tenant>(DependencyType.SHARED, ITenantTypeName, TenantTypeName, new Tenant())
            .addUsingFactoryAsSingleton<ITenantContextLoader, TenantContextLoader>(DependencyType.PRIVATE, ITenantContextLoaderTypeName, TenantContextLoaderTypeName, (di) => {
                const runtimeConfigurationLoader = di.resolve<RuntimeConfigurationLoader>(RuntimeConfigurationLoaderTypeName);
                const systemJs = di.resolve<SystemJs>(SystemJsTypeName);
                const sharedDependencyContainer = SharedDependencyContainer.getInstance();
                return new TenantContextLoader(
                    runtimeConfigurationLoader,
                    systemJs,
                    sharedDependencyContainer
                );
            })
            .addUsingFactoryAsSingleton<ITenantCustomBootstrapCodeLoader, TenantCustomBootstrapCodeLoader>(DependencyType.PRIVATE, ITenantCustomBootstrapCodeLoaderTypeName, TenantCustomBootstrapCodeLoaderTypeName, (di) => {
                const systemJs = di.resolve<SystemJs>(SystemJsTypeName);
                return new TenantCustomBootstrapCodeLoader(systemJs);
            })
    }
}

export const tenantDependencyModule = new TenantDependencyModule();
