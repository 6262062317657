/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {InternalNavigateToOptions} from "../internal-navigate-to.options";

export const IDefaultPagesOptionsStoreTypeName = 'IDefaultPagesOptionsStore';

export interface IDefaultPagesOptionsStore {
    store(options: InternalNavigateToOptions): Promise<string>;

    get(code: string): Promise<InternalNavigateToOptions | undefined>;

    remove(code: string): Promise<void>;
}
