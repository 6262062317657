/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {DefaultPagesService, DefaultPagesServiceTypeName} from "./default-pages.service";
import {IBeforePlatformReadyInitTypeName} from "../../initializer/before-platform-ready-init.interface";
import {
    IVirtualRoutesProviderTypeName
} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-routes-provider.interface";
import {
    IDefaultPagesOptionsStore,
    IDefaultPagesOptionsStoreTypeName
} from "./options/options-store/default-pages-options-store.interface";
import {
    DefaultPagesOptionsStore,
    DefaultPagesOptionsStoreTypeName
} from "./options/options-store/default-pages-options.store";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    NavigateToOptionsFactory,
    NavigateToOptionsFactoryTypeName
} from "./options/factory/navigate-to-options.factory";
import {
    ILocalizationService,
    ILocalizationServiceTypeName
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    PlatformTranslationService,
    PlatformTranslationServiceTypeName
} from "../../localization/translations/platform-translation.service";

export class DefaultPagesDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<NavigateToOptionsFactory>(DependencyType.PRIVATE, NavigateToOptionsFactoryTypeName, new NavigateToOptionsFactory())
            .addUsingFactoryAsSingleton<IDefaultPagesOptionsStore, DefaultPagesOptionsStore>(DependencyType.PRIVATE, IDefaultPagesOptionsStoreTypeName, DefaultPagesOptionsStoreTypeName, (di) => {
                return DefaultPagesOptionsStore.getInstance();
            })
            .addUsingFactoryAsSingleton<IDefaultPagesService, DefaultPagesService>(DependencyType.SHARED, IDefaultPagesServiceTypeName, DefaultPagesServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const defaultPagesOptionsStore = di.resolve<IDefaultPagesOptionsStore>(IDefaultPagesOptionsStoreTypeName);
                const navigateToOptionsFactory = di.resolve<NavigateToOptionsFactory>(NavigateToOptionsFactoryTypeName);
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);
                const platformTranslateService = di.resolve<PlatformTranslationService>(PlatformTranslationServiceTypeName);
                return DefaultPagesService.build(
                    window,
                    tenant,
                    navigateToOptionsFactory,
                    defaultPagesOptionsStore,
                    localizationService,
                    platformTranslateService,
                    di
                );
            })
            .addInterfacesOnSingleton(DefaultPagesServiceTypeName, IBeforePlatformReadyInitTypeName)
            .addInterfacesOnSingleton(DefaultPagesServiceTypeName, IVirtualRoutesProviderTypeName);
    }
}

export const defaultPagesDependencyModule = new DefaultPagesDependencyModule();
