/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    ILocalizationService,
    ILocalizationServiceTypeName
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    IPlaceholderReplacer,
    IPlaceholderReplacerTypeName
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholder-replacer.interface";
import {LocalePlaceholderReplacer, LocalePlaceholderReplacerTypeName} from "./replacers/locale-placeholder-replacer";
import {
    IPlaceholdersReplacerService,
    IPlaceholdersReplacerServiceTypeName
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholders-replacer-service.interface";
import {PlaceholdersReplacerService, PlaceholdersReplacerServiceTypeName} from "./placeholders-replacer-service";
import {
    DefaultPlaceholderReplacerPriorityAssigner,
    DefaultPlaceholderReplacerPriorityAssignerTypeName
} from "./placerholder-replacer-priority-assigner";
import {
    IPlaceholderReplacerPriorityAssigner,
    IPlaceholderReplacerPriorityAssignerTypeName
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholder-replacer-priority-assigner.interface";

export class PlaceholderReplacersDependencyModule extends TypedDependencyModule {
    public register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IPlaceholderReplacer, LocalePlaceholderReplacer>(DependencyType.SHARED, IPlaceholderReplacerTypeName, LocalePlaceholderReplacerTypeName, (di) => {
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);
                return new LocalePlaceholderReplacer(localizationService);
            })
            .addUsingInstanceAsSingleton<IPlaceholderReplacerPriorityAssigner, DefaultPlaceholderReplacerPriorityAssigner>(DependencyType.SHARED, IPlaceholderReplacerPriorityAssignerTypeName, DefaultPlaceholderReplacerPriorityAssignerTypeName, new DefaultPlaceholderReplacerPriorityAssigner())
            .addUsingFactoryAsSingleton<IPlaceholdersReplacerService, PlaceholdersReplacerService>(DependencyType.SHARED, IPlaceholdersReplacerServiceTypeName, PlaceholdersReplacerServiceTypeName, (di) => {
                return new PlaceholdersReplacerService(di);
            })
    }
}

export const placeholderReplacersDependencyModule = new PlaceholderReplacersDependencyModule();
