/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ISiteService} from "sirius-platform-support-library/shared/site/site.interface";
import {SiteConstants} from "sirius-platform-support-library/shared/site/constants";
import {
    LocalizableResource
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {PlatformTranslationService} from "../localization/translations/platform-translation.service";
import _ from "lodash";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    SelfContainedTranslationService
} from "sirius-platform-support-library/shared/localization/translations/self-contained-translation.service";


export const SiteServiceTypeName = 'SiteService';

export class SiteService implements ISiteService, IBeforePlatformReadyInit {
    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly localizationService: ILocalizationService;
    private readonly platformTranslateService: PlatformTranslationService;
    private shellResources?: any;
    private tenantResources?: any;
    private titleLocalizationResources?: any;
    private titleLocalizationCode?: string;

    constructor(
        window: Window,
        tenant: ITenant,
        localizationService: ILocalizationService,
        platformTranslateService: PlatformTranslationService
    ) {
        this.window = window;
        this.tenant = tenant;
        this.localizationService = localizationService;
        this.platformTranslateService = platformTranslateService;
    }

    public static build(
        window: Window,
        tenant: ITenant,
        localizationService: ILocalizationService,
        platformTranslateService: PlatformTranslationService,
    ): SiteService {
        let instance = ObjectUtility.getFromObjectPath<SiteService>(SiteConstants.SERVICE_KEY);
        if (instance == undefined) {
            instance = new SiteService(
                window,
                tenant,
                localizationService,
                platformTranslateService,
            );
            ObjectUtility.assignOnObjectPath(SiteConstants.SERVICE_KEY, instance);
        }
        return instance;
    }

    public async setTitle(context: any, localizationCode: string, localizableResource?: LocalizableResource): Promise<void> {
        if (!localizableResource) {
            localizableResource = {
                localizedResources: {},
                onTranslationsRequested: async () => {
                    return {};
                }
            }
        }
        const translationsCache = new TranslationsCache(context || this, localizableResource);
        const requestedResources = await translationsCache.getLocalizedResources();
        this.titleLocalizationCode = localizationCode;
        this.titleLocalizationResources = _.merge(
            {},
            this.shellResources,
            this.tenantResources,
            requestedResources
        )
        const translationService = new SelfContainedTranslationService(
            this.titleLocalizationResources,
            this.localizationService.getCurrentLocaleCode(),
            this.localizationService.getDefaultLocaleCode()
        );
        this.window.document.title = await translationService.translate(localizationCode) || '';
    }

    public getTitle(): string {
        return this.titleLocalizationCode;
    }

    public async init(): Promise<void> {
        this.shellResources = await this.platformTranslateService.getTranslationsBySupportedLocales();

        const headerOptions = this.tenant?.getContext()?.site?.top?.header;
        const footerOptions = this.tenant?.getContext()?.site?.footer;

        this.tenantResources = _.merge(
            {},
            headerOptions?.localizedResources || {},
            footerOptions?.localizedResources || {}
        );

        this.localizationService.onLocaleChanged(this, 'SiteService', async () => {
            if (this.titleLocalizationCode) {
                const translationService = new SelfContainedTranslationService(
                    this.titleLocalizationResources || {},
                    this.localizationService.getCurrentLocaleCode(),
                    this.localizationService.getDefaultLocaleCode()
                );
                this.window.document.title = await translationService.translate(this.titleLocalizationCode) || '';
            }
        });
    }
}
