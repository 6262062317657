/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {TranslationService} from "sirius-platform-support-library/shared/localization/translations/translation.service";
import {
    TranslationServiceOptions
} from "sirius-platform-support-library/shared/localization/translations/translation-service.options";

export const PlatformTranslationServiceTypeName = 'PlatformTranslationService';

export class PlatformTranslationService extends TranslationService {
    public constructor(options: TranslationServiceOptions) {
        super(options);
    }
}