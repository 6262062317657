/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {TypedDependencyModule} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {ITypedServiceRegistry} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {SystemJs, SystemJsTypeName} from "./system-js";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";

export class DynamicImportsDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer.addUsingInstanceAsSingleton<SystemJs>(DependencyType.PRIVATE, SystemJsTypeName, new SystemJs(System))
    }
}

export const dynamicImportsDependencyModule = new DynamicImportsDependencyModule();
