/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {TypedDependencyModule} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {ITypedServiceRegistry} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {ServicesInitializer, ServicesInitializerTypeName} from "./services-initializer";

export class ServicesInitializerDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<ServicesInitializer>(DependencyType.PRIVATE, ServicesInitializerTypeName, (di) => {
                return new ServicesInitializer(di);
            });
    }
}

export const servicesInitializerDependencyModule = new ServicesInitializerDependencyModule();
