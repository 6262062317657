/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {InternalMenuItem} from "../internal-menu-item";
import {MenuItem} from "sirius-platform-support-library/models/common";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";
import {DynamicMenuItem} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-item";

export const MenuItemMapperTypeName = 'MenuItemMapper';

export class MenuItemMapper {

    public async mapSingle(item: MenuItem, translationService: ITranslationService, parent?: DynamicMenuItem): Promise<InternalMenuItem | undefined> {
        try {
            const internal = new InternalMenuItem(
                item,
                parent,
                translationService
            );
            if (item.items) {
                internal.items = await this.mapMany(item.items, translationService, internal);
            }
            await internal.localize();
            return internal;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public async mapMany(items: MenuItem[], translationService: ITranslationService, parent?: DynamicMenuItem): Promise<InternalMenuItem[]> {
        const result: InternalMenuItem[] = [];
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            const converted = await this.mapSingle(item, translationService, parent);
            if (converted) {
                result.push(converted);
            }
        }
        return result;
    }
}
