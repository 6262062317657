/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    FederatedModuleMicroFrontendLoader,
    FederatedModuleMicroFrontendLoaderTypeName
} from "./dynamic-loading/federated-modules/federated-module-micro-frontend.loader";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    SystemJsMicroFrontendLoader,
    SystemJsMicroFrontendLoaderTypeName
} from "./dynamic-loading/system-js/system-js-micro-frontend.loader";
import {SystemJs, SystemJsTypeName} from "../core/dynamic-imports/system-js";
import {
    MicroFrontendLoaderFactory,
    MicroFrontendLoaderFactoryTypeName
} from "./dynamic-loading/micro-frontend-loader.factory";
import {
    SiteConfigToMicroFrontendsMapper,
    SiteConfigToMicroFrontendsMapperTypeName
} from "./mappers/site-config-to-micro-frontends.mapper";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {
    SiteConfigToSiteStructureMapper,
    SiteConfigToSiteStructureMapperTypeName
} from "./mappers/site-config-to-site-structure.mapper";
import {IApplication, IApplicationTypeName} from "./application.interface";
import {SingleSpaApplication, SingleSpaApplicationTypeName} from "./single-spa/single-spa-application";
import {
    IMicroFrontendLoaderFactory,
    IMicroFrontendLoaderFactoryTypeName
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loader-factory.interface";
import {
    IMicroFrontendLoader,
    IMicroFrontendLoaderTypeName
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loader.interface";
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {
    MicroFrontendLoadersFactory,
    MicroFrontendLoadersFactoryTypeName
} from "./loaders/micro-frontend-loaders.factory";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export class ApplicationDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<IMicroFrontendLoader, FederatedModuleMicroFrontendLoader>(DependencyType.PRIVATE, IMicroFrontendLoaderTypeName, FederatedModuleMicroFrontendLoaderTypeName, new FederatedModuleMicroFrontendLoader())
            .addUsingFactoryAsSingleton<IMicroFrontendLoader, SystemJsMicroFrontendLoader>(DependencyType.PRIVATE, IMicroFrontendLoaderTypeName, SystemJsMicroFrontendLoaderTypeName, (di) => {
                const systemJs = di.resolve<SystemJs>(SystemJsTypeName);
                return new SystemJsMicroFrontendLoader(systemJs);
            })
            .addUsingFactoryAsSingleton<IMicroFrontendLoaderFactory, MicroFrontendLoaderFactory>(DependencyType.SHARED, IMicroFrontendLoaderFactoryTypeName, MicroFrontendLoaderFactoryTypeName, (di) => {
                return new MicroFrontendLoaderFactory(di);
            })
            .addUsingFactoryAsSingleton<SiteConfigToMicroFrontendsMapper>(DependencyType.PRIVATE, SiteConfigToMicroFrontendsMapperTypeName, (di) => {
                const microFrontendLoaderFactory = di.resolve<IMicroFrontendLoaderFactory>(IMicroFrontendLoaderFactoryTypeName);
                const sharedDependencyContainer = SharedDependencyContainer.getInstance();
                return new SiteConfigToMicroFrontendsMapper(microFrontendLoaderFactory, sharedDependencyContainer, di);
            })
            .addUsingFactoryAsSingleton<SiteConfigToSiteStructureMapper>(DependencyType.PRIVATE, SiteConfigToSiteStructureMapperTypeName, (di) => {
                const microFrontendLoadersFactory = di.resolve<MicroFrontendLoadersFactory>(MicroFrontendLoadersFactoryTypeName);
                return new SiteConfigToSiteStructureMapper(
                    microFrontendLoadersFactory,
                    di
                );
            })
            .addUsingFactoryAsSingleton<MicroFrontendLoadersFactory>(DependencyType.PRIVATE, MicroFrontendLoadersFactoryTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new MicroFrontendLoadersFactory(
                    tenant,
                    di
                );
            })
            .addUsingFactoryAsSingleton<IApplication, SingleSpaApplication>(DependencyType.PRIVATE, IApplicationTypeName, SingleSpaApplicationTypeName, (di) => {
                const systemJs = di.resolve<SystemJs>(SystemJsTypeName);
                const siteConfigToMicroFrontendsMapper = di.resolve<SiteConfigToMicroFrontendsMapper>(SiteConfigToMicroFrontendsMapperTypeName);
                const siteConfigToSiteStructureMapper = di.resolve<SiteConfigToSiteStructureMapper>(SiteConfigToSiteStructureMapperTypeName);
                const defaultPagesService = di.resolve<IDefaultPagesService>(IDefaultPagesServiceTypeName);
                return new SingleSpaApplication(
                    window,
                    systemJs,
                    siteConfigToMicroFrontendsMapper,
                    siteConfigToSiteStructureMapper,
                    defaultPagesService
                );
            })
    }
}

export const applicationDependencyModule = new ApplicationDependencyModule();
