/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    FeatureTogglesConstants
} from "sirius-platform-support-library/shared/feature-toggles/feature-toggles.constants";

export class NamespaceCodePair {
    public namespace: string;
    public code: string;

    private constructor(
        namespace: string,
        code: string
    ) {
        this.namespace = namespace;
        this.code = code;
    }

    public static fromKey(key: string, defaultNamespace?: string): NamespaceCodePair | undefined {
        const parts = key.split(FeatureTogglesConstants.DEFAULT_NAMESPACE_SEPARATOR);

        let namespace;
        let code;
        if (parts.length >= 2) {
            namespace = parts[0] || defaultNamespace;
            code = parts[1];
        } else {
            namespace = defaultNamespace;
            code = parts[0];
        }

        if (!namespace || !code) {
            return undefined;
        }

        return new NamespaceCodePair(namespace, code);
    }
}
