/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    IUserProfileService,
    IUserProfileServiceTypeName
} from "sirius-platform-support-library/shared/user-profile/user-profile-service.interface";
import {UserProfileService, UserProfileServiceTypeName} from "./user-profile.service";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";
import {
    IAuthenticationService,
    IAuthenticationServiceTypeName
} from "sirius-platform-support-library/shared/authentication/authentication-service.interface";
import {
    IUserContextUpdater,
    IUserContextUpdaterTypeName
} from "sirius-platform-support-library/shared/authentication/user-context/user-context-updater.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";

export class UserProfileDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IUserProfileService, UserProfileService>(DependencyType.SHARED, IUserProfileServiceTypeName, UserProfileServiceTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const authenticationService = di.resolve<IAuthenticationService>(IAuthenticationServiceTypeName);
                const userContextUpdater = di.resolve<IUserContextUpdater>(IUserContextUpdaterTypeName)
                return UserProfileService.build(
                    eventBus,
                    authenticationService,
                    userContextUpdater,
                    di
                );
            })
            .addInterfacesOnSingleton(UserProfileServiceTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const userProfileDependencyModule = new UserProfileDependencyModule();
