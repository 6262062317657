/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {MicroFrontendResource} from "sirius-platform-support-library/tenants/tenant-context";

export class UniqueNameGenerator {
    public static readonly HEADER_ROLE = 'HEADER';
    public static readonly NAVBAR_ROLE = 'NAVBAR';
    public static readonly QUICKNAV_ROLE = 'QUICKNAV';
    public static readonly CONTENT_ROLE = 'CONTENT';
    public static readonly FOOTER_ROLE = 'FOOTER';

    public getUniqueName(resource: MicroFrontendResource, role: string): string {
        return `${role}__${resource.name}__${resource.version}`;
    }
}
