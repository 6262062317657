/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {css, html, LitElement} from "lit";
import {defineCustomElements as initSkeleton} from 'skeleton-webcomponent-loader/loader/index';
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {Header} from "sirius-platform-support-library/tenants/tenant-context";
import {
    StandardFeatures
} from "sirius-platform-support-library/shared/micro-frontends/features/standard-features.constants";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";

export class SiriusHeaderSkeleton extends LitElement {
    public static readonly CUSTOM_ELEMENT_NAME = 'sirius-header-skeleton';
    public static styles = css`
      :host {
        width: 100%;
      }

      .container {
        height: 56px;
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        column-gap: 8px;
        background-color: var(--uxd-neutral-white,#ffffff);
      }

      .container .branding {
        display: flex;
        height: 56px;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
      }

      .container .search-container {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .container .menu {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        column-gap: 8px;

        padding-right: 24px;
      }

      .logo-skeleton {
        --skeleton-margin-bottom: 0px;
        margin-top: 16px;
        padding: 0 16px 0 24px;
      }

      .title-skeleton {
        --skeleton-margin-bottom: 0px;
        margin-top: 14px;
      }

      .search-skeleton {
        --skeleton-margin-bottom: 0px;
        margin-top: 16px;
        margin-left: 8px;
      }

      .menu-button-skeleton {
        --skeleton-margin-bottom: 0px;
        margin-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }

      .last {
        padding-right: 0 !important;
      }
    `;
    private tenant?: ITenant;
    private headerOptions?: Header;

    public render() {
        const searchIsEnabled = !!(this.headerOptions?.features || []).find(f => f.code === StandardFeatures.SEARCH && f.enabled);

        if (!this.headerOptions?.visible) {
            return '';
        }

        return html`
            <script src="/libs/skeleton-webcomponent-loader/dist/esm/skeleton-webcomponent.js"></script>

            <div class="container">
                <div class="branding">
                    <nb-skeleton class="logo-skeleton" height="40px" width="40px" variant="circle"></nb-skeleton>
                    <nb-skeleton class="title-skeleton" height="36px" width="180px" variant="rect"></nb-skeleton>
                </div>
                ${searchIsEnabled ? html`
                    <div class="search-container">
                        <nb-skeleton class="search-skeleton" height="24px" width="612px" variant="rect"></nb-skeleton>
                    </div>` : ``}
                <div class="menu">
                    <nb-skeleton class="menu-button-skeleton" height="24px" width="100px" variant="rect"></nb-skeleton>
                    <nb-skeleton class="menu-button-skeleton last" height="24px" width="208px"
                                 variant="rect"></nb-skeleton>
                </div>
            </div>
        `;
    }

    public async connectedCallback() {
        super.connectedCallback();

        this.tenant = SharedDependencyContainer
            .getInstance()
            .getServiceCollection()
            .resolve<ITenant>(ITenantTypeName);

        this.headerOptions = this.tenant?.getContext()?.site?.top?.header;

        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'header-skeleton',
                suffix: 'skeleton'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        await styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(this.renderRoot, DomInjectionType.PREPEND);

        const themingHandlersManager = SharedDependencyContainer.getInstance().getServiceCollection().resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
        if (themingHandlersManager) {
            themingHandlersManager.registerThemeHandler(styleSheetsBasedThemeHandler);
            await themingHandlersManager.attach(ThemedComponent.SHELL_HEADER_SKELETON, this.renderRoot, DomInjectionType.PREPEND);
        }

        await initSkeleton();
    }
}

if (!customElements.get(SiriusHeaderSkeleton.CUSTOM_ELEMENT_NAME)) {
    customElements.define(SiriusHeaderSkeleton.CUSTOM_ELEMENT_NAME, SiriusHeaderSkeleton);
}
