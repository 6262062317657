/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IPageUnloadEventReceiver
} from "sirius-platform-support-library/shared/browser-events/page-unload-event-receiver.interface";

export const IPlatformPageUnloadEventReceiverTypeName = 'IPlatformPageUnloadEventReceiver';

export interface IPlatformPageUnloadEventReceiver extends IPageUnloadEventReceiver {
}