/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ThemeVariant} from "sirius-platform-support-library/models/theming/theme-variant.model";
import {Theme} from "sirius-platform-support-library/models/theming/theme.model";
import {RuntimeThemeVariant} from "sirius-platform-support-library/shared/theming/models/runtime-theme-variant.model";
import {HashingUtils} from "sirius-platform-support-library/utilities/hashing-utils";
import {ThemeVariantProperties} from "sirius-platform-support-library/models/theming/theme-variant-properties.model";
import _ from "lodash";

export class InternalThemeVariant implements RuntimeThemeVariant {
    public static readonly DEFAULT_FORMAT = '{{themeName}} {{variantName}}';
    public static readonly THEME_CODE_PLACEHOLDER = '{{themeCode}}';
    public static readonly THEME_NAME_PLACEHOLDER = '{{themeName}}';
    public static readonly VARIANT_CODE_PLACEHOLDER = '{{variantCode}}';
    public static readonly VARIANT_NAME_PLACEHOLDER = '{{variantName}}';

    public readonly index: number;
    public readonly id: string;
    public readonly theme: Theme;
    public readonly variant: ThemeVariant;

    public defaultFormat: string = InternalThemeVariant.DEFAULT_FORMAT;
    public properties?: ThemeVariantProperties;

    public constructor(
        index: number,
        theme: Theme,
        variant: ThemeVariant,
        defaultFormat?: string
    ) {
        this.index = index;
        this.theme = _.cloneDeep(theme);
        this.theme.code = this.theme.code.toLowerCase();
        this.variant = _.cloneDeep(variant);
        this.variant.code = this.variant.code.toLowerCase();
        this.properties = _.cloneDeep(this.variant.properties);
        this.id = InternalThemeVariant.generateId(this.theme.code, this.variant.code);

        if (defaultFormat) {
            this.defaultFormat = defaultFormat;
        }
    }

    public get name(): string {
        return this.toString();
    }

    public static generateId(themeCode: string, variantCode: string): string {
        return HashingUtils.getUuid(themeCode, variantCode);
    }

    public setDefaultFormat(defaultFormat?: string): void {
        this.defaultFormat = defaultFormat || InternalThemeVariant.DEFAULT_FORMAT;
    }

    public setProperties(properties?: ThemeVariantProperties): void {
        this.properties = _.merge({}, this.properties ?? {}, properties ?? {});
    }

    public toString(format?: string): string {
        format = format || this.defaultFormat;
        return format
            .replaceAll(InternalThemeVariant.THEME_CODE_PLACEHOLDER, this.theme.code)
            .replaceAll(InternalThemeVariant.THEME_NAME_PLACEHOLDER, this.theme.name)
            .replaceAll(InternalThemeVariant.VARIANT_CODE_PLACEHOLDER, this.variant.code)
            .replaceAll(InternalThemeVariant.VARIANT_NAME_PLACEHOLDER, this.variant.name);
    }
}
