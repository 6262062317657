/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {UserContext} from "sirius-platform-support-library/shared/authentication/user-context/user-context";
import {Permission} from "sirius-platform-support-library/shared/authorization/models/permission";
import {
    IPermissionsProvider
} from "sirius-platform-support-library/shared/authorization/providers/permissions-provider.interface";
import {AuthorizationConstants} from "sirius-platform-support-library/shared/authorization/authorization.constants";
import {
    AuthenticationStateEnum
} from "sirius-platform-support-library/shared/authentication/user-context/authentication-state.enum";
import {RuntimePermission} from "sirius-platform-support-library/shared/authorization/models/runtime-permission";
import {StandardPermissions} from "sirius-platform-support-library/shared/authorization/models/standard-permissions";

export const PlatformPermissionsProviderTypeName = 'PlatformPermissionsProvider';

export class PlatformPermissionsProvider implements IPermissionsProvider {
    public getNamespace(): string {
        return AuthorizationConstants.DEFAULT_NAMESPACE
    }

    public async getPermissions(userContext: UserContext): Promise<Permission[]> {
        const permissions: Permission[] = [];
        if (userContext.authenticationState == AuthenticationStateEnum.UNAUTHENTICATED) {
            permissions.push(new RuntimePermission(AuthorizationConstants.DEFAULT_NAMESPACE, StandardPermissions.UNAUTHENTICATED_CODE));
        }
        if (userContext.authenticationState == AuthenticationStateEnum.AUTHENTICATED) {
            permissions.push(new RuntimePermission(AuthorizationConstants.DEFAULT_NAMESPACE, StandardPermissions.AUTHENTICATED_CODE));
        }
        if (userContext.authenticationState == AuthenticationStateEnum.REGISTERING) {
            permissions.push(new RuntimePermission(AuthorizationConstants.DEFAULT_NAMESPACE, StandardPermissions.REGISTERING_CODE));
        }
        return permissions;
    }
}
