/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {TenantContext} from "sirius-platform-support-library/tenants/tenant-context";

export const ITenantContextLoaderTypeName = 'ITenantContextLoader';

export interface ITenantContextLoader {
    load(failFast?: boolean): Promise<TenantContext>;
}
