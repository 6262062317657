/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IconProperties} from "../icon-properties";

export const CdsIconParserTypeName = 'CdsIconParser';

export class CdsIconParser {
    public static readonly OUTLINE_ICON_PROPERTY = 'outline';
    public static readonly SOLID_ICON_PROPERTY = 'solid';
    public static readonly PRIMARY_ICON_PROPERTY = CdsIconParser.OUTLINE_ICON_PROPERTY;

    public parse(iconCode: string, text: string): IconProperties | undefined {
        try {
            let tokens = text.split(';').map(item => item.trim());
            if (tokens.length < 2) {
                return undefined;
            }
            let content = tokens[1];
            const startIndex = content.indexOf('=[');
            const endIndex = content.lastIndexOf(']');
            content = content.substring(startIndex, endIndex);
            if (!content) {
                return undefined;
            }
            tokens = content.split('",').map(item => item.trim());
            if (tokens.length < 2) {
                return undefined;
            }
            const resourceName = tokens[0]
                .replaceAll('=[', '')
                .replaceAll('"', '')
                .trim();
            content = tokens[1];
            content = content.substring(content.indexOf('{') + 1, content.lastIndexOf('}'));
            tokens = content.split('\',');
            const properties = {};
            tokens.forEach(token => {
                const pairs = token.split(':').map(item => item.trim());
                if (pairs.length !== 2) {
                    return;
                }
                const propKey = pairs[0];
                let propValue = pairs[1];
                if (propValue.startsWith('\'')) {
                    propValue = propValue.substring(1);
                }
                if (propValue.endsWith('\'')) {
                    propValue = propValue.substring(0, propValue.length - 1);
                }
                properties[propKey] = this.buildCompleteSvg(propValue);
            });
            return {
                code: resourceName,
                properties: properties
            }
        } catch (e) {
            console.warn(`Failed to pare icon ('${iconCode}') due to an encountered exception.`, e);
            return undefined;
        }
    }

    public getPrimaryResource(iconProperties: IconProperties, primaryIconProperty: string = CdsIconParser.PRIMARY_ICON_PROPERTY): string | undefined {
        if (iconProperties.properties.hasOwnProperty(primaryIconProperty)) {
            return iconProperties.properties[primaryIconProperty];
        }
        return undefined;
    }

    private buildCompleteSvg(resource: string): string {
        return `<svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">${resource}</svg>`;
    }
}
