/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    IPlatformBrowserNavigationGateHandler,
    IPlatformBrowserNavigationGateHandlerTypeName
} from "./platform/platform-browser-navigation-gate-handler.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    BrowserNavigationGateHandlerRefreshRequestedCallback,
    IBrowserNavigationGateHandler,
    IBrowserNavigationGateHandlerTypeName
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-gate-handler.interface";
import {
    IPlatformBrowserNavigationEventsReceiver,
    IPlatformBrowserNavigationEventsReceiverTypeName
} from "./platform/platform-browser-navigation-events-receiver.interface";
import {
    IBrowserNavigationEventsReceiver,
    IBrowserNavigationEventsReceiverTypeName
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-events-receiver.interface";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {AggregatedBrowserNavigationGateHandlerResponse} from "./aggregated-browser-navigation-gate-handler-response";

export const AggregatedBrowserNavigationGateHandlerTypeName = 'AggregatedBrowserNavigationGateHandler';

export class AggregatedBrowserNavigationGateHandler implements IBeforePlatformReadyInit {
    private readonly tenant: ITenant;
    private readonly serviceCollection: IServiceCollection;

    private browserNavigationGateHandlerRefreshRequestedCallback?: BrowserNavigationGateHandlerRefreshRequestedCallback

    public constructor(
        tenant: ITenant,
        serviceCollection: IServiceCollection
    ) {
        this.tenant = tenant;
        this.serviceCollection = serviceCollection;

    }

    public async init(): Promise<void> {
        this.getPlatformBrowserNavigationGateHandlers().forEach((handler: IPlatformBrowserNavigationGateHandler) => {
            if (handler.onBrowserNavigationGateHandlerRefreshRequested) {
                handler.onBrowserNavigationGateHandlerRefreshRequested(this.refreshBrowserNavigationGateHandler.bind(this));
            }
        });
        this.getTenantBrowserNavigationGateHandlers().forEach((handler: IBrowserNavigationGateHandler) => {
            if (handler.onBrowserNavigationGateHandlerRefreshRequested) {
                handler.onBrowserNavigationGateHandlerRefreshRequested(this.refreshBrowserNavigationGateHandler.bind(this));
            }
        });
    }

    public onBeforeNavigate(url: string): Promise<void> | void {
        this.getPlatformBrowserNavigationEventsReceivers().forEach(handler => {
            try {
                if (!handler.onBeforeNavigate) {
                    return;
                }
                const result = handler.onBeforeNavigate(url);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantBrowserNavigationEventsReceivers().forEach(handler => {
            try {
                if (!handler.onBeforeNavigate) {
                    return;
                }
                const result = handler.onBeforeNavigate(url);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public isNavigationAllowed(url?: string, hardNavigation?: boolean): AggregatedBrowserNavigationGateHandlerResponse {
        const blockingHandlers: Set<string> = new Set<string>();
        const platformIsNavigationAllowed = this.getPlatformBrowserNavigationGateHandlers()
            .map(handler => {
                try {
                    const isNavigationAllowed = handler.isNavigationAllowed(url, hardNavigation);
                    if (!isNavigationAllowed) {
                        blockingHandlers.add(handler.getUniqueId());
                    }
                    return isNavigationAllowed;
                } catch (e) {
                    console.error(e);
                    return true;
                }
            })
            .reduce((acc, val) => acc && val, true);
        const tenantIsNavigationAllowed = this.getTenantBrowserNavigationGateHandlers()
            .map(handler => {
                try {
                    const isNavigationAllowed = handler.isNavigationAllowed(url, hardNavigation);
                    if (!isNavigationAllowed) {
                        blockingHandlers.add(handler.getUniqueId());
                    }
                    return isNavigationAllowed;
                } catch (e) {
                    console.error(e);
                    return true;
                }
            })
            .reduce((acc, val) => acc && val, true);
        return {
            isNavigationAllowed: !!platformIsNavigationAllowed && !!tenantIsNavigationAllowed,
            blockingHandlers: blockingHandlers
        };
    }

    public onAfterNavigate(url: string): Promise<void> | void {
        this.getPlatformBrowserNavigationEventsReceivers().forEach(handler => {
            try {
                if (!handler.onAfterNavigate) {
                    return;
                }
                const result = handler.onAfterNavigate(url);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantBrowserNavigationEventsReceivers().forEach(handler => {
            try {
                if (!handler.onAfterNavigate) {
                    return;
                }
                const result = handler.onAfterNavigate(url);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public async onNavigationAllowed(url?: string, hardNavigation?: boolean): Promise<void> {
        this.getPlatformBrowserNavigationGateHandlers().forEach(handler => {
            try {
                if (!handler.onNavigationAllowed) {
                    return;
                }
                const result = handler.onNavigationAllowed(url, hardNavigation);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantBrowserNavigationGateHandlers().forEach(handler => {
            try {
                if (!handler.onNavigationAllowed) {
                    return;
                }
                const result = handler.onNavigationAllowed(url, hardNavigation);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public async onNavigationBlocked(url?: string, hardNavigation?: boolean): Promise<void> {
        this.getPlatformBrowserNavigationGateHandlers().forEach(handler => {
            try {
                if (!handler.onNavigationBlocked) {
                    return;
                }
                const result = handler.onNavigationBlocked(url, hardNavigation);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantBrowserNavigationGateHandlers().forEach(handler => {
            try {
                if (!handler.onNavigationBlocked) {
                    return;
                }
                const result = handler.onNavigationBlocked(url, hardNavigation);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public onBrowserNavigationGateHandlerRefreshRequested(callback: BrowserNavigationGateHandlerRefreshRequestedCallback): void {
        this.browserNavigationGateHandlerRefreshRequestedCallback = callback;
    }

    private getPlatformBrowserNavigationGateHandlers(): IPlatformBrowserNavigationGateHandler[] {
        return this.serviceCollection.resolveAll<IPlatformBrowserNavigationGateHandler>(IPlatformBrowserNavigationGateHandlerTypeName);
    }

    private getTenantBrowserNavigationGateHandlers(): IBrowserNavigationGateHandler[] {
        return this.serviceCollection.resolveAll<IBrowserNavigationGateHandler>(IBrowserNavigationGateHandlerTypeName);
    }

    private getPlatformBrowserNavigationEventsReceivers(): IPlatformBrowserNavigationEventsReceiver[] {
        return this.serviceCollection.resolveAll<IPlatformBrowserNavigationEventsReceiver>(IPlatformBrowserNavigationEventsReceiverTypeName);
    }

    private getTenantBrowserNavigationEventsReceivers(): IBrowserNavigationEventsReceiver[] {
        return this.serviceCollection.resolveAll<IBrowserNavigationEventsReceiver>(IBrowserNavigationEventsReceiverTypeName);
    }

    private async refreshBrowserNavigationGateHandler(): Promise<void> {
        try {
            if (this.browserNavigationGateHandlerRefreshRequestedCallback) {
                await this.browserNavigationGateHandlerRefreshRequestedCallback();
            }
        } catch (e) {
            console.error(e);
        }
    }
}
