/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    DEFAULT_LOCALE_PROVIDER_PRIORITIES,
    ILocaleProviderPriorityAssigner
} from "sirius-platform-support-library/shared/localization/providers/locale-provider-priority-assigner.interface";
import {ILocaleProvider} from "sirius-platform-support-library/shared/localization/providers/locale-provider.interface";

export const DefaultLocaleProviderPriorityAssignerTypeName = 'DefaultLocaleProviderPriorityAssigner';

export class DefaultLocaleProviderPriorityAssigner implements ILocaleProviderPriorityAssigner {
    private priorityMap: Record<string, number> = DEFAULT_LOCALE_PROVIDER_PRIORITIES;

    public configure(priorityMap: Record<string, number>): void {
        if (priorityMap) {
            this.priorityMap = priorityMap;
        }
    }

    public reset(): void {
        this.priorityMap = DEFAULT_LOCALE_PROVIDER_PRIORITIES;
    }

    public prioritize(providers: ILocaleProvider[]): ILocaleProvider[] {

        return providers.sort((a, b) => {
            const aPriority = this.priorityMap[a.getProviderCode()] ?? 0;
            const bPriority = this.priorityMap[b.getProviderCode()] ?? 0;
            if (aPriority < bPriority) {
                return 1;
            }
            if (aPriority > bPriority) {
                return -1;
            }
            return 0;
        });
    }
}
