/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    RequestedProgressIndicator
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicator";
import {RendarableProgressIndicator} from "../rendarable-progress-indicator";

export class ProgressIndicatorsDomHandler {
    private static readonly PROGRESS_INDICATOR_FULL_BLOCKING_MARKER = 'sirius-progress-indicator-full-screen-overlay';
    private static readonly PROGRESS_INDICATOR_BAR_MARKER = 'sirius-progress-indicator-bar-wrapper';
    private static readonly PROGRESS_INDICATOR_CONTENT_BLOCKING_MARKER = 'sirius-progress-indicator-content-overlay';
    private static readonly PROGRESS_INDICATOR_ID_ATTRIBUTE = 'progress-indicator-id';

    private readonly document: Document;

    private piBarWrapperElement: HTMLElement;
    private piFullscreenWrapperElement: HTMLElement;
    private piContentWrapperElement: HTMLElement;

    private barContainerElement: HTMLElement;
    private fullscreenContainerElement: HTMLElement;
    private contentContainerElement: HTMLElement;

    public constructor(
        document: Document
    ) {
        this.document = document;
    }

    public async init(): Promise<void> {
        this.build();
    }

    public render(progressIndicator: RendarableProgressIndicator): void {
        if (!progressIndicator) {
            return;
        }

        const containerElement = progressIndicator.target;
        const pendingProgressIndicatorElement: HTMLElement = progressIndicator.create();
        containerElement.appendChild(pendingProgressIndicatorElement);
    }

    public update(progressIndicator: RendarableProgressIndicator): void {
        if (!progressIndicator) {
            return;
        }
        const containerElement = this.getTargetElement(progressIndicator);
        const progressIndicatorElement = containerElement.querySelector(`[${ProgressIndicatorsDomHandler.PROGRESS_INDICATOR_ID_ATTRIBUTE}]`) as HTMLElement;
        if (progressIndicatorElement) {
            progressIndicator.updateAttributes(progressIndicatorElement);
        }
    }

    public clear(): void {
        this.fullscreenContainerElement.innerHTML = '';
        this.barContainerElement.innerHTML = '';
        this.contentContainerElement.innerHTML = '';
    }

    public async removeProgressIndicatorById(id: string): Promise<void> {
        if (!id) {
            return;
        }
        this.clear();
    }

    public addActiveClass(pi: RendarableProgressIndicator): void {
        if (pi.blocking) {
            pi.blockContentOnly ? this.piContentWrapperElement.classList.add('active') : this.piFullscreenWrapperElement.classList.add('active');
        }
    }

    public removeActiveClass(pi: RendarableProgressIndicator): void {
        if (pi.blocking) {
            pi.blockContentOnly ? this.piContentWrapperElement.classList.remove('active') : this.piFullscreenWrapperElement.classList.remove('active');
        }
    }

    public removeAnyActiveClass(): void {
        this.piContentWrapperElement.classList.remove('active');
        this.piFullscreenWrapperElement.classList.remove('active');
    }

    public getTargetElement(pi: RequestedProgressIndicator): HTMLElement {
        if (pi.blocking && pi.blockContentOnly) {
            return this.contentContainerElement;
        } else if (pi.blocking) {
            return this.fullscreenContainerElement;
        } else {
            return this.barContainerElement
        }
    }

    private build(): void {
        this.piBarWrapperElement = this.document.getElementById(ProgressIndicatorsDomHandler.PROGRESS_INDICATOR_BAR_MARKER);
        this.piFullscreenWrapperElement = this.document.getElementById(ProgressIndicatorsDomHandler.PROGRESS_INDICATOR_FULL_BLOCKING_MARKER);
        this.piContentWrapperElement = this.document.getElementById(ProgressIndicatorsDomHandler.PROGRESS_INDICATOR_CONTENT_BLOCKING_MARKER);

        if (!this.piBarWrapperElement) {
            throw new Error(`Could not get progressIndicator wrapper element with ID ${ProgressIndicatorsDomHandler.PROGRESS_INDICATOR_BAR_MARKER}`);
        }
        this.barContainerElement = this.document.createElement("div");
        this.barContainerElement.classList.add('progress-indicators');
        this.piBarWrapperElement.appendChild(this.barContainerElement);

        if (!this.piFullscreenWrapperElement) {
            throw new Error(`Could not get progressIndicator wrapper element with ID ${ProgressIndicatorsDomHandler.PROGRESS_INDICATOR_FULL_BLOCKING_MARKER}`);
        }
        this.fullscreenContainerElement = this.piFullscreenWrapperElement.querySelector('.progress-indicators');
        if (!this.fullscreenContainerElement) {
            this.fullscreenContainerElement = this.document.createElement("div");
            this.fullscreenContainerElement.classList.add('progress-indicators');
            this.piFullscreenWrapperElement.appendChild(this.fullscreenContainerElement);
        }
        if (!this.piContentWrapperElement) {
            throw new Error(`Could not get progressIndicator wrapper element with ID ${ProgressIndicatorsDomHandler.PROGRESS_INDICATOR_CONTENT_BLOCKING_MARKER}`);
        }
        this.contentContainerElement = this.document.createElement("div");
        this.contentContainerElement.classList.add('progress-indicators');
        this.piContentWrapperElement.appendChild(this.contentContainerElement);
    }
}
