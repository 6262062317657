/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {css, html, LitElement} from "lit";
import {property, state} from "lit/decorators.js";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {TranslationService} from 'sirius-platform-support-library/shared/localization/translations/translation.service';
import {
    SupportedTranslationsLoaders
} from "sirius-platform-support-library/shared/localization/translations/loaders/translations-loaders.constants";
import {InternalNavigateToOptions} from "../options/internal-navigate-to.options";
import {NavigateToOptionsFactory} from "../options/factory/navigate-to-options.factory";
import {DefaultPageHelper} from "../default-page.helper";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";
import {createNodeElement} from "sirius-platform-support-library/utilities/dom-helper";

export class DefaultPageWebComponent extends LitElement {
    public static readonly CUSTOM_ELEMENT_NAME = 'sirius-default-page';

    public static styles = css`
      :host {
        width: 100%;
        background-color: transparent;
      }

      .container {
        margin-top: 4em;
        margin-bottom: 4em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1.5em;
        padding: 1.8em;
      }

      .content-title {
        font-size: 37px;
        font-family: 'Roboto Light';
        color: var(--uxd-neutral-gray900, #0e0e0e);
      }

      .content-description {
        font-size: 25px;
        color: var(--uxd-neutral-gray900, #0e0e0e);
      }

      .buttons-container {
        display: flex;
        column-gap: 0.8em;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
      }

      .content-button {
        margin-top: 1em;
      }
      .btn.btn-outline:hover:not(.btn.btn-outline:disabled) {
        background-color: var(--uxd-outline-button, #D9F5FD) !important;
        border-color: var(--uxd-hover-button, #0672CB) !important;
        color: var(--uxd-hover-button, #0672CB) !important;
      }

      .btn.btn-outline {
        border-color: var(--uxd-primary-dellblue, #0672CB) !important;
        color: var(--uxd-primary-dellblue, #0672CB) !important;
        background-color: transparent !important;
      }
      .btn.btn-info.disabled,.btn.btn-outline-primary.disabled,.btn.btn-primary-outline.disabled,.btn.btn-outline.disabled, .btn.btn-info-outline.disabled, .btn.btn-outline-info.disabled{
        color: var(--uxd-disabled-color, #0672CB) !important;
        border-color: var(--uxd-disabled-color,#0672CB) !important;
        background: transparent !important;
      }

      *:focus-visible {
        outline: none !important;
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px var(--uxd-primary-midnight,#00468b) !important;
        border-radius: 2px;
        text-decoration: underline;
        box-sizing: border-box;
      }`;

    @property({attribute: true})
    public options?: string;
    @state()
    public titleText: string;
    @state()
    public descriptionText: string;
    @state()
    public homeText: string;
    @state()
    public backText: string;
    private readonly navigateToOptionsFactory: NavigateToOptionsFactory;
    private translationService?: TranslationService;

    private pageCode?: string;
    private pageOptions?: InternalNavigateToOptions;

    private referenceStyleSheet?: Element | ParentNode;

    public constructor() {
        super();

        this.navigateToOptionsFactory = new NavigateToOptionsFactory();
    }

    public async connectedCallback() {
        super.connectedCallback();

        this.pageCode = DefaultPageHelper.getOptionsCode(window.location.search);
        this.pageOptions = DefaultPageHelper.parseOptions(this.pageCode, this.options);

        this.translationService = new TranslationService({
            publicPath: {
                provide: () => {
                    // @ts-ignore
                    return __webpack_public_path__;
                }
            },
            type: SupportedTranslationsLoaders.REMOTE_ASSETS,
            options: {
                prependPublicPath: false,
                baseUrl: '/assets/i18n/',
                extension: '.json'
            },
            localizedResources: this.pageOptions.localizedResources || {}
        });

        this.translationService.onLanguageChanged(this, this.translateProperties);

        await this.translateProperties();

        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'default-page'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-dark-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });
        if (this.referenceStyleSheet) {
            await styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(this.referenceStyleSheet, DomInjectionType.AFTER);

            const themingHandlersManager = SharedDependencyContainer.getInstance().getServiceCollection().resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
            if (themingHandlersManager) {
                themingHandlersManager.registerThemeHandler(styleSheetsBasedThemeHandler);
                await themingHandlersManager.attach(ThemedComponent.SHELL_DEFAULT_PAGE, this.referenceStyleSheet, DomInjectionType.AFTER);
            }
        }
    }

    public render() {
        return html`
            <div class="container">
                <div class="content-title" role="heading" tabindex="0"
                     aria-label="${this.titleText}">${unsafeHTML(this.titleText)}
                </div>
                <div class="content-description" role="heading" tabindex="0"
                     aria-label="${this.descriptionText}">${unsafeHTML(this.descriptionText)}
                </div>
                <div class="buttons-container">
                    ${this.pageOptions?.homeButtonVisible && this.pageOptions?.homeUrl ? html`
                        <button class="btn btn-outline content-button"
                                aria-label="${this.homeText}"
                                @click="${this.homeClick}">${this.homeText}
                        </button>` : ''}
                    ${this.pageOptions?.backButtonVisible ? html`
                        <button class="btn btn-outline content-button" @click="${this.backClick}"
                                aria-label="${this.backText}">${this.backText}
                        </button>` : ''}
                </div>
            </div>
        `;
    }

    public homeClick(): void {
        if (!this.pageOptions?.homeUrl) {
            return;
        }

        window.history.replaceState(null, null, this.pageOptions.homeUrl);
    }

    public backClick(): void {
        if (this.pageOptions?.originUrl) {
            window.history.replaceState(null, null, this.pageOptions.originUrl);
            return;
        }

        window.history.back();
    }

    protected createRenderRoot() {
        const root = super.createRenderRoot();

        this.referenceStyleSheet = root.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/libs/@clr/ui/clr-ui.min.css'
        }));

        return root;
    }

    private async translateProperties(): Promise<void> {
        this.titleText = await this.translationService.translate(this.pageOptions?.title || '');
        this.descriptionText = await this.translationService.translate(this.pageOptions?.description || '');
        this.homeText = await this.translationService.translate(this.pageOptions?.homeButtonText || '');
        this.backText = await this.translationService.translate(this.pageOptions?.backButtonText || '');
    }
}

if (!customElements.get(DefaultPageWebComponent.CUSTOM_ELEMENT_NAME)) {
    customElements.define(DefaultPageWebComponent.CUSTOM_ELEMENT_NAME, DefaultPageWebComponent);
}
