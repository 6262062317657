/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {BannerAlertsService, BannerAlertsServiceTypeName} from "./banner-alerts.service";
import {
    IBannerAlertsService,
    IBannerAlertsServiceTypeName
} from "sirius-platform-support-library/shared/banner-alerts/banner-alerts-service.interface";
import {
    ILocalizationService,
    ILocalizationServiceTypeName
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    DefaultBannerAlertsPriorityAssigner,
    DefaultBannerAlertsPriorityAssignerTypeName
} from "./prioritizers/default-banner-alerts-priority-assigner";
import {
    IBannerAlertsPriorityAssigner,
    IBannerAlertsPriorityAssignerTypeName
} from "sirius-platform-support-library/shared/banner-alerts/banner-alerts-priority-assigner.interface";
import {
    IActionHandler,
    IActionHandlerTypeName
} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {BannerAlertValidator, BannerAlertValidatorTypeName} from "./validators/banner-alert.validator";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {
    IPlatformBrowserNavigationEventsReceiverTypeName
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    PlatformActionInteractionService,
    PlatformActionInteractionServiceTypeName
} from "../browser-events/platform-action-interaction.service";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {
    IThemeHandler,
    IThemeHandlerTypeName
} from "sirius-platform-support-library/shared/theming/management/handlers/theme-handler.interface";
import {
    IButtonStyleProcessor,
    IButtonStyleProcessorTypeName
} from "../common-ui/buttons/button-style.processor.interface";

export class BannerAlertsDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'banner-alerts'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-dark-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        dependencyContainer
            .addUsingInstanceAsSingleton<BannerAlertValidator>(DependencyType.PRIVATE, BannerAlertValidatorTypeName, new BannerAlertValidator())
            .addUsingInstanceAsSingleton<IBannerAlertsPriorityAssigner, DefaultBannerAlertsPriorityAssigner>(DependencyType.SHARED, IBannerAlertsPriorityAssignerTypeName, DefaultBannerAlertsPriorityAssignerTypeName, new DefaultBannerAlertsPriorityAssigner())
            .addUsingFactoryAsSingleton<IBannerAlertsService, BannerAlertsService>(DependencyType.SHARED, IBannerAlertsServiceTypeName, BannerAlertsServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);
                const bannerAlertsPriorityAssigner = di.resolve<IBannerAlertsPriorityAssigner>(IBannerAlertsPriorityAssignerTypeName);
                const actionHandler = di.resolve<IActionHandler>(IActionHandlerTypeName);
                const bannerAlertValidator = di.resolve<BannerAlertValidator>(BannerAlertValidatorTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const actionInteractionService = di.resolve<PlatformActionInteractionService>(PlatformActionInteractionServiceTypeName);
                const themingHandlersManager = di.resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
                const buttonStyleProcessor = di.resolve<IButtonStyleProcessor>(IButtonStyleProcessorTypeName);

                return BannerAlertsService.build(
                    window,
                    document,
                    tenant,
                    localizationService,
                    bannerAlertsPriorityAssigner,
                    actionHandler,
                    bannerAlertValidator,
                    eventBus,
                    actionInteractionService,
                    styleSheetsBasedThemeHandler,
                    themingHandlersManager,
                    buttonStyleProcessor,
                    di
                );
            })
            .addInterfacesOnSingleton(BannerAlertsServiceTypeName, IAfterPlatformReadyInitTypeName)
            .addInterfacesOnSingleton(BannerAlertsServiceTypeName, IPlatformBrowserNavigationEventsReceiverTypeName)
            .addUsingInstanceAsSingleton<IThemeHandler, StyleSheetsBasedThemeHandler>(DependencyType.PRIVATE, IThemeHandlerTypeName, styleSheetsBasedThemeHandler.getClassName(), styleSheetsBasedThemeHandler);
    }
}

export const bannerAlertsDependencyModule = new BannerAlertsDependencyModule();
