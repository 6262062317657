/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {SystemJs} from "../../../core/dynamic-imports/system-js";
import {MicroFrontendTypeEnum} from "sirius-platform-support-library/models/micro-frontends/micro-frontend-type.enum";
import {
    IMicroFrontendLoader
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loader.interface";
import {
    MicroFrontendLoadingOptions
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loading.options";

export const SystemJsMicroFrontendLoaderTypeName = 'SystemJsMicroFrontendLoader';

export class SystemJsMicroFrontendLoader implements IMicroFrontendLoader {
    private readonly systemJs: SystemJs;

    public get supportedMicroFrontendTypes(): MicroFrontendTypeEnum[] {
        return [MicroFrontendTypeEnum.SINGLE_SPA, MicroFrontendTypeEnum.WEB_COMPONENT];
    }

    public supportsMicroFrontendType(microFrontendType: MicroFrontendTypeEnum): boolean {
        return this.supportedMicroFrontendTypes.indexOf(microFrontendType, 0) > -1
    }

    public constructor(systemJs: SystemJs) {
        this.systemJs = systemJs;
    }

    public async load(options: MicroFrontendLoadingOptions): Promise<any> {
        return await this.systemJs.import(options.remoteEntry);
    }
}
