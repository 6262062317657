/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IBeforePlatformReadyInit} from "../../initializer/before-platform-ready-init.interface";
import {
    ISiriusIconsRegistry
} from "sirius-platform-support-library/shared/icons/registry/sirius-icons-registry.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    IIconLoader,
    IIconLoaderTypeName
} from "sirius-platform-support-library/shared/icons/loaders/icon-loader.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {CdsIconsLoader} from "../loaders/cds/cds-icons.loader";
import {UxdIconsLoader} from "../loaders/uxd/uxd-icons.loader";
import {ClrIconsLoader} from "../loaders/clr/clr-icons.loader";
import {DdsIconsLoader} from "../loaders/dds/dds-icons.loader";

export const IconsImporterTypeName = 'IconsRegistryConfigurator';

export class IconsRegistryConfigurator implements IBeforePlatformReadyInit {
    private readonly tenant: ITenant;
    private readonly iconsRegistry: ISiriusIconsRegistry;
    private readonly serviceCollection: IServiceCollection;

    public constructor(
        tenant: ITenant,
        iconsRegistry: ISiriusIconsRegistry,
        serviceCollection: IServiceCollection
    ) {
        this.tenant = tenant;
        this.iconsRegistry = iconsRegistry;
        this.serviceCollection = serviceCollection;
    }

    public async init(): Promise<void> {
        const loaders = this.getIconsLoaders();
        await this.iconsRegistry.registerLoaders(...loaders);
    }

    private getIconsLoaders(): IIconLoader[] {
        const registeredLoaders = this.serviceCollection.resolveAll<IIconLoader>(IIconLoaderTypeName).reduce((acc: Record<string, IIconLoader>, value: IIconLoader) => {
            acc[value.getUniqueCode()] = value;
            return acc;
        }, {} as Record<string, IIconLoader>);
        const tenantLoaders = this.tenant.getContext()?.behaviour?.iconsRegistry?.loaders || [];
        const platformLoaders = [];
        if (!tenantLoaders.some(loaderCode => loaderCode === ClrIconsLoader.UNIQUE_CODE)) {
            platformLoaders.push(ClrIconsLoader.UNIQUE_CODE);
        }
        if (!tenantLoaders.some(loaderCode => loaderCode === CdsIconsLoader.UNIQUE_CODE)) {
            platformLoaders.push(CdsIconsLoader.UNIQUE_CODE);
        }
        if (!tenantLoaders.some(loaderCode => loaderCode === UxdIconsLoader.UNIQUE_CODE)) {
            platformLoaders.push(UxdIconsLoader.UNIQUE_CODE);
        }
        if (!tenantLoaders.some(loaderCode => loaderCode === DdsIconsLoader.UNIQUE_CODE)) {
            platformLoaders.push(DdsIconsLoader.UNIQUE_CODE);
        }
        let loaders = [...platformLoaders, ...tenantLoaders];
        loaders = loaders.filter((element, index) => {
            return loaders.indexOf(element) === index;
        });
        return loaders.map(loaderCode => registeredLoaders[loaderCode]);
    }
}
