/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IActionInteractionService
} from "sirius-platform-support-library/shared/browser-events/action-interaction-service.interface";
import {ContextualAction} from "sirius-platform-support-library/models/common";
import {AggregatedActionInteractionGateHandler} from "./aggregated-action-interaction-gate.handler";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    PossibleGateChangedEvents
} from "sirius-platform-support-library/shared/browser-events/possible-gate-changed.events";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {
    PossibleGateChangeCallback
} from "sirius-platform-support-library/shared/browser-events/possible-gate-changed.events-receiver";
import {PlatformBrowserNavigationServiceTypeName} from "./platform-browser-navigation.service";
import {EventType} from "sirius-platform-support-library/shared/event-bus/event-type";

export const PlatformActionInteractionServiceTypeName = 'PlatformActionInteractionService';

export class PlatformActionInteractionService implements IActionInteractionService {
    private static readonly BLOCKED_INTERNAL_EVENT = 'dell.sirius.action-events.internal.interaction-blocked';
    private static readonly ALLOWED_INTERNAL_EVENT = 'dell.sirius.action-events.internal.interaction-allowed';
    private static readonly BEFORE_INTERNAL_EVENT = 'dell.sirius.action-events.internal.before-interaction';
    private static readonly AFTER_INTERNAL_EVENT = 'dell.sirius.action-events.internal.after-interaction';
    private static readonly POSSIBLE_GATE_CHANGED_EVENT = 'dell.sirius.action-events.internal.possible-gate-changed';

    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly eventBus: IEventBus;
    private readonly aggregatedActionInteractionGateHandler: AggregatedActionInteractionGateHandler;

    private previousInteractionAllowedState: boolean;

    public constructor(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        aggregatedActionInteractionGateHandler: AggregatedActionInteractionGateHandler
    ) {
        this.window = window;
        this.tenant = tenant;
        this.eventBus = eventBus;
        this.aggregatedActionInteractionGateHandler = aggregatedActionInteractionGateHandler;

        this.bind();
    }

    public bind(): void {
        this.window.addEventListener(PlatformActionInteractionService.ALLOWED_INTERNAL_EVENT, async (event: CustomEvent) => {
            const action = event.detail?.action;
            await this.onInteractionAllowed(action);
        });
        this.window.addEventListener(PlatformActionInteractionService.BLOCKED_INTERNAL_EVENT, async (event: CustomEvent) => {
            const action = event.detail?.action;
            await this.onInteractionBlocked(action);
        });
        this.window.addEventListener(PlatformActionInteractionService.BEFORE_INTERNAL_EVENT, async (event: CustomEvent) => {
            const action = event.detail?.action;
            await this.onBeforeInteraction(action);
        });
        this.window.addEventListener(PlatformActionInteractionService.AFTER_INTERNAL_EVENT, async (event: CustomEvent) => {
            const action = event.detail?.action;
            await this.onAfterInteraction(action);
        });
        this.window.addEventListener(PlatformActionInteractionService.POSSIBLE_GATE_CHANGED_EVENT, async (event: CustomEvent) => {
            await this.onActionInteractionGateHandlerRefreshRequested();
        });

        this.aggregatedActionInteractionGateHandler.onActionInteractionGateHandlerRefreshRequested(this.onActionInteractionGateHandlerRefreshRequested.bind(this));
    }

    public dispatchBeforeInteractionEvent(action: ContextualAction): void {
        this.window.dispatchEvent(new CustomEvent<any>(PlatformActionInteractionService.BEFORE_INTERNAL_EVENT, {
            detail: {
                action: action
            }
        }));
    }

    public isInteractionAllowed(action: ContextualAction): boolean {
        const currentInteractionAllowedState = this.aggregatedActionInteractionGateHandler.isInteractionAllowed(action);
        if (!this.previousInteractionAllowedState && currentInteractionAllowedState) {
            this.dispatchInteractionAllowedEvent(action);
        } else if (!currentInteractionAllowedState) {
            this.dispatchInteractionBlockedEvent(action);
        }
        if (this.previousInteractionAllowedState != currentInteractionAllowedState) {
            this.dispatchPossibleGateChangeEvent();
        }
        this.previousInteractionAllowedState = currentInteractionAllowedState;
        return currentInteractionAllowedState;
    }

    public dispatchAfterInteractionEvent(action: ContextualAction): void {
        this.window.dispatchEvent(new CustomEvent<any>(PlatformActionInteractionService.AFTER_INTERNAL_EVENT, {
            detail: {
                action: action
            }
        }));
    }

    public onPossibleGateChange(context: any, subscriberName: string, possibleGateChangeCallback: PossibleGateChangeCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<any>(this, subscriberName, PossibleGateChangedEvents.ACTION_INTERACTION_POSSIBLE_GATE_CHANGE, () => {
            possibleGateChangeCallback?.call(context);
        });
    }

    private dispatchInteractionAllowedEvent(action: ContextualAction): void {
        this.window.dispatchEvent(new CustomEvent<any>(PlatformActionInteractionService.ALLOWED_INTERNAL_EVENT, {
            detail: {
                action: action
            }
        }));
    }

    private dispatchInteractionBlockedEvent(action: ContextualAction): void {
        this.window.dispatchEvent(new CustomEvent<any>(PlatformActionInteractionService.BLOCKED_INTERNAL_EVENT, {
            detail: {
                action: action,
            }
        }));
    }

    private dispatchPossibleGateChangeEvent(): void {
        this.window.dispatchEvent(new CustomEvent<any>(PlatformActionInteractionService.POSSIBLE_GATE_CHANGED_EVENT, {
            detail: undefined
        }));
    }

    private async onInteractionAllowed(action: ContextualAction): Promise<void> {
        try {
            await this.aggregatedActionInteractionGateHandler.onInteractionAllowed(action);
        } catch (e) {
            console.error(e);
        }
    }

    private async onInteractionBlocked(action: ContextualAction): Promise<void> {
        try {
            await this.aggregatedActionInteractionGateHandler.onInteractionBlocked(action);
        } catch (e) {
            console.error(e);
        }
    }

    private async onBeforeInteraction(action: ContextualAction): Promise<void> {
        await this.aggregatedActionInteractionGateHandler.onBeforeInteraction(action);
    }

    private async onAfterInteraction(action: ContextualAction): Promise<void> {
        await this.aggregatedActionInteractionGateHandler.onAfterInteraction(action);
    }

    private onActionInteractionGateHandlerRefreshRequested(): void {
        this.eventBus.dispatch<any>(PlatformBrowserNavigationServiceTypeName, EventType.BROADCAST, PossibleGateChangedEvents.ACTION_INTERACTION_POSSIBLE_GATE_CHANGE, {});
    }
}
