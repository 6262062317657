/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IMicroFrontendLoadingSkeleton
} from "sirius-platform-support-library/shared/site/loaders/micro-frontend-loading-skeleton.interface";
import {SkeletonLoaderConstants} from "sirius-platform-support-library/shared/site/loaders/skeleton-loader.constants";
import {SiriusNavbarSkeleton} from "./web-elements/navbar-skeleton.web-element";

export const NavbarLoaderSkeletonTypeName = 'NavbarLoaderSkeleton';

export class NavbarLoaderSkeleton implements IMicroFrontendLoadingSkeleton {
    public for(): string {
        return SkeletonLoaderConstants.NAVBAR_LOADER;
    }

    public getMarkup(): string {
        return `<${SiriusNavbarSkeleton.CUSTOM_ELEMENT_NAME}></${SiriusNavbarSkeleton.CUSTOM_ELEMENT_NAME}>`
    }
}
