/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    ITenantStoreService,
    ITenantStoreServiceTypeName
} from "sirius-platform-support-library/shared/tenant-store/tenant-store-service.interface";
import {TenantStoreService, TenantStoreServiceTypeName} from "./tenant-store.service";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";

export class TenantStoreDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<ITenantStoreService, TenantStoreService>(
                DependencyType.SHARED,
                ITenantStoreServiceTypeName,
                TenantStoreServiceTypeName,
                (di) => {
                    const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                    return TenantStoreService.build(window, eventBus);
                })
            .addInterfacesOnSingleton(TenantStoreServiceTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const tenantStoreDependencyModule = new TenantStoreDependencyModule();
