/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ActionPerformedCallback,
    ActionsDisabledStateChangeCallback,
    ActionUnauthorizedCallback,
    IActionHandler
} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {
    IActionHandlerInterceptor,
    IActionHandlerInterceptorTypeName
} from "sirius-platform-support-library/shared/actions/interceptors/action-handler-interceptor.interface";
import {ActionStateToggleEvent} from "sirius-platform-support-library/shared/actions/action-state-toggle-event";
import {
    Action,
    ActionTargetEnum,
    ActionTypeEnum,
    UnauthorizedHandleType
} from "sirius-platform-support-library/models/common";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    IPlaceholdersReplacerService
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholders-replacer-service.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import * as constants from "./constants";
import {
    IBrowserNavigationService
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-service.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {ActionMetadata} from "sirius-platform-support-library/shared/actions/action-metadata";
import {
    IActionInteractionService
} from "sirius-platform-support-library/shared/browser-events/action-interaction-service.interface";
import {ActionResult} from "./action-result";
import {ActionHandlerEvents} from "sirius-platform-support-library/shared/actions/events/action-handler.events";
import {ActionPerformedEvent} from "sirius-platform-support-library/shared/actions/events/action-performed.event";
import {
    IAuthorizationService
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";
import {ActionUnauthorizedEvent} from "sirius-platform-support-library/shared/actions/events/action-unauthorized.event";
import {sanitizeUrl} from "@braintree/sanitize-url";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";

export const ActionHandlerTypeName = 'ActionHandler';

export class ActionHandler implements IActionHandler {
    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly eventBus: IEventBus;
    private readonly placeholdersReplacerService: IPlaceholdersReplacerService;
    private readonly browserNavigationService: IBrowserNavigationService;
    private readonly actionInteractionService: IActionInteractionService;
    private readonly authorizationService: IAuthorizationService;
    private readonly serviceCollection: IServiceCollection;

    private _actionsEnabled = true;

    constructor(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        placeholdersReplacerService: IPlaceholdersReplacerService,
        browserNavigationService: IBrowserNavigationService,
        actionInteractionService: IActionInteractionService,
        authorizationService: IAuthorizationService,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.tenant = tenant;
        this.eventBus = eventBus;
        this.placeholdersReplacerService = placeholdersReplacerService;
        this.browserNavigationService = browserNavigationService;
        this.actionInteractionService = actionInteractionService;
        this.authorizationService = authorizationService;
        this.serviceCollection = serviceCollection;
    }

    public async handle(context: any, source: string, action: Action, metadata?: ActionMetadata, fireActionPerformedEvent: boolean = false): Promise<boolean> {
        let actionResult: ActionResult = {success: false};
        if (!action || !this._actionsEnabled) {
            return !!actionResult?.success;
        }
        const shouldContinue = await this.intercept(context, source, action, metadata, fireActionPerformedEvent);
        if (shouldContinue === false) { // We handle 3 state so === comparison is needed
            return false;
        }
        try {
            switch (action.type) {
                case ActionTypeEnum.URL:
                    actionResult = await this.handleUrlAction(source, action);
                    break;
                case ActionTypeEnum.EVENT:
                    actionResult = this.handleEventAction(source, action, metadata);
                    break;
                case ActionTypeEnum.CALLBACK:
                    actionResult = await this.handleCallbackAction(context, action, metadata);
                    break;
            }
        } catch (e) {
            actionResult = {success: false};
            console.error(e);
        }

        if (fireActionPerformedEvent) {
            this.dispatchActionPerformedEvent(action, actionResult);
        }

        return !!actionResult?.success;
    }

    public disableActions(): void {
        this._actionsEnabled = false;
        this.dispatchActionsDisableStateToggleEvent();
    }

    public enableActions(): void {
        this._actionsEnabled = true;
        this.dispatchActionsDisableStateToggleEvent();
    }

    public actionsEnabled(): boolean {
        return this._actionsEnabled;
    }

    public isActionAuthorized(action: Action, notifyUnauthorizedEvent: boolean = false): boolean {
        if (action.authorization?.permissions) {
            if (!this.authorizationService.isAuthorized(action.authorization.permissions)) {
                if (notifyUnauthorizedEvent) {
                    this.dispatchActionUnauthorizedEvent(action);
                }
                return false;
            }
        }
        return true;
    }


    public getButtonStyle(action?: Action): string {
        let style = '';
        const buttonStyle = action?.authorization?.behaviour?.unauthorizedHandleType;
        if (!buttonStyle) {
            return style;
        }
        switch (buttonStyle) {
            case UnauthorizedHandleType.DISABLED:
                style = 'disabled';
                break;
            case UnauthorizedHandleType.HIDDEN:
                style = 'hidden';
                break;
            default:
                style = '';
        }
        return style;
    }

    public onActionsDisabledStateChanged(context: any, subscriberName: string, callback: ActionsDisabledStateChangeCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<ActionStateToggleEvent>(this, subscriberName, constants.ACTIONS_DISABLED_STATE_TOGGLE_EVENT, (busEvent) => {
            callback.call(context, busEvent.data);
        });
    }

    public onActionPerformed(context: any, source: string, callback: ActionPerformedCallback): void {
        const eventKey = this.getActionPerformedEventKey(source);
        this.window.addEventListener(eventKey, async (event: CustomEvent) => {
            const data = event?.detail as ActionPerformedEvent;
            if (!data) {
                return;
            }
            try {
                await callback.call(context ?? this, data);
            } catch (e) {
                console.error(e);
            }
        });
    }

    public onActionUnauthorized(context: any, source: string, callback: ActionUnauthorizedCallback): void {
        const eventKey = this.getActionUnauthorizedEventKey(source);
        this.window.addEventListener(eventKey, async (event: CustomEvent) => {
            const data = event?.detail as ActionUnauthorizedEvent;
            if (!data) {
                return;
            }
            try {
                await callback.call(context ?? this, data);
            } catch (e) {
                console.error(e);
            }
        });
    }

    private async handleUrlAction(source: string, action: Action): Promise<ActionResult> {
        if (!action.action || typeof action.action !== 'string') {
            throw new Error('The provided action is not a valid URL');
        }
        const newTab = action.target === ActionTargetEnum.NEW_TAB;
        const processedUrl = this.placeholdersReplacerService.replace(action.action);
        const disableInteractionIfNavigationIsDisabled = this.tenant.getContext()?.behaviour?.actions?.disableInteractionIfNavigationIsDisabled ?? false;
        const disableNewTabNavigationIfNavigationDisabled = this.tenant.getContext()?.behaviour?.actions?.disableNewTabNavigationIfNavigationDisabled ?? false;
        if (disableInteractionIfNavigationIsDisabled) {
            if (!newTab) {
                if (!this.browserNavigationService.isNavigationAllowed(processedUrl)) {
                    return {success: false};
                }
            } else {
                if (disableNewTabNavigationIfNavigationDisabled && !this.browserNavigationService.isNavigationAllowed(processedUrl)) {
                    return {success: false};
                }
            }
        }
        if (!this.isActionAuthorized(action, true)) {
            return {success: false};
        }
        const relative = processedUrl.startsWith('/');
        if (relative) {
            this.window.history.pushState(null, null, processedUrl);
        } else {
            if (newTab) {
                this.window.open(processedUrl, "_blank");
            } else {
                this.window.location.href = sanitizeUrl(processedUrl);
            }
        }
        return {
            success: true
        };
    }

    private handleEventAction(source: string, action: Action, metadata?: ActionMetadata): ActionResult {
        if (!action.action || typeof action.action !== 'string') {
            throw new Error('The provided action is not a valid event name');
        }
        this.enrichData(action, metadata);
        if (action?.data && !(typeof action.data === 'string' || action.data instanceof String) && !action?.data?.originatorUrl) {
            action.data.originatorUrl = this.window.location.pathname;
        }
        const disableInteractionIfNavigationIsDisabled = this.tenant.getContext()?.behaviour?.actions?.disableInteractionIfNavigationIsDisabled ?? false;
        if (disableInteractionIfNavigationIsDisabled) {
            if (!this.actionInteractionService.isInteractionAllowed(action)) {
                return {success: false};
            }
        }
        if (!this.isActionAuthorized(action, true)) {
            return {success: false};
        }
        this.eventBus.dispatchBroadcast<any>(source || ActionHandlerTypeName, action.action, action.data, null, false, false);
        return {success: true};
    }

    private async handleCallbackAction(context: any, action: Action, metadata?: ActionMetadata): Promise<ActionResult> {
        if (!context) {
            throw new Error('The provided context is not valid');
        }
        if (typeof action.action !== 'function') {
            throw new Error('The provided action is not a callback function.');
        }
        this.enrichData(action, metadata);
        const disableInteractionIfNavigationIsDisabled = this.tenant.getContext()?.behaviour?.actions?.disableInteractionIfNavigationIsDisabled ?? false;
        if (disableInteractionIfNavigationIsDisabled) {
            if (!this.actionInteractionService.isInteractionAllowed({context: context, ...action})) {
                return {success: false};
            }
        }
        if (!this.isActionAuthorized(action, true)) {
            return {success: false};
        }
        try {
            const result = await action.action?.call(context, action);
            return {
                success: true,
                result: result
            };
        } catch (e) {
            console.error(e);
            return {success: false};
        }
    }

    private dispatchActionsDisableStateToggleEvent() {
        this.eventBus.dispatchBroadcast<ActionStateToggleEvent>(
            ActionHandlerTypeName,
            constants.ACTIONS_DISABLED_STATE_TOGGLE_EVENT,
            {actionsDisabled: this._actionsEnabled}
        );
    }

    private dispatchActionPerformedEvent(action: Action, actionResult?: ActionResult): void {
        const source = this.getSource(action.data?.source)
        const eventKey = this.getActionPerformedEventKey(source);
        const eventData: ActionPerformedEvent = {
            source: source,
            sourceId: action.data?.sourceId,
            success: !!actionResult?.success,
            result: actionResult?.result,
            action: action
        };
        const event = new CustomEvent<ActionPerformedEvent>(eventKey, {
            detail: eventData
        });
        this.window.dispatchEvent(event);
    }

    private dispatchActionUnauthorizedEvent(action: Action): void {
        const source = this.getSource(action.data?.source)
        const eventKey = this.getActionUnauthorizedEventKey(source);
        const eventData: ActionUnauthorizedEvent = {
            source: source,
            sourceId: action.data?.sourceId,
            action: action
        };
        const event = new CustomEvent<ActionUnauthorizedEvent>(eventKey, {
            detail: eventData
        });
        this.window.dispatchEvent(event);
    }

    private enrichData(action: Action, metadata?: ActionMetadata | any) {
        if (!metadata) {
            return;
        }
        action.data = Object.assign({}, action.data ?? {}, metadata ?? {});
    }

    private getActionPerformedEventKey(source?: string): string {
        return `${ActionHandlerEvents.ACTION_PERFORMED_EVENT_KEY_PREFIX}${this.getSource(source)}`;
    }

    private getActionUnauthorizedEventKey(source?: string): string {
        return `${ActionHandlerEvents.ACTION_UNAUTHORIZED_EVENT_KEY_PREFIX}${this.getSource(source)}`;
    }

    private getSource(source?: string): string {
        return source ?? 'unknown-source';
    }

    private getActionHandlerInterceptor(): IActionHandlerInterceptor | undefined {
        return this.serviceCollection.resolve<IActionHandlerInterceptor>(IActionHandlerInterceptorTypeName);
    }

    private async intercept(context: any, source: string, action: Action, metadata?: ActionMetadata, fireActionPerformedEvent: boolean = false): Promise<undefined | boolean> {
        try {
            const interceptor = this.getActionHandlerInterceptor();
            if (interceptor) {
                return await interceptor.intercept(context, source, action, metadata, fireActionPerformedEvent);
            }
        } catch (e) {
            console.debug('An exception occurred while intercepting the action.', e);
            return undefined;
        }
    }
}
