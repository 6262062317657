/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IIconLoader} from "sirius-platform-support-library/shared/icons/loaders/icon-loader.interface";
import {
    ISiriusIconsRegistry
} from "sirius-platform-support-library/shared/icons/registry/sirius-icons-registry.interface";
import {allShapesFiles} from "./cds.constants";
import {CdsIconParser} from "./cds-icon.parser";
import {InternalIcon} from "sirius-platform-support-library/shared/icons/registry/internal.icon";
import {UxdIconParser} from "../uxd/uxd-icon.parser";

export const CdsIconsLoaderTypeName = 'CdsIconsLoader';

export class CdsIconsLoader implements IIconLoader {
    public static readonly BASE_RESOURCE_URL = '/libs/@cds/core/icon/shapes/'
    public static readonly UNIQUE_CODE = 'CDS';
    public static readonly SET_CODE = 'cds';

    private readonly iconParser: CdsIconParser;

    public constructor(iconParser: CdsIconParser) {
        this.iconParser = iconParser;
    }

    public getUniqueCode(): string {
        return CdsIconsLoader.UNIQUE_CODE;
    }

    public async bind(iconRegistry: ISiriusIconsRegistry): Promise<void> {
        await this.registerLazyIcons(iconRegistry);
    }

    public async load(icon: InternalIcon): Promise<void> {
        try {
            const resourceUrl = `${CdsIconsLoader.BASE_RESOURCE_URL}${icon.code}.js`;
            const resourceContent = await this.fetchResourceContent(resourceUrl);
            const iconProperties = this.iconParser.parse(icon.code, resourceContent);
            const resource = this.iconParser.getPrimaryResource(iconProperties);
            icon.load(
                resource,
                iconProperties.properties,
                UxdIconParser.PRIMARY_ICON_PROPERTY,
                UxdIconParser.OUTLINE_ICON_PROPERTY,
                UxdIconParser.SOLID_ICON_PROPERTY,
            );
        } catch (e) {
            console.warn(`Could not load requested icon (${icon.code}) due to an encountered exception.`, e);
        }
    }

    private async registerLazyIcons(iconRegistry: ISiriusIconsRegistry): Promise<void> {
        allShapesFiles.forEach((shapeFileName) => {
            const shapeCode = shapeFileName.substring(0, shapeFileName.lastIndexOf('.'));
            iconRegistry.addIcon({
                code: shapeCode,
                resource: undefined,
                set: CdsIconsLoader.SET_CODE,
                loaderCode: this.getUniqueCode()
            });
        });
    }

    private async fetchResourceContent(resourceUrl: string): Promise<string | undefined> {
        try {
            const response = await fetch(resourceUrl);
            if (response.status !== 200) {
                return undefined;
            }
            return await response.text();
        } catch (e) {
            console.warn(`Could not fetch icon resource ${resourceUrl} due to an encountered exception.`, e);
            return undefined;
        }
    }
}
