/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export class TenantContextLoaderError extends Error {
    private readonly _defaultedDueToUnavailability: boolean;

    public get defaultedDueToUnavailability(): boolean {
        return this._defaultedDueToUnavailability;
    }

    public constructor(message?: string, defaultDueToUnavailability?: boolean) {
        super(message);
        this.name = 'TenantContextLoaderError';
        this._defaultedDueToUnavailability = defaultDueToUnavailability;
    }
}
