/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import * as singleSpa from 'single-spa';
import {constructApplications, constructLayoutEngine, constructRoutes} from 'single-spa-layout';
import {LayoutEngine} from "single-spa-layout/dist/types/browser/constructLayoutEngine";
import {InputRoutesConfigObject} from "single-spa-layout/dist/types/isomorphic/constructRoutes";
import {IApplication} from "../application.interface";
import {SiteConfigToMicroFrontendsMapper} from "../mappers/site-config-to-micro-frontends.mapper";
import {SiteConfigToSiteStructureMapper} from "../mappers/site-config-to-site-structure.mapper";
import {MicroFrontendComponent} from "../models/micro-frontend-component.model";
import {TenantContext} from "sirius-platform-support-library/tenants/tenant-context";
import {
    IVirtualRoutesProvider
} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-routes-provider.interface";
import {VirtualRoute} from 'sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-route';
import {SystemJs} from "../../core/dynamic-imports/system-js";
import {
    IDefaultPagesService
} from 'sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface';

export const SingleSpaApplicationTypeName = 'SingleSpaApplication';

export class SingleSpaApplication implements IApplication, IVirtualRoutesProvider {
    private readonly window: Window;
    private readonly systemJs: SystemJs;
    private readonly siteConfigToMicroFrontendsMapper: SiteConfigToMicroFrontendsMapper;
    private readonly siteConfigToSiteStructureMapper: SiteConfigToSiteStructureMapper;
    private readonly defaultPagesService: IDefaultPagesService;

    private bootstrapped: boolean;
    private siteStructure: InputRoutesConfigObject;
    private siteComponents: Record<string, MicroFrontendComponent>;

    private resolvedRoutes: any;
    private resolvedApplications: any;
    private layoutEngine: LayoutEngine;

    private readonly awaitables: Promise<any>[] = [];

    constructor(
        window: Window,
        systemJs: SystemJs,
        siteConfigToMicroFrontendsMapper: SiteConfigToMicroFrontendsMapper,
        siteConfigToSiteStructureMapper: SiteConfigToSiteStructureMapper,
        defaultPagesService: IDefaultPagesService
    ) {
        this.window = window;
        this.systemJs = systemJs;
        this.siteConfigToMicroFrontendsMapper = siteConfigToMicroFrontendsMapper;
        this.siteConfigToSiteStructureMapper = siteConfigToSiteStructureMapper;
        this.defaultPagesService = defaultPagesService;
    }

    public async bootstrap(tenantContext: TenantContext): Promise<void> {
        try {
            const self = this;

            this.siteStructure = this.siteConfigToSiteStructureMapper.mapElements(tenantContext, tenantContext.site);
            this.siteComponents = this.siteConfigToMicroFrontendsMapper.mapSectionsAndRoutes(tenantContext, tenantContext.site);

            singleSpa.addErrorHandler(async (err) => {
                if (singleSpa.getAppStatus(err.appOrParcelName) === singleSpa.LOAD_ERROR) {
                    await this.handleMicroFrontendLoadingError(err.appOrParcelName);
                }
            });

            this.resolvedRoutes = constructRoutes(this.siteStructure);
            this.resolvedApplications = constructApplications({
                routes: this.resolvedRoutes,
                // Enable this to emulate slow loading of micro frontends
                /* loadApp: (config) => {
                     const delay = 30000;// Math.floor(Math.random() * 10) * 1000;
                     return new Promise((resolve) => {
                         setTimeout(() => {
                             resolve(config);
                         }, delay);
                     }).then(config => this.loadApp.bind(self)(config))
                 }*/
                loadApp: ({name}) => {
                    const loadingFunction = this.loadApp.bind(self);
                    this.awaitables.push(loadingFunction);
                    return loadingFunction.apply(this, [{name}]);
                }
            });

            this.resolvedApplications.forEach((app) => {
                const component = this.siteComponents[app.name]
                if (component) {
                    app.customProps = component.properties;
                    singleSpa.registerApplication(app);
                }
            });

            this.layoutEngine = constructLayoutEngine({
                routes: this.resolvedRoutes,
                applications: this.resolvedApplications
            });

            this.layoutEngine.activate();

            const currentRoute = window.location.pathname?.trim() || '/';
            if (currentRoute === '/') {
                const defaultRoute = (this.siteStructure as any).defaultRoute;
                if (defaultRoute) {
                    singleSpa.navigateToUrl(defaultRoute);
                }
            }
        } catch (e) {
            console.error(e);
            throw e;
        } finally {
            this.bootstrapped = true;
        }
    }

    public async start(): Promise<void> {
        if (!this.bootstrapped) {
            throw new Error('Single SPA application was not bootstrapped on the platform.');
        }

        singleSpa.start();

        await Promise.allSettled(this.awaitables);

        console.debug('Application started.');
    }

    public getVirtualRoutes(): VirtualRoute[] {
        return [];
    }

    private async handleMicroFrontendLoadingError(microFrontendName: string): Promise<void> {
        this.systemJs.delete(microFrontendName);
        if (this.defaultPagesService.isLoadingErrorFullScreenPageEnabled()) {
            await this.defaultPagesService.navigateToFullScreenErrorPage(this);
        }
    }

    private async loadApp({name}): Promise<import('single-spa').LifeCycles> {
        try {
            const component = this.siteComponents[name]
            if (!component?.loaderFunction) {
                throw new Error(`Failed to load "${name}".`);
            }
            return component?.loaderFunction(component.loadingOptions);
        } catch (e) {
            await this.handleMicroFrontendLoadingError(name);
            return undefined;
        }
    }
}
