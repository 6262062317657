/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {
    IUserProfileService,
    UserProfileUpdatedCallback
} from "sirius-platform-support-library/shared/user-profile/user-profile-service.interface";
import {
    IUserProfileHandler,
    IUserProfileHandlerTypeName
} from "sirius-platform-support-library/shared/user-profile/user-profile-handler.interface";
import {UserProfileConstants} from "sirius-platform-support-library/shared/user-profile/user-profile.constants";
import {UserDetails} from "sirius-platform-support-library/models/user/user-details";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    IUserContextUpdater
} from "sirius-platform-support-library/shared/authentication/user-context/user-context-updater.interface";
import {
    IAuthenticationService
} from "sirius-platform-support-library/shared/authentication/authentication-service.interface";
import {
    AuthenticationStateEnum
} from "sirius-platform-support-library/shared/authentication/user-context/authentication-state.enum";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {UserProfileEvents} from "sirius-platform-support-library/shared/user-profile/events/user-profile-events";
import {
    UserProfileUpdatedEvent
} from "sirius-platform-support-library/shared/user-profile/events/user-profile-updated.event";
import {UserInfo} from "sirius-platform-support-library/models/user/user-info";
import _ from "lodash";

export const UserProfileServiceTypeName = 'UserProfileService';

export class UserProfileService implements IUserProfileService, IBeforePlatformReadyInit {
    public static readonly NO_HANDLER_FOUND_WARNING = 'No UserProfileHandler found in the dependency container. Please make sure you have one implemented in your tenant custom code library.';
    public static readonly USER_IS_NOT_AUTHENTICATED = 'User is not authenticated. Could not update profile in the current state.';

    public static build(
        eventBus: IEventBus,
        authenticationService: IAuthenticationService,
        userContextUpdater: IUserContextUpdater,
        serviceCollection: IServiceCollection
    ): UserProfileService {
        let instance = ObjectUtility.getFromObjectPath<UserProfileService>(UserProfileConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new UserProfileService(
                eventBus,
                authenticationService,
                userContextUpdater,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(UserProfileConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IUserProfileService {
        return ObjectUtility.getFromObjectPath<IUserProfileService>(UserProfileConstants.GLOBAL_KEY);
    }

    private readonly eventBus: IEventBus;
    private readonly authenticationService: IAuthenticationService;
    private readonly userContextUpdater: IUserContextUpdater;
    private readonly serviceCollection: IServiceCollection;

    private constructor(
        eventBus: IEventBus,
        authenticationService: IAuthenticationService,
        userContextUpdater: IUserContextUpdater,
        serviceCollection: IServiceCollection
    ) {
        this.eventBus = eventBus;
        this.authenticationService = authenticationService;
        this.userContextUpdater = userContextUpdater;
        this.serviceCollection = serviceCollection;
    }

    public async init(): Promise<void> {
    }

    public async get(): Promise<UserDetails> {
        const userContext = this.authenticationService.getUserContext();
        const baseUserDetails = {
            principalName: userContext.principalName,
            displayName: userContext.displayName,
            firstName: userContext.firstName,
            lastName: userContext.lastName
        };
        const handler = this.getUserProfileHandler();
        if (!handler) {
            console.warn(UserProfileService.NO_HANDLER_FOUND_WARNING)
            return baseUserDetails;
        }
        const tenantUserDetails = await handler.get(userContext);
        return _.merge(baseUserDetails, tenantUserDetails);
    }

    public async update(userDetails: UserDetails): Promise<UserDetails> {
        if (!userDetails) {
            return;
        }
        const userContext = this.authenticationService.getUserContext();
        if (userContext.authenticationState !== AuthenticationStateEnum.AUTHENTICATED) {
            throw new Error(UserProfileService.USER_IS_NOT_AUTHENTICATED);
        }
        let userInfo: UserInfo;
        const handler = this.getUserProfileHandler();
        if (handler) {
            userInfo = await handler.update(userDetails);
        } else {
            console.warn(UserProfileService.NO_HANDLER_FOUND_WARNING);
            userInfo = {
                ...userDetails,
                claims: userContext.claims
            }
        }
        await this.userContextUpdater.updateUserContext(userInfo);
        this.eventBus.dispatchBroadcast<UserProfileUpdatedEvent>(UserProfileServiceTypeName, UserProfileEvents.USER_PROFILE_UPDATED, {
            userDetails: userDetails
        });
        return userInfo;
    }

    public onUserProfileUpdated(context: any, subscriberName: string, callback: UserProfileUpdatedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<UserProfileUpdatedEvent>(this, subscriberName, UserProfileEvents.USER_PROFILE_UPDATED, (busEvent) => {
            callback?.call(context, busEvent.data);
        });
    }

    private getUserProfileHandler(): IUserProfileHandler {
        return this.serviceCollection.resolve<IUserProfileHandler>(IUserProfileHandlerTypeName);
    }
}