/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {InputRoutesConfigObject} from "single-spa-layout/dist/types/isomorphic/constructRoutes";
import {UniqueNameGenerator} from "./unique-name-generator";
import {
    Footer,
    Header,
    MicroFrontend,
    Navbar,
    QuickNav,
    Route,
    SiteConfig,
    TenantContext,
    Visibility
} from "sirius-platform-support-library/tenants/tenant-context";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    VirtualRoutesProviderHolder
} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-routes-provider.holder";
import {MicroFrontendLoadersFactory} from "../loaders/micro-frontend-loaders.factory";
import {SectionMarkerCodes} from "sirius-platform-support-library/shared/page-customizations/section-marker-codes";

export const SiteConfigToSiteStructureMapperTypeName = 'SiteConfigToSiteStructureMapper';

export class SiteConfigToSiteStructureMapper extends VirtualRoutesProviderHolder {
    public static readonly HEADER_ELEMENT_TYPE = 'div';
    public static readonly NAV_ELEMENT_TYPE = 'div';
    public static readonly ROUTER_ELEMENT_TYPE = 'main';
    public static readonly FOOTER_ELEMENT_TYPE = 'footer';

    private readonly uniqueNameGenerator: UniqueNameGenerator;
    private readonly microFrontendLoadersFactory: MicroFrontendLoadersFactory;

    public constructor(
        microFrontendLoadersFactory: MicroFrontendLoadersFactory,
        serviceCollection: IServiceCollection,
    ) {
        super(serviceCollection);
        this.microFrontendLoadersFactory = microFrontendLoadersFactory;
        this.uniqueNameGenerator = new UniqueNameGenerator();
    }

    public mapElements(tenantContext: TenantContext, siteConfig: SiteConfig): InputRoutesConfigObject {
        const stickyTopSection = this.getWrappingElement('div', {class: 'sticky-top-section'});
        const topSection = this.getWrappingElement('div', {class: 'top-section'});
        const contentWrapperSection = this.getWrappingElement('div', {class: `${SectionMarkerCodes.CONTENT_MARKER} content-wrapper-section`});
        const routerSection = this.getWrappingElement(SiteConfigToSiteStructureMapper.ROUTER_ELEMENT_TYPE, {
            class: `content-section ${siteConfig?.restrictMaxPageWidth ? 'max-1920' : ''}`,
            id: 'content-section'
        });
        const mainSection = this.getWrappingElement('div', {class: 'main-section'});
        const accessibilityButton = this.getWrappingElement('sirius-skip-to-content');
        const toastsContainer = this.getWrappingElement('div', {class: `${SectionMarkerCodes.TOASTS_MARKER} toasts-container`});
        const tenantCustomOverlay = this.getWrappingElement('div', {
            id: 'tenant-custom-overlay',
            class: 'tenant-custom-overlay'
        });
        this.addElementToSection(mainSection, accessibilityButton, true);
        this.addElementToSection(mainSection, stickyTopSection, true);
        this.addElementToSection(contentWrapperSection, routerSection, true);

        const footerSection = this.getWrappingElement(SiteConfigToSiteStructureMapper.FOOTER_ELEMENT_TYPE, {class: `${SectionMarkerCodes.FOOTER_MARKER} footer-section`});

        const headerSection = this.getWrappingElement(SiteConfigToSiteStructureMapper.HEADER_ELEMENT_TYPE, {class: `${SectionMarkerCodes.HEADER_MARKER}`});
        const headerElement = this.mapSectionIfConfigured<Header>(siteConfig.top?.header, UniqueNameGenerator.HEADER_ROLE);
        this.addElementToSection(headerSection, headerElement);

        const navSection = this.getWrappingElement(SiteConfigToSiteStructureMapper.NAV_ELEMENT_TYPE, {class: `${SectionMarkerCodes.NAVBAR_MARKER}`});
        const navElement = this.mapSectionIfConfigured<Navbar>(siteConfig.top?.navbar, UniqueNameGenerator.NAVBAR_ROLE);
        this.addElementToSection(navSection, navElement);

        const quickNavSection = this.getWrappingElement(SiteConfigToSiteStructureMapper.NAV_ELEMENT_TYPE, {class: `${SectionMarkerCodes.QUICKNAV_MARKER}`});
        const quickNavElement = this.mapSectionIfConfigured<QuickNav>(siteConfig?.top?.quicknav, UniqueNameGenerator.QUICKNAV_ROLE);
        this.addElementToSection(quickNavSection, quickNavElement);

        const accessibilityTab = this.getWrappingElement('div', {tabindex: '0', id: 'content-start'});
        const progressIndicatorSection = this.getWrappingElement('div', {
            tabindex: '0',
            id: 'sirius-progress-indicator-bar-wrapper'
        });

        this.addElementToSection(topSection, headerSection);
        this.addElementToSection(topSection, navSection);
        this.addElementToSection(topSection, quickNavSection)
        this.addElementToSection(topSection, accessibilityTab, true);
        this.addElementToSection(topSection, progressIndicatorSection, true);

        const alertsSection = this.getWrappingElement('div', {
            class: `${SectionMarkerCodes.BANNER_ALERTS_MARKER} alerts-section`,
            id: 'sirius-banner-alerts'
        });
        this.addElementToSection(stickyTopSection, alertsSection, true);

        if (siteConfig.top?.sticky) {
            this.addElementToSection(stickyTopSection, topSection, true);
        } else {
            this.addElementToSection(mainSection, topSection, true);
        }

        this.addElementToSection(mainSection, toastsContainer, true);
        this.addElementToSection(mainSection, tenantCustomOverlay, true);

        this.addElementToSection(mainSection, routerSection, true);

        this.addElementToSection(mainSection, contentWrapperSection, true);


        const footerElement = this.mapSectionIfConfigured<Footer>(siteConfig.footer, UniqueNameGenerator.FOOTER_ROLE);
        this.addElementToSection(footerSection, footerElement);

        const configuredDefaultRoute = siteConfig.routing.default;
        let defaultRoute = '';

        siteConfig.routing.routes.forEach(route => {
            if (configuredDefaultRoute === route.route) {
                defaultRoute = configuredDefaultRoute;
            }
            const routeSection = this.mapRouteElement<Route>(route, defaultRoute === route.route);
            this.addElementToSection(routerSection, routeSection);
        });

        this.addVirtualRoutes(routerSection);

        const rootSection = {
            disableWarnings: true, // Disabling warning since we are using non-default properties as response to Single SPA route construction
            containerEl: '.site-wrapper > .site-content',
            routes: [],
            defaultRoute
        };

        const progressIndicatorBlockingMfeSection = this.getWrappingElement('div', {
            tabindex: '0',
            id: 'sirius-progress-indicator-content-overlay',
            class: 'progress-indicator-content-overlay'
        });
        this.addElementToSection(contentWrapperSection, progressIndicatorBlockingMfeSection, true);

        this.addElementToSection(rootSection, mainSection, true);
        this.addElementToSection(rootSection, footerSection, true);

        return rootSection;
    }

    private getWrappingElement(type: string, attributes?: Record<string, string>): any {
        const attrs = attributes ? Object.getOwnPropertyNames(attributes).map(name => {
            return {name: name, value: attributes[name]};
        }) : [];

        return {
            type: type,
            attrs: attrs,
            routes: []
        };
    }

    private mapRouteElement<T extends Route>(section: T, isDefault: boolean = false): any {
        if (!section) {
            return undefined;
        }

        const route = {
            type: 'route',
            routes: []
        } as any;

        if (isDefault) {
            route.default = isDefault;
        } else {
            route.path = section.route;
        }

        const app = this.mapSection<T>(section, UniqueNameGenerator.CONTENT_ROLE);

        route.routes.push(app);

        return route;
    }

    private mapSection<T extends MicroFrontend>(section: T, role: string): any {
        if (!section) {
            return undefined;
        }
        const uniqueName = this.uniqueNameGenerator.getUniqueName(section.microFrontend, role);
        const result = {
            type: 'application',
            name: uniqueName,
            error: `<div><h5>Oops! The ${section.microFrontend.name} MFE isn't working right now</h5></div>`
        };
        if (role) {
            const loaderName = `${role?.toLowerCase()}-loader`;
            // @ts-ignore
            result.loader = this.microFrontendLoadersFactory.getLoaderFor(loaderName);
        }
        return result;
    }

    private mapSectionIfConfigured<T extends MicroFrontend & Visibility>(section: T, role: string): any {
        if (!section?.microFrontend) {
            return undefined;
        }
        return this.mapSection<T>(section, role);
    }

    private addElementToSection(section: any, element: any, force: boolean = false): any {
        let shouldAdd = false;
        if (element) {
            // eslint-disable-next-line no-prototype-builtins
            if (element.hasOwnProperty('routes')) {
                if (element.routes.length > 0) {
                    shouldAdd = true;
                }
            } else {
                shouldAdd = true;
            }
        }
        if (force || shouldAdd) {
            section.routes.push(element);
        }
    }

    private addVirtualRoutes(routerSection: any): void {
        const virtualRoutes = this.getVirtualRoutes();
        virtualRoutes.forEach(virtualRoute => {
            if (!virtualRoute.code || !virtualRoute.route) {
                return;
            }
            this.addElementToSection(routerSection, {
                type: 'route',
                path: virtualRoute.route,
                routes: [
                    {
                        type: 'application',
                        name: this.getVirtualRouteUniqueName(virtualRoute),
                        virtual: true,
                    }
                ]
            });
        });
    }
}
