/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {BannerAlert} from "sirius-platform-support-library/shared/banner-alerts/banner-alert";
import {ValidationError} from "sirius-platform-support-library/validators/validation-error";
import {BannerAlertActionValidator} from "./banner-alert-action.validator";

export const BannerAlertValidatorTypeName = 'BannerAlertValidator';

export class BannerAlertValidator {
    private readonly actionValidator: BannerAlertActionValidator;

    public constructor() {
        this.actionValidator = new BannerAlertActionValidator();
    }

    public validate(bannerAlert: BannerAlert): void {
        const errors: Error[] = [];
        if (bannerAlert.actions) {
            bannerAlert.actions.forEach(action => {
                try {
                    this.actionValidator.validate(action);
                } catch (e) {
                    errors.push(...e.errors);
                }
            });
        }
        if (errors.length > 0) {
            throw new ValidationError('Validation errors detected for the given banner alert', errors);
        }
    }

    public validateMany(bannerAlerts: BannerAlert[]): void {
        const errors: Error[] = [];
        bannerAlerts.forEach(bannerAlert => {
            try {
                this.validate(bannerAlert);
            } catch (e) {
                errors.push(...e.errors);
            }
        })
        if (errors.length > 0) {
            throw new ValidationError('Please provide a valid banner alert', errors);
        }
    }
}
