/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ThemingConfig} from "sirius-platform-support-library/models/theming/theming-config.model";
import {DefaultTheme} from "sirius-platform-support-library/shared/theming/theming.constants";

export const DEFAULT_THEMING_CONFIG: ThemingConfig = {
    enabledThemes: [DefaultTheme.Dell.toString()],
    defaultThemeCode: DefaultTheme.Dell.toString(),
    themes: {
        [DefaultTheme.Dell.toString()]: {
            code: DefaultTheme.Dell.toString(),
            name: 'Dell',
            defaultVariantCode: 'light',
            variants: {
                light: {
                    code: 'light',
                    name: 'Light',
                },
                dark: {
                    code: 'dark',
                    name: 'Dark',
                }
            }
        }
    }
};

