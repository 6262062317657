/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {sanitizeUrl} from "@braintree/sanitize-url";

export function removeQueryParametersAndReplaceState(removableQueryParameters: string[], windowToken: Window): void {
    if (!removableQueryParameters) {
        return;
    }
    let url = windowToken.location.href;
    url = removeQueryParameters(url, removableQueryParameters);
    windowToken.history.replaceState(null, null, url);
}

export function removeQueryParameters(url, removableQueryParameters: string[]): string {
    if (!removableQueryParameters) {
        return url;
    }
    return removableQueryParameters.reduce((url, param) => removeQueryParameter(url, param), url);
}

export function removeQueryParameter(url, parameter) {
    const urlParts = url.split('?');
    if (urlParts.length >= 2) {
        const encodedParam = encodeURIComponent(parameter);
        const pars = urlParts[1].split(/[&;]/g);
        for (let i = pars.length; i-- > 0;) {
            if (pars[i].lastIndexOf(encodedParam + '=', 0) !== -1 || encodedParam === pars[i]) {
                pars.splice(i, 1);
            }
        }
        return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

export function performRedirect(redirectUrl: string, windowToken: Window): void {
    if (!redirectUrl) {
        return;
    }
    try {
        const sanitizedUrl = sanitizeUrl(redirectUrl);
        if (!sanitizedUrl) {
            return;
        }
        if (sanitizedUrl.startsWith('http://') || sanitizedUrl.startsWith('https://')) {
            const url = new URL(sanitizedUrl);
            if (url.host.toLowerCase() === windowToken.location.host.toLowerCase()) {
                windowToken.history.replaceState(null, null, sanitizedUrl);
            } else {
                windowToken.location.href = sanitizedUrl;
            }
            return;
        }
        if (sanitizedUrl === windowToken.location.pathname) {
            return;
        }
        windowToken.history.replaceState(null, null, sanitizedUrl);
    } catch (e) {
        return;
    }
}
