/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    RequestedProgressIndicator,
    VisibleProgressIndicator
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicator";
import {
    ITranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations-cache.interface";
import {
    TranslationsRequestedCallback
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    LocalizableResourceTranslationService
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource-translation.service";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";

import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";

import {v4 as uuidv4} from 'uuid';
import {
    ProgressIndicatorNonBlockingComponent
} from "../site/components/progress-indicator/non-blocking/progress-indicator.non-blocking.web-component";
import {
    ProgressIndicatorBlockingComponent
} from "../site/components/progress-indicator/blocking/progress-indicator.blocking.web-component";

export class RendarableProgressIndicator implements VisibleProgressIndicator {
    public static readonly PROGRESS_INDICATOR_TYPE = '{{PROGRESS_INDICATOR_TYPE}}';
    public static readonly PROGRESS_VALUE_PLACEHOLDER = '{{PROGRESS_VALUE}}';
    public static readonly LOCALIZED_TITLE_PLACEHOLDER = '{{PROGRESS_INDICATOR_TITLE}}';
    private static readonly PROGRESS_INDICATOR_ID_ATTRIBUTE = 'progress-indicator-id';
    private static readonly PROGRESS_INDICATOR_ELEMENT = ProgressIndicatorNonBlockingComponent.CUSTOM_ELEMENT_NAME;
    private static readonly PROGRESS_INDICATOR_BLOCKING_ELEMENT = ProgressIndicatorBlockingComponent.CUSTOM_ELEMENT_NAME;
    public readonly context: any;
    public id: string;
    public timestamp: number;
    public owner: string;
    public deterministic: boolean;
    public blocking: boolean;
    public progress: number;
    public localizationCode: string;
    public localizedText: string;
    public showElapsedTime: boolean;
    public showProgressPercent: boolean;
    public backgroundColor: string;
    public textColor: string;
    public border: boolean;
    public blockContentOnly: boolean;
    public localizedResources?: Record<string, any>;
    public onTranslationsRequested?: TranslationsRequestedCallback;
    public target: HTMLElement;
    private readonly translationsCache: ITranslationsCache;
    private readonly translationService: ITranslationService;
    private document: Document;

    public constructor(
        document: Document,
        context: any,
        progressIndicator: RequestedProgressIndicator,
        target: HTMLElement,
        localizationService: ILocalizationService,
        serviceCollection: IServiceCollection
    ) {
        this.document = document;
        this.context = context;
        this.target = target

        this.translationsCache = new TranslationsCache(context, this);
        this.translationService = new LocalizableResourceTranslationService(
            localizationService,
            this.translationsCache,
            serviceCollection
        );

        this.id = uuidv4().toLowerCase();
        this.timestamp = Math.round(Date.now() / 1000);
        this.owner = progressIndicator.owner || "";
        this.deterministic = ObjectUtility.isDefined(progressIndicator.deterministic) ? progressIndicator.deterministic : false;
        this.blocking = ObjectUtility.isDefined(progressIndicator.blocking) ? progressIndicator.blocking : true;
        this.localizationCode = progressIndicator.localizationCode;
        this.progress = progressIndicator.progress;
        this.showElapsedTime = ObjectUtility.isDefined(progressIndicator.showElapsedTime) ? progressIndicator.showElapsedTime : false;
        this.backgroundColor = progressIndicator.backgroundColor;
        this.textColor = progressIndicator.textColor;
        this.border = ObjectUtility.isDefined(progressIndicator.border) ? progressIndicator.border : false;
        this.showProgressPercent = ObjectUtility.isDefined(progressIndicator.showProgressPercent) ? progressIndicator.showProgressPercent : false;
        this.blockContentOnly = ObjectUtility.isDefined(progressIndicator.blockContentOnly) ? progressIndicator.blockContentOnly : false;

        this.localizedResources = progressIndicator.localizedResources || {};
        this.onTranslationsRequested = progressIndicator.onTranslationsRequested;
    }

    public async localize(): Promise<void> {
        try {
            this.localizedText = await this.translationService.translate(this.localizationCode!!);
        } catch (e) {
            console.warn('Could not localize title', this, e);
        }
    }

    public create(): HTMLElement {
        const elementType = this.blocking ? RendarableProgressIndicator.PROGRESS_INDICATOR_BLOCKING_ELEMENT : RendarableProgressIndicator.PROGRESS_INDICATOR_ELEMENT;
        const pendingProgressIndicatorElement: HTMLElement = this.document.createElement(elementType);
        pendingProgressIndicatorElement.setAttribute(RendarableProgressIndicator.PROGRESS_INDICATOR_ID_ATTRIBUTE, this.id);
        this.updateAttributes(pendingProgressIndicatorElement);
        pendingProgressIndicatorElement.classList.add('progress-indicator');
        return pendingProgressIndicatorElement;
    }

    public updateAttributes(targetElement: HTMLElement): void {
        targetElement.setAttribute('progress', this.progress?.toString() || '');
        targetElement.setAttribute('title-text', this.localizedText);
        if (this.deterministic) {
            targetElement.setAttribute('deterministic', "true");
        }
        if (this.showElapsedTime) {
            targetElement.setAttribute('show-elapsed-time', "true");
        }
        if (this.backgroundColor) {
            targetElement.setAttribute('bgcolor', this.backgroundColor);
        }
        if (this.textColor) {
            targetElement.setAttribute('txtcolor', this.textColor);
        }
        if (this.border) {
            targetElement.setAttribute('border', "true");
        }
        if (this.showProgressPercent) {
            targetElement.setAttribute('show-progress', "true");
        }
    }

    public toVisibleProgressIndicator(): VisibleProgressIndicator {
        return {
            id: this.id,
            timestamp: this.timestamp,
            owner: this.owner,
            blocking: this.blocking,
            deterministic: this.deterministic,
            localizationCode: this.localizationCode,
            progress: this.progress,
            showElapsedTime: this.showElapsedTime,
            showProgressPercent: this.showProgressPercent,
            border: this.border,
            blockContentOnly: this.blockContentOnly,
            backgroundColor: this.backgroundColor,
            textColor: this.textColor
        }
    }
}
