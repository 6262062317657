/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {StandaloneProgressIndicatorsOptions} from "./standalone-progress-indicators.options";

export const IStandaloneProgressIndicatorsServiceTypeName = 'IStandaloneProgressIndicatorsService';

export interface IStandaloneProgressIndicatorsService {
    isShown(): boolean;

    show(text: string, backgroundColor?: string, textColor?: string): void;

    showWithOptions(options: StandaloneProgressIndicatorsOptions, backgroundColor?: string, textColor?: string): void;

    hide(): void;
}
