/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

export const ServerTimingsHeaderEnvironmentCodeProviderTypeName = 'ServerTimingsHeaderEnvironmentCodeProvider';

export class ServerTimingsHeaderEnvironmentCodeProvider {
    public static readonly KEY = 'env';

    private readonly window: Window;

    constructor(window: Window) {
        this.window = window;
    }

    public isProductionEnvironment(): boolean | undefined {
        let code = this.getCode()?.toLowerCase();
        if (!code) {
            return undefined;
        }
        code = code
            .replaceAll('_', '')
            .replaceAll('-', '')
            .replaceAll('.', '');
        if (code.indexOf('nonprod') > -1) {
            return false;
        } else if (code.indexOf('prod') > -1) {
            return true;
        }
        return false;
    }

    public getCode(): string | undefined {
        const values = this.getServerTimingParameters();
        return values[ServerTimingsHeaderEnvironmentCodeProvider.KEY] as string;
    }

    private getServerTimingParameters(): any {
        const result = {};
        if (this.window.performance && this.window.performance.getEntriesByType) {
            const navTiming = performance.getEntriesByType('navigation')
            if (navTiming.length > 0) {
                // @ts-ignore
                const serverTiming = navTiming[0].serverTiming
                if (serverTiming && serverTiming.length > 0) {
                    for (let i = 0; i < serverTiming.length; i++) {
                        result[serverTiming[i].name] = serverTiming[i].description;
                    }
                }
            }
        }
        return result;
    }
}
