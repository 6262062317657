/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ILocaleProvider} from "sirius-platform-support-library/shared/localization/providers/locale-provider.interface";
import {ILocaleStore} from "sirius-platform-support-library/shared/localization/providers/locale-store.interface";
import {LocaleCodeNormalizer} from "sirius-platform-support-library/shared/localization/normalizers/locale-code-normalizer";

import Cookies from 'js-cookie';

export const LwpCookieLocaleProviderTypeName = 'LwpCookieLocaleProvider';

export class LwpCookieLocaleProvider implements ILocaleProvider, ILocaleStore {
    public static readonly CODE = 'LWP_COOKIE';

    public static readonly LWP_COOKIE_NAME: string = 'lwp';
    public static readonly LWP_LANGUAGE_CODE_FIELD: string = 'l';
    public static readonly LWP_COUNTRY_CODE_FIELD: string = 'c';

    private readonly window: Window;
    private readonly cookieService;
    private readonly localeCodeNormalizer: LocaleCodeNormalizer;

    constructor(window: Window) {
        this.window = window;
        this.localeCodeNormalizer = new LocaleCodeNormalizer();
        this.cookieService = Cookies.withConverter({
            write(value, name) {
                return value?.toString();
            },
            read(value, name) {
                return value;
            }
        });
    }

    public getProviderCode(): string {
        return LwpCookieLocaleProvider.CODE;
    }

    public getLocaleCode(): string {
        try {
            const localeCode = this.getCookieLocaleCode();
            return this.localeCodeNormalizer.normalize(localeCode);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public store(localeCode: string): void {
        if (!localeCode) {
            return;
        }

        const currentLocale = this.getLocaleCode();
        if (currentLocale === localeCode) {
            return;
        }

        this.setCookieLocaleCode(localeCode);
    }

    private getCookieLocaleCode(): string | undefined {
        const lwp = this.cookieService.get(LwpCookieLocaleProvider.LWP_COOKIE_NAME);
        const parameters = this.parseLwpValue(lwp);
        if (
            !Object.prototype.hasOwnProperty.call(parameters, LwpCookieLocaleProvider.LWP_LANGUAGE_CODE_FIELD) ||
            !Object.prototype.hasOwnProperty.call(parameters, LwpCookieLocaleProvider.LWP_COUNTRY_CODE_FIELD)
        ) {
            return undefined;
        }
        const languageCode = parameters['l'].trim();
        const countryCode = parameters['c'].trim();
        if (!languageCode || !countryCode) {
            return undefined;
        }
        return `${languageCode.toLowerCase()}_${countryCode.toUpperCase()}`;
    }

    private setCookieLocaleCode(localeCode: string): void {
        const parts = localeCode.split('_');
        if (parts.length !== 2) {
            return;
        }
        const languageCode = parts[0];
        const countryCode = parts[1];
        if (!languageCode || !countryCode) {
            return;
        }
        const originalLwp = this.cookieService.get(LwpCookieLocaleProvider.LWP_COOKIE_NAME);
        const parameters = this.parseLwpValue(originalLwp);
        parameters[LwpCookieLocaleProvider.LWP_LANGUAGE_CODE_FIELD] = languageCode.toLowerCase();
        parameters[LwpCookieLocaleProvider.LWP_COUNTRY_CODE_FIELD] = countryCode.toLowerCase();
        const updatedLwp = this.buildLwpValue(parameters);
        this.cookieService.set(LwpCookieLocaleProvider.LWP_COOKIE_NAME, updatedLwp, {
            path: '/',
            domain: '.dell.com',
            secure: true,
            sameSite: 'None'
        });
    }

    private parseLwpValue(lwpValue: string | undefined): any {
        const parameters = {} as any;
        if (!lwpValue) {
            return parameters;
        }
        const configs = lwpValue.split('&');
        configs.forEach(item => {
            const parts = item.split('=');
            if (parts.length >= 2) {
                parameters[parts[0]] = parts[1];
            }
        });
        return parameters;
    }

    private buildLwpValue(parameters: any): string {
        let lwpValue = '';
        // eslint-disable-next-line
        for (const key of Object.keys(parameters)) {
            lwpValue += `&${key}=${parameters[key]}`;
        }
        lwpValue = lwpValue.substr(1);
        return lwpValue;
    }
}
