/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    ILoadingIndicatorService,
    ILoadingIndicatorServiceTypeName
} from "sirius-platform-support-library/shared/loading-indicators/loading-indicator-service.interface";
import {LoadingIndicatorService, LoadingIndicatorServiceTypeName} from "./loading-indicator.service";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    ISplashScreenService,
    ISplashScreenServiceTypeName
} from "sirius-platform-support-library/shared/splash-screen/splash-screen-service.interface";
import {
    IStandaloneProgressIndicatorsService,
    IStandaloneProgressIndicatorsServiceTypeName
} from "../progress-indicators/standalone/standalone-progress-indicators-service.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";

export class LoadingIndicatorDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer.addUsingFactoryAsSingleton<ILoadingIndicatorService, LoadingIndicatorService>(DependencyType.SHARED, ILoadingIndicatorServiceTypeName, LoadingIndicatorServiceTypeName, (di) => {
            const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
            const progressIndicatorsService = di.resolve<IStandaloneProgressIndicatorsService>(IStandaloneProgressIndicatorsServiceTypeName);
            const splashScreenService = di.resolve<ISplashScreenService>(ISplashScreenServiceTypeName);
            const defaultPagesService = di.resolve<IDefaultPagesService>(IDefaultPagesServiceTypeName);
            return LoadingIndicatorService.build(
                window,
                eventBus,
                progressIndicatorsService,
                splashScreenService,
                defaultPagesService,
                di
            );
        });
    }
}

export const loadingIndicatorDependencyModule = new LoadingIndicatorDependencyModule();
