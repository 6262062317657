/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {applyCacheBreakerScripts} from "./cache-breaker.scripts";
import {applySpaNavigationEnforcement} from "./spa-navigation-enforcer.scripts";
import {assignGlobalWindowHandlers} from "./global-window-handlers.scripts";

export async function applyCommonScripts() {
    applySpaNavigationEnforcement();
    applyCacheBreakerScripts();
    assignGlobalWindowHandlers();
}