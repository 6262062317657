/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    IProgressIndicatorsService,
    IProgressIndicatorsServiceTypeName
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicators-service.interface";
import {ProgressIndicatorsService, ProgressIndicatorsServiceTypeName} from "./progress-indicators-service";
import {
    ILocalizationService,
    ILocalizationServiceTypeName
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {
    IStandaloneProgressIndicatorsService,
    IStandaloneProgressIndicatorsServiceTypeName
} from "./standalone/standalone-progress-indicators-service.interface";
import {
    StandaloneProgressIndicatorsService,
    StandaloneProgressIndicatorsServiceTypeName
} from "./standalone/standalone-progress-indicators.service";
import {
    ILoadingIndicatorService,
    ILoadingIndicatorServiceTypeName
} from "sirius-platform-support-library/shared/loading-indicators/loading-indicator-service.interface";

export class ProgressIndicatorsDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IStandaloneProgressIndicatorsService, StandaloneProgressIndicatorsService>(DependencyType.PRIVATE, IStandaloneProgressIndicatorsServiceTypeName, StandaloneProgressIndicatorsServiceTypeName, (di) => {
                return new StandaloneProgressIndicatorsService(
                    '#sirius-progress-indicator-full-screen-overlay',
                    window,
                    document
                );
            })
            .addUsingFactoryAsSingleton<IProgressIndicatorsService, ProgressIndicatorsService>(DependencyType.SHARED, IProgressIndicatorsServiceTypeName, ProgressIndicatorsServiceTypeName, (di) => {
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const loadingIndicatorService = di.resolve<ILoadingIndicatorService>(ILoadingIndicatorServiceTypeName);

                return ProgressIndicatorsService.build(
                    window,
                    document,
                    localizationService,
                    eventBus,
                    loadingIndicatorService,
                    di
                );
            })
            .addInterfacesOnSingleton(ProgressIndicatorsServiceTypeName, IAfterPlatformReadyInitTypeName);
    }
}

export const progressIndicatorsDependencyModule = new ProgressIndicatorsDependencyModule();
