/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ClarityDialogSize} from "../clarity-dialog-size";
import {IDialogsInteractionHandler} from "../handlers/dialogs-interaction-handler.interface";
import {RendarableDialog} from "../rendarable-dialog";
import {RendarableDialogAction} from "../rendarable-dialog-action";
import {createNodeElement} from "sirius-platform-support-library/utilities/dom-helper";
import {
    IThemingHandlersManager
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";

export class DialogsDomHandler {
    private static readonly DIALOGS_WRAPPER_ID = 'sirius-dialogs-wrapper';
    private static readonly DIALOG_ID_ATTRIBUTE = 'dialog-id';
    private static readonly MODAL_SELECTOR = '.modal';
    private static readonly MODAL_DIALOG_SELECTOR = '.modal-dialog';
    private static readonly DISMISS_SELECTOR = '.dismiss-button';
    private static readonly ACTION_SELECTOR = 'button';
    private static readonly BANNER_ALERT_SELECTOR = 'sirius-banner-alerts';


    private readonly window: Window;
    private readonly document: Document;
    private readonly interactionHandler: IDialogsInteractionHandler;
    private readonly styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler;
    private readonly themingHandlersManager: IThemingHandlersManager;

    private wrapperElement: HTMLElement;
    private containerElement: HTMLElement;
    private dismissElement: HTMLElement;


    public constructor(
        document: Document,
        interactionHandler: IDialogsInteractionHandler,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
    ) {
        this.document = document;
        this.interactionHandler = interactionHandler;
        this.styleSheetsBasedThemeHandler = styleSheetsBasedThemeHandler;
        this.themingHandlersManager = themingHandlersManager;
    }

    public async init(): Promise<void> {
        await this.build();
    }

    public render(dialog: RendarableDialog): void {
        if (!dialog) {
            return;
        }

        const pendingDialogElement: HTMLElement = this.document.createElement('div');
        pendingDialogElement.setAttribute(DialogsDomHandler.DIALOG_ID_ATTRIBUTE, dialog.id);
        pendingDialogElement.classList.add('dialog');
        pendingDialogElement.innerHTML = dialog.toHtml();
        this.containerElement.appendChild(pendingDialogElement);

        this.applyClickEventListenersOnDismissTargets(dialog, pendingDialogElement);
        this.applyClickEventListenersOnActions(pendingDialogElement);
        this.applyStyles(dialog, pendingDialogElement);
        this.applyClickEventListenersOnKeyboardEvents(dialog, pendingDialogElement);
    }

    public update(dialog: RendarableDialog): void {
        if (!dialog) {
            return;
        }

        const pendingDialogElement: HTMLElement = this.document.createElement('div');
        pendingDialogElement.setAttribute(DialogsDomHandler.DIALOG_ID_ATTRIBUTE, dialog.id);
        pendingDialogElement.classList.add('dialog');
        pendingDialogElement.innerHTML = dialog.toHtml();

        this.applyClickEventListenersOnDismissTargets(dialog, pendingDialogElement);
        this.applyClickEventListenersOnActions(pendingDialogElement);
        this.applyStyles(dialog, pendingDialogElement);
        this.applyClickEventListenersOnKeyboardEvents(dialog, pendingDialogElement);

        const dialogs = this.containerElement.querySelectorAll(`[${DialogsDomHandler.DIALOG_ID_ATTRIBUTE}]`);
        dialogs.forEach((dialogElement: HTMLElement) => {
            if (dialogElement.getAttribute(DialogsDomHandler.DIALOG_ID_ATTRIBUTE) === dialog.id) {
                this.containerElement.replaceChild(pendingDialogElement, dialogElement);
            }
        });
    }

    public clear(): void {
        this.containerElement.innerHTML = '';
    }

    public async removeDialogById(id: string): Promise<void> {
        if (!id) {
            return;
        }
        const dialogs = this.containerElement.querySelectorAll(`[${DialogsDomHandler.DIALOG_ID_ATTRIBUTE}]`);
        dialogs.forEach(dialogElement => {
            if (dialogElement.getAttribute(DialogsDomHandler.DIALOG_ID_ATTRIBUTE) === id) {
                const modalDialogElement = dialogElement.querySelector(DialogsDomHandler.MODAL_DIALOG_SELECTOR)
                if (modalDialogElement) {
                    modalDialogElement.classList.remove('in');
                    setTimeout(() => dialogElement.remove(), 300);
                }

            }
        });
        await this.interactionHandler.handleDismiss(id);
    }

    public setFocusToTopDialog() {
        const dialogs = this.containerElement.querySelectorAll(DialogsDomHandler.MODAL_DIALOG_SELECTOR);
        const topDialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] as HTMLElement : null;
        if (topDialog) {
            topDialog.setAttribute("tabindex", "0");
            topDialog.focus();
        }
    }

    private async build(): Promise<void> {
        this.wrapperElement = this.document.getElementById(DialogsDomHandler.DIALOGS_WRAPPER_ID);
        if (!this.wrapperElement) {
            throw new Error(`Could not get dialog wrapper element with ID ${DialogsDomHandler.DIALOGS_WRAPPER_ID}`);
        }
        this.containerElement = this.document.createElement("div");
        this.containerElement.classList.add('dialogs');
        this.wrapperElement.attachShadow({mode: 'open'});

        this.wrapperElement.shadowRoot.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/libs/@clr/ui/clr-ui.min.css'
        }));

        const dialogsStyleSheetElement = this.wrapperElement.shadowRoot.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/styles/core/dialogs/dialogs.css'
        }));

        await this.styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(dialogsStyleSheetElement, DomInjectionType.BEFORE);

        await this.themingHandlersManager.attach(ThemedComponent.SHELL_DIALOGS, dialogsStyleSheetElement, DomInjectionType.BEFORE);

        this.wrapperElement.shadowRoot.appendChild(this.containerElement);
    }

    private applyClickEventListenersOnDismissTargets(dialog: RendarableDialog, dialogElement: HTMLElement): void {
        if (dialog.closeButtonVisible) {
            this.dismissElement = dialogElement.querySelector(DialogsDomHandler.DISMISS_SELECTOR);
            this.dismissElement.addEventListener('click', this.onDismissClick.bind(this, dialog.id));
        }
        if (dialog.closeOnClickOutside) {
            const modalElement = dialogElement.querySelector(DialogsDomHandler.MODAL_SELECTOR);
            modalElement.addEventListener('click', async (event) => {
                await this.onClickOutsideDialog.call(this, dialog.id, event);
            });
        }
    }

    private applyStyles(dialog: RendarableDialog, dialogElement: HTMLElement): void {
        const modalBackdropElement = dialogElement.querySelector(DialogsDomHandler.MODAL_SELECTOR) as HTMLElement;
        const modalDialogElement = dialogElement.querySelector(DialogsDomHandler.MODAL_DIALOG_SELECTOR) as HTMLElement;
        if (modalDialogElement) {
            modalDialogElement.classList.add('in', `${ClarityDialogSize[dialog.size]}`);
            modalDialogElement.focus();
        }
        if (dialog.showBackdrop) {
            modalBackdropElement.classList.add('in');
        }
    }

    private applyClickEventListenersOnActions(activeDialogElement: Element): void {
        const actionElements = activeDialogElement.querySelectorAll(DialogsDomHandler.ACTION_SELECTOR);
        actionElements.forEach(actionElement => {
            actionElement.addEventListener('click', this.onActionClick.bind(this));
        });
    }

    private applyClickEventListenersOnKeyboardEvents(dialog: RendarableDialog, dialogElement: HTMLElement): void {
        const modalDialogElement = dialogElement.querySelector(DialogsDomHandler.MODAL_DIALOG_SELECTOR) as HTMLElement;
        if (modalDialogElement) {
            modalDialogElement.addEventListener('keydown', async (event: KeyboardEvent) => {
                await this.onKeyboardNavigation(event, dialog, dialogElement);
            }, true);
        }
    }

    private async onActionClick(event: Event): Promise<void> {
        const actionElement = event.target as Element;
        if (!actionElement) {
            return;
        }
        const dialogId = actionElement.closest('[dialog-id]').getAttribute('dialog-id');
        const actionCode = actionElement.getAttribute('action-code');
        if (!dialogId) {
            return;
        }
        await this.interactionHandler.handleAction(dialogId, actionCode);
    }

    private async onDismissClick(id: string): Promise<void> {
        await this.removeDialogById(id);
    }

    private async onClickOutsideDialog(id: string, e: Event): Promise<void> {
        const target = e.target as HTMLElement
        if (target.classList.contains('modal')) {
            await this.removeDialogById(id);
            await this.interactionHandler.handleDismiss(id);
        }
    }

    private focusFirstAction(dialogElement: HTMLElement) {
        const newFocusElement = dialogElement.querySelector("[action-code]") as HTMLElement;
        newFocusElement.focus();
    }

    private focusLastAction(dialogElement: HTMLElement) {
        const allButtons = dialogElement.querySelectorAll("button") as NodeList;
        const newFocusElement = allButtons[allButtons.length - 1] as HTMLElement;
        newFocusElement.focus();


    }

    private focusNextAction(activeElement: HTMLElement, dialogElement: HTMLElement, dialog: RendarableDialog): void {
        const activeItemIndex = dialog.actions.findIndex((action: RendarableDialogAction) => action.code === activeElement.getAttribute('action-code'));
        if (activeItemIndex < dialog.actions.length - 1) {
            const activeItem = dialog.actions[activeItemIndex + 1];
            activeElement = dialogElement.querySelector(`[action-code='${activeItem.code}']`) as HTMLElement;
            activeElement.focus();
        } else {
            this.focusFirstAction(dialogElement);
        }
    }

    private focusPreviousAction(activeElement: HTMLElement, dialogElement: HTMLElement, dialog: RendarableDialog): void {
        const activeItemIndex = dialog.actions.findIndex((action: RendarableDialogAction) => action.code === activeElement.getAttribute('action-code'));
        if (activeItemIndex > 0) {
            const activeItem = dialog.actions[activeItemIndex - 1];
            activeElement = dialogElement.querySelector(`[action-code='${activeItem.code}']`) as HTMLElement;
            activeElement.focus();
        } else {
            this.focusLastAction(dialogElement);
        }
    }

    private async onKeyboardNavigation(event: KeyboardEvent, dialog: RendarableDialog, dialogElement: HTMLElement): Promise<void> {
        const activeElement = dialogElement.querySelector(":focus") as HTMLElement;
        const index = dialog.actions.findIndex((action) => action.code === activeElement.getAttribute('action-code'));
        switch (event.key) {
            case "Escape":
                if (dialog.closeOnClickOutside) {
                    await this.removeDialogById(dialog.id);
                    await this.interactionHandler.handleDismiss(dialog.id);
                }
                break;
            case "Tab":
                if (activeElement.hasAttribute('action-code')) {
                    if (index === dialog.actions.length - 1) {
                        this.focusFirstAction(dialogElement);
                        event.preventDefault();
                        event.stopPropagation();
                    }
                }
                if (activeElement.className === "close dismiss-button" && event.shiftKey) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                if (!dialog.closeButtonVisible && event.shiftKey) {
                    this.focusFirstAction(dialogElement);
                    event.preventDefault();
                    event.stopPropagation();
                }
                break;
            case "ArrowLeft":
                if (activeElement.hasAttribute('action-code')) {
                    if (index === 0) {
                        this.focusLastAction(dialogElement);
                    } else {
                        this.focusPreviousAction(activeElement, dialogElement, dialog)
                    }
                }
                break;
            case "ArrowRight":
                if (activeElement.hasAttribute('action-code')) {
                    if (index === dialog.actions.length - 1) {
                        this.focusFirstAction(dialogElement);
                    } else {
                        this.focusNextAction(activeElement, dialogElement, dialog);
                    }
                }
                break;
        }
    }


}
