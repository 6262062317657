/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {v4 as uuidv4} from 'uuid';
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {TypedInstanceFactory} from "sirius-platform-support-library/dependency-injection/typed/typed-instance-factory";
import {InstanceFactory} from "sirius-platform-support-library/dependency-injection/generic/instance-factory";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {DependencyMetadata} from "./dependency-metadata";
import {DependencyRecordType} from "./dependency-record-type.enum";

export class InternalDependencyRegistry implements ITypedServiceCollection, IServiceCollection {
    private readonly instancesRegistry: Record<string, any>;
    private readonly factoriesRegistry: Record<string, TypedInstanceFactory<any>>;
    private readonly typesRegistry: Record<string, DependencyMetadata[]>;

    constructor() {
        this.instancesRegistry = {};
        this.factoriesRegistry = {};
        this.typesRegistry = {};
    }

    public isRegistered(serviceType: string): boolean {
        return this.getRegisteredServices().some(serviceName => serviceName === serviceType);
    }

    public getRegisteredServices(): string[];
    public getRegisteredServices(dependencyType: DependencyType): string[];
    public getRegisteredServices<T>(...args: any[]): string[] {
        const dependencyType = (args[0] || DependencyType.PRIVATE) as DependencyType;
        return Object.keys(this.typesRegistry)
            .flatMap(key => this.typesRegistry[key])
            .filter(m => m.dependencyType === dependencyType)
            .map(m => m.uniqueName);
    }

    public resolve<T>(serviceType: string): T;
    public resolve<T>(serviceType: string, dependencyType: DependencyType): T;
    public resolve<T>(...args: any[]): T {
        const serviceType = args[0] as string;
        const dependencyType = (args[1] || DependencyType.ALL) as DependencyType;
        const instances = this.getSuitableInstances(serviceType, dependencyType);
        if (instances == undefined || instances.length === 0) {
            return undefined;
        }
        const instance = instances[instances.length - 1];
        return this.resolveSpecificInstance(instance.id) as T;
    }

    public resolveAll<T>(serviceType: string): T[];
    public resolveAll<T>(serviceType: string, dependencyType: DependencyType): T[];
    public resolveAll<T>(...args: any[]): T[] {
        const serviceType = args[0] as string;
        const dependencyType = (args[1] || DependencyType.ALL) as DependencyType;
        const instances = this.getSuitableInstances(serviceType, dependencyType);
        if (instances == undefined || instances.length === 0) {
            return [];
        }
        return instances.map(p => {
            return this.resolveSpecificInstance(p.id);
        }).filter(instance => instance) as T[];
    }

    public registerInstance(serviceType: string, implementationType: string, instance: any, dependencyType: DependencyType) {
        if (!instance) {
            throw new Error(`Please provide an instance value for the given ${serviceType}`);
        }
        const implementationTypeMetadata = this.getServiceMetadata(implementationType);
        if (implementationTypeMetadata) {
            throw new Error('Concrete class has already been registered to the dependency container.');
        }
        const instanceId = uuidv4();
        this.instancesRegistry[instanceId] = instance;
        this.storeMetadata(instanceId, implementationType, serviceType, dependencyType);
    }

    public registerFactory(serviceType: string, implementationType: string, factory: TypedInstanceFactory<any>, dependencyType: DependencyType) {
        if (!factory) {
            throw new Error(`Please provide a factory for the given ${serviceType}`);
        }
        const implementationTypeMetadata = this.getServiceMetadata(implementationType);
        if (implementationTypeMetadata) {
            throw new Error('Concrete class has already been registered to the dependency container.');
        }
        const instanceId = uuidv4();
        this.factoriesRegistry[instanceId] = factory;
        this.storeMetadata(instanceId, implementationType, serviceType, dependencyType);
    }

    public registerInterfacesOnImplementationType(implementationType: string, ...serviceTypes: string[]): void {
        const implementationTypeMetadata = this.getServiceMetadata(implementationType);
        if (!implementationTypeMetadata) {
            throw new Error(`Could not find any concrete type registered for '${implementationType}'`);
        }
        if (implementationTypeMetadata.recordType !== DependencyRecordType.CONCRETE) {
            throw new Error(`The given type '${implementationType}' is not a concrete class`);
        }
        for (let index = 0; index < serviceTypes.length; index++) {
            const serviceType = serviceTypes[index];
            this.storeMetadata(implementationTypeMetadata.id, implementationTypeMetadata.concreteType, serviceType, implementationTypeMetadata.dependencyType);
        }
    }

    private storeMetadata(recordId: string, implementationType: string, serviceType: string, dependencyType: DependencyType) {
        let implementationTypeMetadata = this.getServiceMetadata(implementationType);
        if (!implementationTypeMetadata) {
            implementationTypeMetadata = {
                id: recordId,
                uniqueName: implementationType,
                dependencyType: dependencyType,
                concreteType: implementationType,
                recordType: DependencyRecordType.CONCRETE,
                interfaces: []
            }
            this.typesRegistry[implementationType] = [implementationTypeMetadata];
        }
        if (serviceType) {
            if (implementationTypeMetadata.interfaces.includes(serviceType, 0)) {
                return;
            }
            implementationTypeMetadata.interfaces.push(serviceType);
            if (!this.typesRegistry[serviceType]) {
                this.typesRegistry[serviceType] = [];
            }
            this.typesRegistry[serviceType].push({
                id: recordId,
                uniqueName: serviceType,
                dependencyType: dependencyType,
                concreteType: implementationType,
                recordType: DependencyRecordType.INTERFACE,
                interfaces: []
            });
        }
    }

    private getServiceMetadata(serviceType): DependencyMetadata | undefined {
        return this.typesRegistry[serviceType]?.slice(-1).pop();
    }

    private resolveSpecificInstance(instanceId: string): any {
        let instance = this.instancesRegistry[instanceId];
        if (instance == undefined) {
            const factory = this.factoriesRegistry[instanceId];
            if (factory) {
                instance = this.buildTypeWithFactory(factory);
                if (instance) {
                    this.instancesRegistry[instanceId] = instance;
                }
            }
        }
        return instance;
    }

    private getSuitableInstances(serviceType: string, dependencyType: DependencyType): any[] {
        const instances = this.typesRegistry[serviceType] || [];
        return instances.filter(p => {
            return p.dependencyType > DependencyType.NONE && p.dependencyType <= dependencyType;
        });
    }

    private buildTypeWithFactory(factory: TypedInstanceFactory<any> | InstanceFactory<any>): any {
        return factory(this);
    }
}
