/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IAnalyticsEngine} from "sirius-platform-support-library/shared/analytics/analytics-engine.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {AnalyticsEngineConfig, GlassboxOptions} from "sirius-platform-support-library/tenants/tenant-context";
import {DynamicImportUtility} from "sirius-platform-support-library/utilities/dynamic-import.utility";
import {EngineUtilities} from "./engine-utilities";

export const GlassboxEngineTypeName = 'GlassboxEngine';

export class GlassboxEngine implements IAnalyticsEngine {

    private static readonly CODE = 'GLASSBOX';

    private readonly window: Window;
    private readonly document: Document;
    private readonly tenant: ITenant;
    private readonly glassboxEngineConfig: AnalyticsEngineConfig;

    private glassboxEngineOptions: GlassboxOptions;
    private initialized: boolean = false;

    constructor(
        window: Window,
        document: Document,
        tenant: ITenant
    ) {
        this.window = window;
        this.document = document;
        this.tenant = tenant;
        this.glassboxEngineConfig = (tenant.getContext()?.analytics || []).find((engines) => engines.analyticsEngineName === GlassboxEngine.CODE) ?? {
            enabled: false,
            analyticsEngineName: GlassboxEngine.CODE,
            userConfigurable: false
        };
        this.glassboxEngineOptions = this.glassboxEngineConfig?.options;
    }

    public getUniqueCode(): string {
        return GlassboxEngine.CODE;
    }

    public getUserPreferenceKey(): string | undefined {
        return this.glassboxEngineConfig?.userPreferenceKey;
    }

    public async init(options?: GlassboxOptions | any, override?: boolean): Promise<void> {
        this.glassboxEngineOptions = options;
        if (override) {
            this.glassboxEngineConfig.enabled = true;
        }
        if (!this.glassboxEngineConfig.enabled) {
            return;
        }
        const existingScript = this.document.querySelector(`script[code='${this.getUniqueCode()}']`);
        if (existingScript) {
            this.initialized = true;
            return;
        }
        if (!options.scriptUrl) {
            return;
        }
        const scriptUrl = options.scriptUrl;
        await this.setOptions(options.metrics);
        await DynamicImportUtility.import(scriptUrl, 'script', this.document.head, {code: this.getUniqueCode()});
        const internalEngine = await EngineUtilities.waitUntilResultDefined(this.getInternalEngine.bind(this));
        if (internalEngine) {
            internalEngine.stopRecording();
        }
        this.initialized = true;
    }

    public async setEnabled(enabled: boolean): Promise<void> {
        if (!this.initialized) {
            await this.init(this.glassboxEngineOptions);
        }
        const internalEngine = await EngineUtilities.waitUntilResultDefined(this.getInternalEngine.bind(this));
        if (!enabled) {
            internalEngine?.stopRecording();
        } else {
            internalEngine?.startRecording();
        }
    }

    public getOptions(): GlassboxOptions | any | undefined {
        return this.getGlassboxMetrics();
    }

    public async setOptions(options?: GlassboxOptions | any): Promise<void> {
        const glassboxMetricsObj = this.getGlassboxMetrics();
        Object.assign(glassboxMetricsObj, options ?? {});
    }

    private getGlassboxMetrics(): any {
        this.window['Dell'] = this.window['Dell'] ?? {};
        this.window['Dell'].Metrics = this.window['Dell'].Metrics ?? {};
        this.window['Dell'].Metrics.sc = this.window['Dell'].Metrics.sc ?? {};
        return this.window['Dell'].Metrics.sc;
    }

    private getInternalEngine(): any | undefined {
        return (this.window as { [key: string]: any })['_detector'] as any;
    }
}
