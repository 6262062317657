/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import singleSpaHtml from 'single-spa-html';
import {DefaultPagesOptionsStore} from "../options/options-store/default-pages-options.store";
import {DefaultPageWebComponent} from "./default-page.web-component";
import {DefaultPageHelper} from "../default-page.helper";

const htmlLifecycles = singleSpaHtml({
    template: (props) => {
        let optionsJson = '{}';
        try {
            optionsJson = JSON.stringify(props?.options || {});
        } catch (e) {
            optionsJson = '{}';
        }
        return `<${DefaultPageWebComponent.CUSTOM_ELEMENT_NAME} options="${encodeURIComponent(optionsJson)}"></${DefaultPageWebComponent.CUSTOM_ELEMENT_NAME}>`;
    }
});

export const bootstrap = (props) => {
    return htmlLifecycles.bootstrap(props);
}

export let previousOptionsCode: string = undefined;
export const mount = async (props) => {
    const optionsCode = DefaultPageHelper.getOptionsCode(window.location.search);
    const options = await DefaultPagesOptionsStore.getInstance().get(optionsCode);
    props.options = options || {};
    previousOptionsCode = optionsCode;
    return htmlLifecycles.mount(props);
};
export const unmount = async (props) => {
    const optionsCode = previousOptionsCode || DefaultPageHelper.getOptionsCode(window.location.search);
    if (optionsCode) {
        await DefaultPagesOptionsStore.getInstance().remove(optionsCode);
    }
    previousOptionsCode = undefined;
    return htmlLifecycles.unmount(props);
};
