/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ButtonStyle, ButtonType, CustomizableButton} from "sirius-platform-support-library/models/common";

export const IButtonStyleProcessorTypeName = 'IButtonStyleProcessor';

export interface IButtonStyleProcessor {
    getClassesByProperties(buttonType?: ButtonType, buttonStyle?: ButtonStyle): string[];

    getClasses(customizableButton: CustomizableButton): string[];
}
