/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {SectionMarkerCodes} from "sirius-platform-support-library/shared/page-customizations/section-marker-codes";
import {PageCustomization} from "sirius-platform-support-library/models/page-customizations/page-customization.models";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    IPageCustomizationsHandler
} from "sirius-platform-support-library/shared/page-customizations/page-customizations-handler.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export const BannerAlertsSectionPageCustomizationHandlerTypeName = 'BannerAlertsSectionPageCustomizationHandler';

export class BannerAlertsSectionPageCustomizationHandler implements IPageCustomizationsHandler {
    private readonly window: Window;
    private readonly document: Document;
    private readonly tenant: ITenant;

    public constructor(
        window: Window,
        document: Document,
        tenant: ITenant
    ) {
        this.window = window;
        this.document = document;
        this.tenant = tenant;
    }

    public getSectionMarkerCode(): string {
        return SectionMarkerCodes.BANNER_ALERTS_MARKER;
    }

    public async apply(sectionCode: string, customizations: PageCustomization): Promise<void> {
        if (sectionCode !== this.getSectionMarkerCode()) {
            return;
        }
        const element = this.window.document.querySelector(`.${this.getSectionMarkerCode()}`) as any;
        if (!element) {
            return;
        }
        const visible = ObjectUtility.isDefined(customizations?.visible) ? customizations.visible : true;
        if (visible) {
            element.classList.remove('hidden');
        } else {
            element.classList.add('hidden');
        }
    }
}
