/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {Dialog} from "sirius-platform-support-library/shared/dialogs/dialog";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {ValidationError} from "sirius-platform-support-library/validators/validation-error";
import {DialogActionValidator} from "./dialog-action.validator";

export const DialogValidatorTypeName = 'DialogValidator';

export class DialogValidator {
    private readonly actionValidator: DialogActionValidator;

    public constructor() {
        this.actionValidator = new DialogActionValidator();
    }

    public validate(dialog: Dialog): void {
        const errors: Error[] = [];
        if (!dialog.bodyHtml?.localizationCode && !dialog.bodyControl?.name) {
            errors.push(new Error('Please provide a valid bodyHtml or bodyControl'));
        }

        if (dialog.actions) {
            dialog.actions.forEach(action => {
                try {
                    if (action.closeAfterAction && !ObjectUtility.isDefined(action.action)) {
                        return;
                    }
                    this.actionValidator.validate(action);
                } catch (e) {
                    errors.push(...e.errors);
                }
            });
        }
        if (errors.length > 0) {
            throw new ValidationError('Validation errors detected for the given dialog', errors);
        }
    }

    public validateMany(dialogs: Dialog[]): void {
        if (!dialogs) {
            return;
        }
        const errors: Error[] = [];
        dialogs.forEach(dialog => {
            try {
                this.validate(dialog);
            } catch (e) {
                errors.push(...e.errors);
            }
        })
        if (errors.length > 0) {
            throw new ValidationError('Please provide a valid dialog', errors);
        }
    }
}
