/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {css, html, LitElement} from "lit";
import {property, query, state} from "lit/decorators.js";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {TranslationService} from 'sirius-platform-support-library/shared/localization/translations/translation.service';
import {
    SupportedTranslationsLoaders
} from "sirius-platform-support-library/shared/localization/translations/loaders/translations-loaders.constants";
import {createNodeElement} from "sirius-platform-support-library/utilities/dom-helper";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";

export class ProgressIndicatorBlockingComponent extends LitElement {
    public static readonly CUSTOM_ELEMENT_NAME = 'sirius-progress-indicator-blocking';

    public static styles = css`
      :host {
        display: flex;
      }

      .progress-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        align-self: stretch;
        background: rgba(14, 14, 14, 0.45);
        display: flex;
        flex: 1;
        backdrop-filter: blur(15px);
      }

      .progress-container {
        display: flex;
        flex: 1;
        max-height: calc(100vh);
        align-items: center;
        justify-items: center;
        justify-content: center;
        text-align: center;
      }

      .si-progress {
        display: flex;
        flex-direction: column;
        color: var(--uxd-neutral-white, #FFFFFF);
        justify-items: center;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex: 1;
      }

      h4 {
        color: var(--uxd-neutral-white, #FFFFFF) !important;
        margin-top: 24px !important;
        margin-left: 24px !important;
        margin-right: 24px !important;
        max-width: 900px;
      }

      .time {
        color: var(--uxd-neutral-white, #FFFFFF);
        align-self: center;
      }

      canvas {
        padding: 0;
        margin: 0;
        display: block;
      }

      *:focus-visible {
        outline: none !important;
        box-shadow: 0 0 0 2px var(--uxd-neutral-white, #FFFFFF), 0 0 0 4px var(--uxd-primary-midnight, #00468b) !important;
        border-radius: 2px;
        text-decoration: underline;
        box-sizing: border-box;
      }`;

    @property({type: Number})
    progress = 0;
    @property({type: String, attribute: 'title-text'})
    title = "";
    @property({type: Boolean})
    deterministic = false;
    @property({type: Boolean, attribute: 'show-elapsed-time'})
    showElapsedTime = false;
    @property({type: Boolean, attribute: 'show-progress'})
    showProgress = false;
    @property({type: String})
    txtcolor = "var(--uxd-neutral-carbon, #444444)";
    @property({type: String})
    bgcolor = "var(--uxd-neutral-white,#FFFFFF)"

    @property({type: String})
    canvasColor = '#FFFFFF';
    // @ts-ignore
    @query('#spinner-canvas')
    canvasElement: HTMLCanvasElement | undefined;
    @state()
    private titleText = "";
    @state()
    private elapsedTimeToken = "ELAPSED_TIME";
    private translationService?: TranslationService;
    private startTime: number;
    private elapsedTimeText = "";
    private timerId: any | undefined
    private elapsedTime = 0;
    private spinnerTimer = 0;

    private referenceStyleSheet?: Element | ParentNode;

    public constructor() {
        super();
        this.startTime = Date.now();
    }

    public async connectedCallback() {
        super.connectedCallback();

        if (this.showElapsedTime) {
            this.startTimer();
        }

        const localizedResources = this.getLocalizedResources();

        this.translationService = new TranslationService({
            publicPath: {
                provide: () => {
                    // @ts-ignore
                    return __webpack_public_path__;
                }
            },
            type: SupportedTranslationsLoaders.REMOTE_ASSETS,
            options: {
                prependPublicPath: false,
                baseUrl: '/assets/i18n/',
                extension: '.json'
            },
            localizedResources: localizedResources
        });

        this.translationService.onLanguageChanged(this, this.translateProperties);

        window.requestAnimationFrame(() => this.updateSpinner());

        await this.translateProperties();

        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'progress-indicators',
                suffix: 'blocking'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-dark-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        if (this.referenceStyleSheet) {
            await styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(this.referenceStyleSheet, DomInjectionType.AFTER);

            const themingHandlersManager = SharedDependencyContainer.getInstance().getServiceCollection().resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
            if (themingHandlersManager) {
                themingHandlersManager.registerThemeHandler(styleSheetsBasedThemeHandler);
                await themingHandlersManager.attach(ThemedComponent.SHELL_PROGRESS_INDICATORS_BLOCKING, this.referenceStyleSheet, DomInjectionType.AFTER);
            }
        }

        const hostStyles = getComputedStyle(this.shadowRoot.host || this);
        const variableValue = hostStyles.getPropertyValue('--spinner-color');

        this.canvasColor = variableValue || '#FFFFFF';
    }

    public async disconnectedCallback() {
        super.disconnectedCallback();

        if (this.timerId) {
            this.clearTimer();
        }

        this.clearTimer()
    }

    public render() {
        return html`
            <div class="progress-wrapper">
                <div class="progress-container">
                    <div class="${this.deterministic ? 'deterministic' : 'non-deterministic'} si-progress">
                        <canvas id="spinner-canvas" width="72" height="72" role="heading"
                                tabindex="${this.showProgress ? '0' : ''}"
                                aria-label="${!!this.showProgress ? `${this.progress}%` : 'Canvas'}"></canvas>
                        ${!this.titleText ?
                                html`` :
                                html`<h4 role="heading" tabindex="0" aria-label="${this.titleText}">
                                    ${this.titleText}</h4>`
                        }
                        <span class="${this.showElapsedTime ? 'time' : 'hidden'}" role="heading" tabindex="0"
                              aria-label="${this.timeTemplate()}">${unsafeHTML(this.timeTemplate())}</span>
                    </div>
                </div>
            </div>`
    }

    public startTimer() {
        this.timerId = setInterval(() => {
            const now = Date.now();
            this.elapsedTime = (now - this.startTime);
            this.requestUpdate();
        }, 100);
    }

    protected createRenderRoot() {
        const root = super.createRenderRoot();

        this.referenceStyleSheet = root.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/libs/@clr/ui/clr-ui.min.css'
        }));

        return root;
    }

    private async translateProperties(): Promise<void> {
        this.titleText = await this.translationService.translate(this.title || '');
        this.elapsedTimeText = await this.translationService.translate(this.elapsedTimeToken || '');
    }

    private timeTemplate() {
        if (this.showElapsedTime) {
            const elapsed = new Date(this.elapsedTime);
            const hh = elapsed.getUTCHours() < 10 ? `0${elapsed.getUTCHours()}` : elapsed.getUTCHours();
            const mm = elapsed.getMinutes() < 10 ? `0${elapsed.getMinutes()}` : elapsed.getMinutes();
            const ss = elapsed.getSeconds() < 10 ? `0${elapsed.getSeconds()}` : elapsed.getSeconds();
            return `${this.elapsedTimeText} ${hh}:${mm}:${ss}`
        }
        return "";
    }

    private clearTimer() {
        clearInterval(this.timerId);
    }

    private updateSpinner() {
        const draw = (ctx, startPoint, endPoint, strokeStyle, lineWidth) => {
            ctx.strokeStyle = strokeStyle;
            ctx.lineWidth = lineWidth;
            ctx.beginPath();
            ctx.arc(36, 36, 32, startPoint, endPoint);
            ctx.stroke();
        }

        if (this.canvasElement) {
            this.spinnerTimer += 0.015;
            if (this.spinnerTimer > 1) {
                this.spinnerTimer = 0;
            }
            const ctx = this.canvasElement.getContext("2d");
            ctx.globalAlpha = 0.5;
            ctx.clearRect(0, 0, 72, 72);
            let startPoint = 0;
            let endPoint = 2 * Math.PI;
            draw(ctx, startPoint, endPoint, this.canvasColor, 4);
            ctx.globalAlpha = 1;
            if (this.deterministic) {
                startPoint = -0.5 * Math.PI;
                endPoint = ((this.progress / 100) * 2 * Math.PI) - 0.5 * Math.PI;

                if (this.showProgress) {
                    ctx.font = "16px Roboto Light";
                    ctx.fillStyle = this.canvasColor;
                    ctx.textAlign = "center";
                    ctx.fillText(`${this.progress}%`, 36, 42);
                }
            } else {
                startPoint = (this.spinnerTimer - 0.5) * 2 * Math.PI;
                endPoint = startPoint + (0.66 * Math.PI);
            }

            draw(ctx, startPoint, endPoint, this.canvasColor, 4);

            window.requestAnimationFrame(() => this.updateSpinner());
        }
    }

    private getLocalizedResources(): any | undefined {
        try {
            const key = 'sirius.standalone-progress-indicators.localized-resources';
            const json = window.sessionStorage.getItem(key);
            if (json) {
                window.sessionStorage.removeItem(key);
                return JSON.parse(json);
            }
            return undefined;
        } catch (e) {
            return undefined;
        }
    }
}

if (!customElements.get(ProgressIndicatorBlockingComponent.CUSTOM_ELEMENT_NAME)) {
    customElements.define(ProgressIndicatorBlockingComponent.CUSTOM_ELEMENT_NAME, ProgressIndicatorBlockingComponent);
}
