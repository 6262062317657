/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {EnvironmentDefinition, EnvironmentType} from "sirius-platform-support-library/tenants/tenant-context";

export const EnvironmentsDefinitionsCodeProcessorTypeName = 'EnvironmentsDefinitionsCodeProcessor';

export class EnvironmentsDefinitionsCodeProcessor {
    private readonly window: Window;

    public constructor(window: Window) {
        this.window = window;
    }

    public getCodeWithGivenEnvironments(environments: Record<string, EnvironmentDefinition>, environmentType?: EnvironmentType | string): string | undefined {
        if (!environments) {
            return undefined;
        }
        const environmentTypeValue = !environmentType ? EnvironmentType.TENANT : environmentType.toString();
        const origin = this.window.location.host.toLowerCase();
        const matches = Object.getOwnPropertyNames(environments).filter(envCode => {
            return (environments[envCode]?.origins || []).some(o => o.toLowerCase() === origin);
        }).map(envKey => {
            return {
                code: envKey,
                ...environments[envKey]
            };
        });
        const firstMatch = matches.find((item, index) => index === 0);
        return environmentTypeValue === EnvironmentType.SIRIUS.toString() ? firstMatch?.siriusEnvironment?.toString() : firstMatch?.code;
    }
}
