/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {TypedDependencyModule} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {ITypedServiceRegistry} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    EnvironmentsDefinitionsCodeProcessor,
    EnvironmentsDefinitionsCodeProcessorTypeName
} from "./environments-definitions-code-processor";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TenantContextEnvironmentCodeProvider,
    TenantContextEnvironmentCodeProviderTypeName
} from "./tenant-context-environment-code-provider";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    ServerTimingsHeaderEnvironmentCodeProvider,
    ServerTimingsHeaderEnvironmentCodeProviderTypeName
} from "./server-timings-header-environment-code-provider";
import {EnvironmentContext, EnvironmentContextTypeName} from "./environment-context";
import {
    IEnvironmentCodeProvider,
    IEnvironmentCodeProviderTypeName
} from "sirius-platform-support-library/shared/environment/environment-code-provider.interface";
import {EnvironmentCodeProvider, EnvironmentCodeProviderTypeName} from "./environment-code-provider";
import {
    IEnvironmentSpecificConfigurator,
    IEnvironmentSpecificConfiguratorTypeName
} from "sirius-platform-support-library/shared/environment/environment-specific-configurator.interface";
import {
    EnvironmentSpecificConfigurator,
    EnvironmentSpecificConfiguratorTypeName
} from "./environment-specific-configurator";

export class EnvironmentDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer.addUsingInstanceAsSingleton<EnvironmentsDefinitionsCodeProcessor>(DependencyType.PRIVATE, EnvironmentsDefinitionsCodeProcessorTypeName, new EnvironmentsDefinitionsCodeProcessor(window))
            .addUsingFactoryAsSingleton<TenantContextEnvironmentCodeProvider>(DependencyType.PRIVATE, TenantContextEnvironmentCodeProviderTypeName, (di) => {
                const environmentsDefinitionsCodeProcessor = di.resolve<EnvironmentsDefinitionsCodeProcessor>(EnvironmentsDefinitionsCodeProcessorTypeName);
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new TenantContextEnvironmentCodeProvider(tenant, environmentsDefinitionsCodeProcessor);
            })
            .addUsingInstanceAsSingleton<ServerTimingsHeaderEnvironmentCodeProvider>(DependencyType.PRIVATE, ServerTimingsHeaderEnvironmentCodeProviderTypeName, new ServerTimingsHeaderEnvironmentCodeProvider(window))
            .addUsingFactoryAsSingleton<EnvironmentContext>(DependencyType.PRIVATE, EnvironmentContextTypeName, (di) => {
                const environmentsDefinitionsCodeProcessor = di.resolve<EnvironmentsDefinitionsCodeProcessor>(EnvironmentsDefinitionsCodeProcessorTypeName);
                const tenantContextEnvironmentCodeProvider = di.resolve<TenantContextEnvironmentCodeProvider>(TenantContextEnvironmentCodeProviderTypeName);
                const serverTimingsHeaderEnvironmentCodeProvider = di.resolve<ServerTimingsHeaderEnvironmentCodeProvider>(ServerTimingsHeaderEnvironmentCodeProviderTypeName);
                return EnvironmentContext.build(environmentsDefinitionsCodeProcessor, tenantContextEnvironmentCodeProvider, serverTimingsHeaderEnvironmentCodeProvider);
            })
            .addUsingFactoryAsSingleton<IEnvironmentCodeProvider, EnvironmentCodeProvider>(DependencyType.SHARED, IEnvironmentCodeProviderTypeName, EnvironmentCodeProviderTypeName, (di) => {
                const environmentContext = di.resolve<EnvironmentContext>(EnvironmentContextTypeName);
                return new EnvironmentCodeProvider(environmentContext);
            })
            .addUsingFactoryAsSingleton<IEnvironmentSpecificConfigurator, EnvironmentSpecificConfigurator>(DependencyType.SHARED, IEnvironmentSpecificConfiguratorTypeName, EnvironmentSpecificConfiguratorTypeName, (di) => {
                const environmentCodeProvider = di.resolve<IEnvironmentCodeProvider>(IEnvironmentCodeProviderTypeName);
                return new EnvironmentSpecificConfigurator(environmentCodeProvider);
            });
    }
}

export const environmentDependencyModule = new EnvironmentDependencyModule();
