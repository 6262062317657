/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ActionTargetEnum} from "sirius-platform-support-library/models/common";
import {DefaultPagesEnum} from "sirius-platform-support-library/shared/site/default-pages/default-pages.enum";
import {InternalNavigateToOptions} from "../internal-navigate-to.options";

export const NavigateToOptionsFactoryTypeName = 'NavigateToOptionsFactory';

export class NavigateToOptionsFactory {
    private static readonly DEFAULTS: Record<string, InternalNavigateToOptions> = {
        [DefaultPagesEnum.DEFAULT_PAGE_400.valueOf()]: {
            pageCode: DefaultPagesEnum.DEFAULT_PAGE_400.valueOf(),
            title: 'DEFAULT_PAGES.PAGES.400.TITLE',
            description: 'DEFAULT_PAGES.PAGES.400.DESCRIPTION',
            homeButtonVisible: false,
            homeButtonText: 'DEFAULT_PAGES.BUTTONS.HOME',
            backButtonVisible: true,
            backButtonText: 'DEFAULT_PAGES.BUTTONS.BACK',
            localizedResources: {}
        } as InternalNavigateToOptions,
        [DefaultPagesEnum.DEFAULT_PAGE_401.valueOf()]: {
            pageCode: DefaultPagesEnum.DEFAULT_PAGE_401.valueOf(),
            title: 'DEFAULT_PAGES.PAGES.401.TITLE',
            description: 'DEFAULT_PAGES.PAGES.401.DESCRIPTION',
            homeButtonVisible: false,
            homeButtonText: 'DEFAULT_PAGES.BUTTONS.HOME',
            backButtonVisible: true,
            backButtonText: 'DEFAULT_PAGES.BUTTONS.BACK',
            localizedResources: {}
        } as InternalNavigateToOptions,
        [DefaultPagesEnum.DEFAULT_PAGE_403.valueOf()]: {
            pageCode: DefaultPagesEnum.DEFAULT_PAGE_403.valueOf(),
            title: 'DEFAULT_PAGES.PAGES.403.TITLE',
            description: 'DEFAULT_PAGES.PAGES.403.DESCRIPTION',
            homeButtonVisible: false,
            homeButtonText: 'DEFAULT_PAGES.BUTTONS.HOME',
            backButtonVisible: true,
            backButtonText: 'DEFAULT_PAGES.BUTTONS.BACK',
            localizedResources: {}
        } as InternalNavigateToOptions,
        [DefaultPagesEnum.DEFAULT_PAGE_404.valueOf()]: {
            pageCode: DefaultPagesEnum.DEFAULT_PAGE_404.valueOf(),
            title: 'DEFAULT_PAGES.PAGES.404.TITLE',
            description: 'DEFAULT_PAGES.PAGES.404.DESCRIPTION',
            homeButtonVisible: true,
            homeButtonText: 'DEFAULT_PAGES.BUTTONS.HOME',
            backButtonVisible: true,
            backButtonText: 'DEFAULT_PAGES.BUTTONS.BACK',
            localizedResources: {}
        } as InternalNavigateToOptions,
        [DefaultPagesEnum.DEFAULT_PAGE_ERROR.valueOf()]: {
            pageCode: DefaultPagesEnum.DEFAULT_PAGE_ERROR.valueOf(),
            pageTitle: '',
            title: 'DEFAULT_PAGES.PAGES.ERROR.TITLE',
            description: 'DEFAULT_PAGES.PAGES.ERROR.DESCRIPTION',
            homeButtonVisible: false,
            homeButtonText: 'DEFAULT_PAGES.BUTTONS.HOME',
            backButtonVisible: true,
            backButtonText: 'DEFAULT_PAGES.BUTTONS.BACK',
            headerVisible: true,
            headerLogoSrc: '',
            headerTitle: '',
            footerVisible: true,
            footerTitleVisible: true,
            footerTitle: '',
            footerDescriptionVisible: true,
            footerDescription: '',
            footerCopyrightVisible: true,
            footerCopyrightLinkText: 'DEFAULT_PAGES.FOOTER.COPYRIGHT',
            footerCopyrightLinkUrl: 'https://www.dell.com/learn/{{countryCode}}/{{languageCode}}/uscorp1/site-terms-of-use-copyright',
            footerCopyrightLinkTarget: ActionTargetEnum.SAME_TAB,
            footerPrivacyLinkVisible: false,
            footerPrivacyLinkText: 'DEFAULT_PAGES.FOOTER.PRIVACY',
            footerPrivacyLinkUrl: 'https://www.dell.com/{{localeCode}}/lp/privacy-center',
            footerPrivacyLinkTarget: ActionTargetEnum.SAME_TAB,
            footerContactLinkVisible: false,
            footerContactLinkText: 'DEFAULT_PAGES.FOOTER.CONTACT_US',
            footerContactLinkUrl: 'https://www.dell.com/{{localeCode}}/lp/contact-us',
            footerContactLinkTarget: ActionTargetEnum.SAME_TAB,
            footerAboutLinkVisible: false,
            footerAboutLinkText: 'DEFAULT_PAGES.FOOTER.ABOUT',
            footerAboutLinkUrl: 'https://www.dell.com/{{localeCode}}/dt/corporate/about-us/who-we-are.html',
            footerAboutLinkTarget: ActionTargetEnum.SAME_TAB,
            localizedResources: {}
        } as InternalNavigateToOptions
    };

    public get(pageCode: string): InternalNavigateToOptions | undefined {
        const key = pageCode?.toString();
        if (!key) {
            return undefined;
        }
        return NavigateToOptionsFactory.DEFAULTS[key];
    }
}
