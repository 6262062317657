/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    ILocalizationService,
    ILocalizationServiceTypeName
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {ISiteService, ISiteServiceTypeName} from "sirius-platform-support-library/shared/site/site.interface";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";
import {SiteService, SiteServiceTypeName} from "./site.service";
import {
    PlatformTranslationService,
    PlatformTranslationServiceTypeName
} from "../localization/translations/platform-translation.service";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export class SiteDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<ISiteService, SiteService>(DependencyType.SHARED, ISiteServiceTypeName, SiteServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);
                const platformTranslateService = di.resolve<PlatformTranslationService>(PlatformTranslationServiceTypeName);
                return SiteService.build(
                    window,
                    tenant,
                    localizationService,
                    platformTranslateService,
                );
            })
            .addInterfacesOnSingleton(SiteServiceTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const siteDependencyModule = new SiteDependencyModule();
