/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ILoadingIndicatorService
} from "sirius-platform-support-library/shared/loading-indicators/loading-indicator-service.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    LoadingIndicatorConstants
} from "sirius-platform-support-library/shared/loading-indicators/loading-indicator.constants";
import {
    ISplashScreenService
} from "sirius-platform-support-library/shared/splash-screen/splash-screen-service.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {TenantContext} from "sirius-platform-support-library/tenants/tenant-context";
import {
    IStandaloneProgressIndicatorsService
} from "../progress-indicators/standalone/standalone-progress-indicators-service.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    LoadingIndicatorEvents
} from "sirius-platform-support-library/shared/loading-indicators/loading-indicator.events";
import {
    StandaloneProgressIndicatorsOptions
} from "../progress-indicators/standalone/standalone-progress-indicators.options";
import {
    IDefaultPagesService
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {USE_LOADING_INDICATOR_PLACEHOLDER} from "../../sirius.config.constants";

export const LoadingIndicatorServiceTypeName = 'LoadingIndicatorService';

export class LoadingIndicatorService implements ILoadingIndicatorService {
    public static readonly SPLASH_SCREEN_SHOWN_SESSION_FLAG_KEY = 'sirius.splash-screen.shown';

    private readonly window: Window;
    private readonly eventBus: IEventBus;
    private readonly progressIndicatorsService: IStandaloneProgressIndicatorsService;
    private readonly splashScreenService: ISplashScreenService;
    private readonly defaultPagesService: IDefaultPagesService;
    private readonly serviceCollection: IServiceCollection;

    private readonly useLoadingIndicator: boolean = true;

    public constructor(
        window: Window,
        eventBus: IEventBus,
        progressIndicatorsService: IStandaloneProgressIndicatorsService,
        splashScreenService: ISplashScreenService,
        defaultPagesService: IDefaultPagesService,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.eventBus = eventBus;
        this.progressIndicatorsService = progressIndicatorsService;
        this.splashScreenService = splashScreenService;
        this.defaultPagesService = defaultPagesService;
        this.serviceCollection = serviceCollection;

        this.splashScreenService.setFailFast(this.defaultPagesService.isOnFullScreenErrorPage());

        const siriusConfig = (this.window as any)?.sirius?.config;
        this.useLoadingIndicator = ObjectUtility.isDefined(siriusConfig?.useLoadingIndicator) && siriusConfig?.useLoadingIndicator !== USE_LOADING_INDICATOR_PLACEHOLDER ? siriusConfig.useLoadingIndicator.toLowerCase() === 'true' : true;

        this.bind();
    }

    public static build(
        window: Window,
        eventBus: IEventBus,
        progressIndicatorsService: IStandaloneProgressIndicatorsService,
        splashScreenService: ISplashScreenService,
        defaultPagesService: IDefaultPagesService,
        serviceCollection: IServiceCollection
    ): LoadingIndicatorService {
        let instance = ObjectUtility.getFromObjectPath<LoadingIndicatorService>(LoadingIndicatorConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new LoadingIndicatorService(
                window,
                eventBus,
                progressIndicatorsService,
                splashScreenService,
                defaultPagesService,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(LoadingIndicatorConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): ILoadingIndicatorService {
        return ObjectUtility.getFromObjectPath<ILoadingIndicatorService>(LoadingIndicatorConstants.GLOBAL_KEY);
    }

    public async show(): Promise<void> {
        try {
            if (!this.useLoadingIndicator || this.defaultPagesService.isOnFullScreenErrorPage()) {
                return;
            }
            const options = await this.splashScreenService.getOptions();
            const showOnlyOncePerSession = options?.showOnlyOncePerSession ?? false;
            const splashScreenWasShown = showOnlyOncePerSession && this.hasSplashScreenBeenShown();
            const splashScreenIsAvailable = await this.splashScreenService.isAvailable();
            const loadingIndicatorOptions = await this.splashScreenService.getOptions();
            const shouldIgnoreSplashScreenForRoute = await this.splashScreenService.shouldIgnoreForRoute(this.window.location.pathname);
            if (!splashScreenWasShown && splashScreenIsAvailable && !shouldIgnoreSplashScreenForRoute) {
                if (this.progressIndicatorsService.isShown()) {
                    this.progressIndicatorsService.hide();
                }
                await this.splashScreenService.show();
            } else {
                const options = {
                    ...loadingIndicatorOptions
                } as StandaloneProgressIndicatorsOptions;
                this.progressIndicatorsService.showWithOptions(options);
            }
        } catch (e) {
            console.error(e);
        }
    }

    public async hide(): Promise<void> {
        try {
            if (!this.useLoadingIndicator) {
                return;
            }
            const splashScreenIsAvailable = await this.splashScreenService.isAvailable();
            if (splashScreenIsAvailable) {
                const wasShown = this.splashScreenService.isShown();
                await this.splashScreenService.hide();
                if (wasShown) {
                    this.setSplashScreenShownSessionFlag();
                }
            }
            if (this.progressIndicatorsService.isShown()) {
                this.progressIndicatorsService.hide();
            }
        } catch (e) {
            console.error(e);
        }
    }

    public isShown(): boolean {
        return this.progressIndicatorsService.isShown() || this.splashScreenService.isShown();
    }

    public async autoHideIfConfigured(delayed: boolean = false, delay: number = 3000): Promise<void> {
        try {
            const context = this.getTenantContext();
            const splashScreenConfig: any = context?.behaviour?.splashScreen ?? {};
            const autoHide = ObjectUtility.isDefined(splashScreenConfig.autoHide) ? splashScreenConfig.autoHide : true;
            delayed = ObjectUtility.isDefined(splashScreenConfig.delayed) ? splashScreenConfig.delayed : delayed;
            delay = ObjectUtility.isDefined(splashScreenConfig.delay) ? splashScreenConfig.delay : delay;
            if (delay < 0) {
                delay = 1000;
            }
            if (autoHide) {
                if (delayed && delay > 0) {
                    setTimeout(async () => {
                        await this.hide();
                    }, delay);
                } else {
                    await this.hide();
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    public clearShownFlag(): void {
        this.eventBus.dispatchBroadcast(LoadingIndicatorServiceTypeName, LoadingIndicatorEvents.CLEAR_SHOWN_FLAG_EVENT);
    }

    private bind(): void {
        this.eventBus.registerBroadcast<void>(this, LoadingIndicatorServiceTypeName, LoadingIndicatorEvents.CLEAR_SHOWN_FLAG_EVENT, this.onClearShownFlag.bind(this));
    }

    private getTenantContext(): TenantContext | undefined {
        const tenant = this.serviceCollection.resolve<ITenant>(ITenantTypeName);
        return tenant?.getContext();
    }

    private setSplashScreenShownSessionFlag(): void {
        this.window.sessionStorage.setItem(LoadingIndicatorService.SPLASH_SCREEN_SHOWN_SESSION_FLAG_KEY, 'true');
    }

    private hasSplashScreenBeenShown(): boolean {
        return this.window.sessionStorage.getItem(LoadingIndicatorService.SPLASH_SCREEN_SHOWN_SESSION_FLAG_KEY) === 'true';
    }

    private onClearShownFlag(): void {
        this.window.sessionStorage.removeItem(LoadingIndicatorService.SPLASH_SCREEN_SHOWN_SESSION_FLAG_KEY);
    }
}
