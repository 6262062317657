/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    BannerAlertsSectionPageCustomizationHandler,
    BannerAlertsSectionPageCustomizationHandlerTypeName
} from "./platform-handlers/banner-alerts-section-page-customization.handler";
import {
    IPageCustomizationsHandler,
    IPageCustomizationsHandlerTypeName
} from "sirius-platform-support-library/shared/page-customizations/page-customizations-handler.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    ToastsSectionPageCustomizationHandler,
    ToastsSectionPageCustomizationHandlerTypeName
} from "./platform-handlers/toasts-section-page-customization.handler";
import {
    HeaderSectionPageCustomizationHandler,
    HeaderSectionPageCustomizationHandlerTypeName
} from "./platform-handlers/header-section-page-customization.handler";
import {
    NavbarSectionPageCustomizationHandler,
    NavbarSectionPageCustomizationHandlerTypeName
} from "./platform-handlers/navbar-section-page-customization.handler";
import {
    FooterSectionPageCustomizationHandler,
    FooterSectionPageCustomizationHandlerTypeName
} from "./platform-handlers/footer-section-page-customization.handler";
import {PageCustomizationsService, PageCustomizationsServiceTypeName} from "./page-customizations.service";
import {
    IPageCustomizationsService,
    IPageCustomizationsServiceTypeName
} from "sirius-platform-support-library/shared/page-customizations/page-customizations-service.interface";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {
    InternalCustomizationsMapper,
    InternalCustomizationsMapperTypeName
} from "./options/internal-customizations.mapper";
import {
    IPlatformBrowserNavigationEventsReceiverTypeName
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";
import {
    AggregatedPageCustomizationsProvider,
    AggregatedPageCustomizationsProviderTypeName
} from "./providers/aggregated-page-customizations.provider";
import {
    TenantContextPageCustomizationsProvider,
    TenantContextPageCustomizationsProviderTypeName,
} from "./providers/tenant-context-page-customizations.provider";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {DynamicMenuService, DynamicMenuServiceTypeName} from "../dynamic-menu/dynamic-menu.service";
import {
    ContentSectionPageCustomizationHandler,
    ContentSectionPageCustomizationHandlerTypeName
} from "./platform-handlers/content-section-page-customization.handler";
import {
    QuickNavSectionPageCustomizationHandler,
    QuickNavSectionPageCustomizationHandlerTypeName
} from "./platform-handlers/quick-nav-section-page-customization.handler";
import {
    IAuthorizationService,
    IAuthorizationServiceTypeName
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";

export class PageCustomizationsDependencyModule extends TypedDependencyModule {
    public register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<InternalCustomizationsMapper>(DependencyType.PRIVATE, InternalCustomizationsMapperTypeName, new InternalCustomizationsMapper())
            .addUsingFactoryAsSingleton<IPageCustomizationsHandler, BannerAlertsSectionPageCustomizationHandler>(DependencyType.PRIVATE, IPageCustomizationsHandlerTypeName, BannerAlertsSectionPageCustomizationHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new BannerAlertsSectionPageCustomizationHandler(window, document, tenant);
            })
            .addUsingFactoryAsSingleton<IPageCustomizationsHandler, ToastsSectionPageCustomizationHandler>(DependencyType.PRIVATE, IPageCustomizationsHandlerTypeName, ToastsSectionPageCustomizationHandlerTypeName, () => {
                return new ToastsSectionPageCustomizationHandler(window);
            })
            .addUsingFactoryAsSingleton<IPageCustomizationsHandler, HeaderSectionPageCustomizationHandler>(DependencyType.PRIVATE, IPageCustomizationsHandlerTypeName, HeaderSectionPageCustomizationHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new HeaderSectionPageCustomizationHandler(window, document, tenant);
            })
            .addUsingFactoryAsSingleton<IPageCustomizationsHandler, NavbarSectionPageCustomizationHandler>(DependencyType.PRIVATE, IPageCustomizationsHandlerTypeName, NavbarSectionPageCustomizationHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new NavbarSectionPageCustomizationHandler(window, document, tenant);
            })
            .addUsingFactoryAsSingleton<IPageCustomizationsHandler, QuickNavSectionPageCustomizationHandler>(DependencyType.PRIVATE, IPageCustomizationsHandlerTypeName, QuickNavSectionPageCustomizationHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new QuickNavSectionPageCustomizationHandler(window, tenant);
            })
            .addUsingFactoryAsSingleton<IPageCustomizationsHandler, ContentSectionPageCustomizationHandler>(DependencyType.PRIVATE, IPageCustomizationsHandlerTypeName, ContentSectionPageCustomizationHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new ContentSectionPageCustomizationHandler(window, tenant);
            })
            .addUsingFactoryAsSingleton<IPageCustomizationsHandler, FooterSectionPageCustomizationHandler>(DependencyType.PRIVATE, IPageCustomizationsHandlerTypeName, FooterSectionPageCustomizationHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new FooterSectionPageCustomizationHandler(window, tenant);
            })
            .addUsingFactoryAsSingleton<TenantContextPageCustomizationsProvider>(DependencyType.PRIVATE, TenantContextPageCustomizationsProviderTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName)
                return new TenantContextPageCustomizationsProvider(
                    tenant
                );
            })
            .addUsingFactoryAsSingleton<AggregatedPageCustomizationsProvider>(DependencyType.PRIVATE, AggregatedPageCustomizationsProviderTypeName, (di) => {
                const tenantContextPageCustomizationsProvider = di.resolve<TenantContextPageCustomizationsProvider>(TenantContextPageCustomizationsProviderTypeName);
                return new AggregatedPageCustomizationsProvider(
                    tenantContextPageCustomizationsProvider,
                    di
                );
            })
            .addUsingFactoryAsSingleton<IPageCustomizationsService, PageCustomizationsService>(DependencyType.SHARED, IPageCustomizationsServiceTypeName, PageCustomizationsServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const customizationsMapper = di.resolve<InternalCustomizationsMapper>(InternalCustomizationsMapperTypeName);
                const pageCustomizationsProvider = di.resolve<AggregatedPageCustomizationsProvider>(AggregatedPageCustomizationsProviderTypeName);
                const dynamicMenuService = di.resolve<DynamicMenuService>(DynamicMenuServiceTypeName);
                const authorizationService = di.resolve<IAuthorizationService>(IAuthorizationServiceTypeName);
                return PageCustomizationsService.build(
                    window,
                    tenant,
                    pageCustomizationsProvider,
                    customizationsMapper,
                    dynamicMenuService,
                    authorizationService,
                    di
                );
            })
            .addInterfacesOnSingleton(PageCustomizationsServiceTypeName, IPlatformBrowserNavigationEventsReceiverTypeName)
            .addInterfacesOnSingleton(PageCustomizationsServiceTypeName, IAfterPlatformReadyInitTypeName);
    }
}

export const pageCustomizationsDependencyModule = new PageCustomizationsDependencyModule();
