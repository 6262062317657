/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {SearchService, SearchServiceTypeName} from "./search.service";
import {
    ISearchService,
    ISearchServiceTypeName
} from "sirius-platform-support-library/shared/search/search-service.interface";
import {
    IPlatformBrowserNavigationEventsReceiverTypeName
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";

export class SearchDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<ISearchService, SearchService>(DependencyType.SHARED, ISearchServiceTypeName, SearchServiceTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                return SearchService.build(
                    window,
                    eventBus,
                    di
                );
            })
            .addInterfacesOnSingleton(SearchServiceTypeName, IAfterPlatformReadyInitTypeName)
            .addInterfacesOnSingleton(SearchServiceTypeName, IPlatformBrowserNavigationEventsReceiverTypeName)
    }
}

export const searchDependencyModule = new SearchDependencyModule();

