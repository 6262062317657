/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {SystemJs} from "../dynamic-imports/system-js";
import {IRuntimeConfiguratorModule} from "sirius-shell-support-library/runtime-configurator-module.interface";
import {
    ITenantContextProviderConfigurator
} from "sirius-shell-support-library/tenants/configurators/tenant-context-provider-configurator.interface";
import {
    IRuntimeOptionsConfigurator
} from "sirius-shell-support-library/runtime-options/runtime-options-configurator.interface";
import {RuntimeOptions} from "sirius-shell-support-library/runtime-options/runtime-options";
import {
    IDefaultPagesService
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {retry} from "sirius-platform-support-library/utilities/promises/retry-promise";
import {
    IDependencyContainer
} from "sirius-platform-support-library/dependency-injection/generic/dependency-container.interface";
import {RuntimeConfiguratorModuleLoadError} from "./runtime-configurator-module-load.error";
import {IInternalRuntimeConfiguratorModule} from "./internal-runtime-configurator-module.interface";
import {IInternalTenantContextProviderConfigurator} from "./internal-tenant-context-provider-configurator.interface";
import {IInternalRuntimeOptionsConfigurator} from "./internal-runtime-options-configurator.interface";
import {TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_URL_PLACEHOLDER} from "../../sirius.config.constants";

export const RuntimeConfigurationLoaderTypeName = 'RuntimeConfigurationLoader';

export class RuntimeConfigurationLoader {
    public static readonly DEFAULT_TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_MIN_BUNDLE_URL_PRIMARY = '/libs/runtime-configurator/main.min.js';
    public static readonly DEFAULT_TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_BUNDLE_URL_PRIMARY = '/libs/runtime-configurator/main.js';
    public static readonly DEFAULT_TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_MIN_BUNDLE_URL_SECONDARY = '/libs/sirius-tenant-context-provider-runtime-configurator/main.min.js';
    public static readonly DEFAULT_TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_BUNDLE_URL_SECONDARY = '/libs/sirius-tenant-context-provider-runtime-configurator/main.js';
    private static preloadPromise?: Promise<IRuntimeConfiguratorModule | undefined>;
    private static preloadedRuntimeConfiguratorModule?: IRuntimeConfiguratorModule;
    private readonly systemJs: SystemJs;
    private readonly defaultPagesService: IDefaultPagesService;
    private readonly defaultRuntimeConfiguratorModule: IRuntimeConfiguratorModule;
    private readonly dependencyContainer: IDependencyContainer;
    private runtimeConfiguratorModule: IInternalRuntimeConfiguratorModule;

    constructor(
        systemJs: SystemJs,
        defaultPagesService: IDefaultPagesService,
        dependencyContainer: IDependencyContainer
    ) {
        this.systemJs = systemJs;
        this.defaultPagesService = defaultPagesService;
        this.dependencyContainer = dependencyContainer;

        this.defaultRuntimeConfiguratorModule = this.getDefaultRuntimeConfiguratorModule();
    }

    public static async preload(systemJs: SystemJs, failFast: boolean = true): Promise<void> {
        if (RuntimeConfigurationLoader.preloadPromise || RuntimeConfigurationLoader.preloadedRuntimeConfiguratorModule) {
            return;
        }

        const localLoad = async () => {
            if (!RuntimeConfigurationLoader.preloadedRuntimeConfiguratorModule) {
                try {
                    RuntimeConfigurationLoader.preloadedRuntimeConfiguratorModule = await RuntimeConfigurationLoader.getRuntimeConfiguratorModule(systemJs, failFast);
                } catch (e) {
                    console.warn('Failed to preload runtime configurator module', e);
                    RuntimeConfigurationLoader.preloadedRuntimeConfiguratorModule = undefined;
                }
            }

            return RuntimeConfigurationLoader.preloadedRuntimeConfiguratorModule;
        }

        if (!RuntimeConfigurationLoader.preloadPromise) {
            RuntimeConfigurationLoader.preloadPromise = localLoad();
        }
    }

    private static async getRuntimeConfiguratorModule(systemJs: SystemJs, failFast: boolean = false): Promise<IRuntimeConfiguratorModule> {
        const urls: string[] = [];
        // @ts-ignore
        const argsUrl = window?.sirius?.config?.tenantContextProviderConfiguratorUrl;
        if (argsUrl && argsUrl !== TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_URL_PLACEHOLDER) {
            urls.push(argsUrl);
        }
        urls.push(
            ...[
                RuntimeConfigurationLoader.DEFAULT_TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_MIN_BUNDLE_URL_PRIMARY,
                RuntimeConfigurationLoader.DEFAULT_TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_BUNDLE_URL_PRIMARY,
                RuntimeConfigurationLoader.DEFAULT_TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_MIN_BUNDLE_URL_SECONDARY,
                RuntimeConfigurationLoader.DEFAULT_TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_BUNDLE_URL_SECONDARY,
            ]
        );
        let failedCount = 0;
        for (let index = 0; index < urls.length; index++) {
            const url = urls[index];
            if (!url) {
                continue;
            }
            const runtimeConfigurator = await RuntimeConfigurationLoader.getRuntimeConfiguratorModuleFromUrl(systemJs, url, failFast);
            if (runtimeConfigurator) {
                return runtimeConfigurator;
            }
            failedCount++;
        }
        if (failedCount === urls.length) {
            throw new RuntimeConfiguratorModuleLoadError(`Failed to load runtime configurator module from any of the following urls: ${urls.join(', ')}`);
        }
        return undefined;
    }

    private static async getRuntimeConfiguratorModuleFromUrl(systemJs: SystemJs, url: string, failFast: boolean = false): Promise<IRuntimeConfiguratorModule> {
        try {
            if (url.startsWith('https://') || url.startsWith('http://')) {
                return failFast ? await systemJs.import<IRuntimeConfiguratorModule>(url) : await retry(() => systemJs.import<IRuntimeConfiguratorModule>(url), {
                    retries: 3,
                    backoff: "EXPONENTIAL",
                    maxBackOff: 5 * 1000
                });
            } else {
                return await systemJs.import<IRuntimeConfiguratorModule>(url);
            }
        } catch (e) {
            console.warn(e);
            return undefined;
        }
    }

    public async load(failFast: boolean = false): Promise<void> {
        try {
            this.runtimeConfiguratorModule = RuntimeConfigurationLoader.preloadedRuntimeConfiguratorModule;
            if (!this.runtimeConfiguratorModule) {
                const promise = RuntimeConfigurationLoader.preloadPromise;
                if (promise) {
                    this.runtimeConfiguratorModule = await promise;
                }
                if (!this.runtimeConfiguratorModule) {
                    this.runtimeConfiguratorModule = await RuntimeConfigurationLoader.getRuntimeConfiguratorModule(this.systemJs, failFast);
                }
            }

            if (!this.runtimeConfiguratorModule) {
                this.runtimeConfiguratorModule = this.defaultRuntimeConfiguratorModule;
            }
        } catch (e) {
            if (e instanceof RuntimeConfiguratorModuleLoadError) {
                this.runtimeConfiguratorModule = this.defaultRuntimeConfiguratorModule;
                (this.runtimeConfiguratorModule as any).defaultedDueToUnavailability = true;
            }
            this.runtimeConfiguratorModule = this.defaultRuntimeConfiguratorModule;
        }

        if (this.runtimeConfiguratorModule.bootstrap) {
            try {
                this.runtimeConfiguratorModule.bootstrap(this.dependencyContainer);
            } catch (e) {
                console.error(e);
            }
        }
    }

    public getTenantContextProviderConfigurator(): IInternalTenantContextProviderConfigurator {
        const tenantContextProviderConfigurator = this.runtimeConfiguratorModule?.getTenantContextProviderConfigurator ? this.runtimeConfiguratorModule?.getTenantContextProviderConfigurator() : this.defaultRuntimeConfiguratorModule.getTenantContextProviderConfigurator();
        (tenantContextProviderConfigurator as any).defaultedDueToUnavailability = this.runtimeConfiguratorModule?.defaultedDueToUnavailability;
        return tenantContextProviderConfigurator;
    }

    public getRuntimeOptionsConfigurator(): IInternalRuntimeOptionsConfigurator {
        const runtimeOptionsConfigurator = this.runtimeConfiguratorModule?.getRuntimeOptionsConfigurator ? this.runtimeConfiguratorModule?.getRuntimeOptionsConfigurator() : this.defaultRuntimeConfiguratorModule.getRuntimeOptionsConfigurator();
        (runtimeOptionsConfigurator as any).defaultedDueToUnavailability = this.runtimeConfiguratorModule?.defaultedDueToUnavailability;
        return runtimeOptionsConfigurator;
    }

    private getDefaultRuntimeConfiguratorModule(): IRuntimeConfiguratorModule {
        return {
            getTenantContextProviderConfigurator(): ITenantContextProviderConfigurator {
                return {
                    getTenantContextProviderBundleUrl(): string | undefined {
                        // @ts-ignore
                        return window?.sirius?.config?.tenantContextProviderUrl;
                    }
                };
            },
            getRuntimeOptionsConfigurator(): IRuntimeOptionsConfigurator {
                return {
                    getRuntimeOptions(): RuntimeOptions {
                        return {
                            // @ts-ignore
                            splashScreenBundleUrl: window?.sirius?.config?.tenantSplashScreenUrl
                        }
                    }
                };
            }
        };
    }
}
