/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IAnalyticsEngine} from "sirius-platform-support-library/shared/analytics/analytics-engine.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {AnalyticsEngineConfig, PendoOptions} from "sirius-platform-support-library/tenants/tenant-context";
import {EngineUtilities} from "./engine-utilities";
import {sanitizeUrl} from "@braintree/sanitize-url";

export const PendoEngineTypeName = 'PendoEngine';

export class PendoEngine implements IAnalyticsEngine {

    private static readonly CODE = 'PENDO';

    private readonly window: Window;
    private readonly document: Document;
    private readonly tenant: ITenant;
    private readonly pendoEngineConfig: AnalyticsEngineConfig;

    private pendoEngineOptions: PendoOptions;
    private initialized: boolean = false;

    constructor(
        window: Window,
        document: Document,
        tenant: ITenant
    ) {
        this.window = window;
        this.document = document;
        this.tenant = tenant;
        this.pendoEngineConfig = (tenant.getContext()?.analytics || []).find((engines) => engines?.analyticsEngineName === PendoEngine.CODE) ?? {
            enabled: false,
            analyticsEngineName: PendoEngine.CODE,
            userConfigurable: false
        };
        this.pendoEngineOptions = this.pendoEngineConfig?.options;
    }

    public getUniqueCode(): string {
        return PendoEngine.CODE;
    }

    public getUserPreferenceKey(): string | undefined {
        return this.pendoEngineConfig?.userPreferenceKey;
    }

    public async init(options?: PendoOptions | any, override?: boolean): Promise<void> {
        this.pendoEngineOptions = options;
        if (override) {
            this.pendoEngineConfig.enabled = true;
        }
        if (!this.pendoEngineConfig.enabled) {
            return;
        }
        const existingScript = this.document.querySelector(`script[code='${this.getUniqueCode()}']`);
        if (existingScript) {
            this.initialized = true;
            return;
        }
        if (!options?.clientKey) {
            return;
        }
        await new Promise<void>((resolve, reject) => {
            const context = {
                resolve,
                reject,
                uniqueCode: this.getUniqueCode()
            };
            /*eslint-disable */
            (function (context, p, e, n, d, o) {
                let v, w, x, y, z;
                o = p[d] = p[d] || {};
                o._q = o._q || [];
                v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
                for (w = 0, x = v.length; w < x; ++w) (function (m) {
                    o[m] = o[m] || function () {
                        o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                    };
                })(v[w]);
                y = e.createElement(n);
                y.setAttribute('code', context.uniqueCode);
                y.async = !0;
                y.src = sanitizeUrl(`https://cdn.pendo.io/agent/static/${options.clientKey}/pendo.js`);
                y.onload = () => {
                    context.resolve();
                };
                y.onerror = () => {
                    context.reject('Could not load Pendo script.');
                };
                z = e.getElementsByTagName(n)[0];
                z.parentNode.insertBefore(y, z);
            })(context, this.window, this.document, 'script', 'pendo');
            /*eslint-enable */
        });
        const internalEngine = await EngineUtilities.waitUntilResultDefined(this.getInternalEngine.bind(this));
        if (internalEngine) {
            internalEngine.initialize({
                visitor: options?.visitor || {},
                account: options?.account || {}
            });
            internalEngine.stopSendingEvents();
        }
        this.initialized = true;
    }

    public async setEnabled(enabled: boolean): Promise<void> {
        if (!this.initialized) {
            await this.init(this.pendoEngineOptions);
        }
        const internalEngine = await EngineUtilities.waitUntilResultDefined(this.getInternalEngine.bind(this));
        const isPendoEnabled = internalEngine?.isSendingEvents();
        if (enabled && !isPendoEnabled) {
            internalEngine?.startSendingEvents();
        } else if (!enabled && isPendoEnabled) {
            internalEngine?.stopSendingEvents();
        }
    }

    public getOptions(): PendoOptions | any | undefined {
        const pendoOptions = Object.assign({}, this.pendoEngineOptions ?? {}) as any;
        if (pendoOptions?.clientKey) {
            delete pendoOptions.clientKey;
        }
        return pendoOptions;
    }

    public async setOptions(options?: PendoOptions | any): Promise<void> {
    }

    private getInternalEngine(): any | undefined {
        // @ts-ignore
        return this.window.pendo;
    }
}
