/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {IEventsSubscriptionsCollection} from "./events-subscriptions-collection.interface";
import {IEventSubscriptionHandler} from "./event-subscription-handler.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";

export class EventSubscriptionHandler implements IEventSubscriptionHandler {
    private readonly eventsSubscriptions: IEventsSubscriptionsCollection;

    constructor(eventsSubscriptions: IEventsSubscriptionsCollection) {
        this.eventsSubscriptions = eventsSubscriptions;
    }

    public unsubscribe(eventSubscription: IEventSubscription): void {
        delete this.eventsSubscriptions[eventSubscription.event][eventSubscription.id];
        if (Object.keys(this.eventsSubscriptions[eventSubscription.event]).length === 0) {
            delete this.eventsSubscriptions[eventSubscription.event];
        }
    }
}
