/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IBrowserNavigationGateHandler
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-gate-handler.interface";

export const IPlatformBrowserNavigationGateHandlerTypeName = 'IPlatformBrowserNavigationGateHandler';

export interface IPlatformBrowserNavigationGateHandler extends IBrowserNavigationGateHandler {
}
