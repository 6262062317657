/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IIconLoader} from "sirius-platform-support-library/shared/icons/loaders/icon-loader.interface";
import {
    ISiriusIconsRegistry
} from "sirius-platform-support-library/shared/icons/registry/sirius-icons-registry.interface";
import {InternalIcon} from "sirius-platform-support-library/shared/icons/registry/internal.icon";
import {ALIASES} from "./dds.constants";
import {sanitizeUrl} from "@braintree/sanitize-url";

export const DdsIconsLoaderTypeName = 'DdsIconsLoader';

export class DdsIconsLoader implements IIconLoader {
    public static readonly ALL_SHAPES_RESOURCES_URL = '/libs/@dds/components/src/icons/dds-icons-defs.svg';
    public static readonly UNIQUE_CODE = 'DDS';
    public static readonly SET_CODE = 'dds';

    private context: Record<string, string> = {};

    public getUniqueCode(): string {
        return DdsIconsLoader.UNIQUE_CODE;
    }

    public async bind(iconRegistry: ISiriusIconsRegistry): Promise<void> {
        await this.registerIcons(iconRegistry);
    }

    public async load(icon: InternalIcon): Promise<void> {
        throw new Error('Implementation not required');
    }

    private async registerIcons(iconRegistry: ISiriusIconsRegistry): Promise<void> {
        try {
            this.context = await this.buildContext(DdsIconsLoader.ALL_SHAPES_RESOURCES_URL);
            (Object.keys(this.context) || []).forEach((shapeName) => {
                const resource = this.context[shapeName];
                if (!resource) {
                    return;
                }
                const aliases = ALIASES[shapeName] ?? [];
                iconRegistry.addIcon({
                    code: shapeName,
                    aliases: aliases,
                    resource: resource,
                    set: DdsIconsLoader.SET_CODE
                });
            });
            if (this.context) {
                this.context = {};
            }
        } catch (e) {
            console.warn(`Could not register ${DdsIconsLoader.SET_CODE} icons due to an encountered exception.`, e);
            this.context = {};
        }
    }

    private async buildContext(resourceUrl: string): Promise<Record<string, string>> {
        try {
            const sanitizedUrl = sanitizeUrl(resourceUrl);
            const response = await fetch(sanitizedUrl);
            if (response.status !== 200) {
                return undefined;
            }
            const resource = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(resource, "text/xml");
            const xpath = "//*[local-name()='symbol'][@id]";
            const result = doc.evaluate(xpath, doc, null, XPathResult.UNORDERED_NODE_ITERATOR_TYPE, null);
            const context: Record<string, string> = {};
            for (let i = 0, node; (node = result.iterateNext()); i++) {
                const iconCode = node.getAttribute("id")?.replace('dds__icon--', '');
                if (!iconCode) {
                    continue;
                }
                context[iconCode] = this.buildCompleteSvg(node.innerHTML);
            }
            return context;
        } catch (e) {
            console.warn(`Could not fetch icon resource ${resourceUrl} due to an encountered exception.`, e);
            return {};
        }
    }

    private buildCompleteSvg(resource?: string): string | undefined {
        if (!resource) {
            return resource;
        }
        return `<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 32 32">${resource}</svg>`;
    }
}
