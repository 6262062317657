/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {v4 as uuidv4} from 'uuid';

export class SubscriberIdGenerator {
    public static getNextId() {
        return uuidv4();
    }
}
