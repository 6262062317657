/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import { ToastType } from "sirius-platform-support-library/shared/toasts/toast-type";
import { errorIconTemplate, infoIconTemplate, successIconTemplate, warningIconTemplate } from "./templates";

export const toastTypeClass = {
    [ToastType.INFO]: 'toast--info',
    [ToastType.SUCCESS]: 'toast--success',
    [ToastType.WARNING]: 'toast--warning',
    [ToastType.ERROR]: 'toast--error',
};

export const toastTypeIconTemplate = {
    [ToastType.INFO]: infoIconTemplate,
    [ToastType.SUCCESS]: successIconTemplate,
    [ToastType.WARNING]: warningIconTemplate,
    [ToastType.ERROR]: errorIconTemplate,
};