/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DynamicMenuSection} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-section.enum";
import {SectionMarkerCodes} from "sirius-platform-support-library/shared/page-customizations/section-marker-codes";

export class DynamicSectionCodeMapper {
    public static readonly MAP: Record<string, DynamicMenuSection> = {
        [SectionMarkerCodes.HEADER_MARKER]: DynamicMenuSection.HEADER,
        [SectionMarkerCodes.NAVBAR_MARKER]: DynamicMenuSection.NAVBAR,
        [SectionMarkerCodes.QUICKNAV_MARKER]: DynamicMenuSection.QUICKNAV,
        [SectionMarkerCodes.FOOTER_MARKER]: DynamicMenuSection.FOOTER,
    };
}
