/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    IAuthorizationService,
    IAuthorizationServiceTypeName
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";
import {AuthorizationService, AuthorizationServiceTypeName} from "./authorization.service";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {
    IAuthenticationService,
    IAuthenticationServiceTypeName
} from "sirius-platform-support-library/shared/authentication/authentication-service.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    PlatformPermissionsProvider,
    PlatformPermissionsProviderTypeName
} from "./providers/platform-permissions.provider";
import {
    IPermissionsProvider,
    IPermissionsProviderTypeName
} from "sirius-platform-support-library/shared/authorization/providers/permissions-provider.interface";
import {
    IPlatformBrowserNavigationGateHandler,
    IPlatformBrowserNavigationGateHandlerTypeName
} from "../browser-events/platform/platform-browser-navigation-gate-handler.interface";
import {
    AuthorizationBrowserNavigationGateHandler,
    AuthorizationBrowserNavigationGateHandlerTypeName
} from "./routing-gates/authorization-browser-navigation-gate.handler";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";

export class AuthorizationDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<IPermissionsProvider, PlatformPermissionsProvider>(DependencyType.PRIVATE, IPermissionsProviderTypeName, PlatformPermissionsProviderTypeName, new PlatformPermissionsProvider())
            .addUsingFactoryAsSingleton<IAuthorizationService, AuthorizationService>(DependencyType.SHARED, IAuthorizationServiceTypeName, AuthorizationServiceTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const authenticationService = di.resolve<IAuthenticationService>(IAuthenticationServiceTypeName);
                return AuthorizationService.build(
                    window,
                    eventBus,
                    authenticationService,
                    di
                );
            })
            .addInterfacesOnSingleton(AuthorizationServiceTypeName, IAfterPlatformReadyInitTypeName)
            .addUsingFactoryAsSingleton<IPlatformBrowserNavigationGateHandler, AuthorizationBrowserNavigationGateHandler>(DependencyType.PRIVATE, IPlatformBrowserNavigationGateHandlerTypeName, AuthorizationBrowserNavigationGateHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const authorizationService = di.resolve<IAuthorizationService>(IAuthorizationServiceTypeName);
                const defaultPagesService = di.resolve<IDefaultPagesService>(IDefaultPagesServiceTypeName);

                return new AuthorizationBrowserNavigationGateHandler(
                    tenant,
                    authorizationService,
                    defaultPagesService
                );
            });
    }
}

export const authorizationDependencyModule = new AuthorizationDependencyModule();

