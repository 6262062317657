/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DeviceTraits} from "sirius-platform-support-library/shared/device-traits/device-traits";
import {
    DeviceTraitsChangedCallback,
    IDeviceTraitsService
} from "sirius-platform-support-library/shared/device-traits/device-traits-service.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {DeviceTraitsConstants} from "sirius-platform-support-library/shared/device-traits/device-traits.constants";
import {
    IDeviceTraitsProvider,
    IDeviceTraitsProviderTypeName
} from "sirius-platform-support-library/shared/device-traits/device-traits-provider.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {DeviceTraitsEvent} from "sirius-platform-support-library/shared/device-traits/events/device-traits.event";
import {DeviceTraitsEvents} from "sirius-platform-support-library/shared/device-traits/events/device-traits.events";

export const DeviceTraitsServiceTypeName = 'DeviceTraitsService';

export class DeviceTraitsService implements IDeviceTraitsService {

    private readonly eventBus: IEventBus;
    private readonly serviceCollection: IServiceCollection;
    private readonly deviceTraits: DeviceTraits = {
        model: '',
        brand: '',
        deviceType: undefined,
        manufacturer: undefined,
        theme: ''
    };

    public constructor(
        eventBus: IEventBus,
        serviceCollection: IServiceCollection
    ) {
        this.eventBus = eventBus;
        this.serviceCollection = serviceCollection;
    }

    public static build(
        eventBus: IEventBus,
        serviceCollection: IServiceCollection
    ): DeviceTraitsService {
        let instance = ObjectUtility.getFromObjectPath<DeviceTraitsService>(DeviceTraitsConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new DeviceTraitsService(
                eventBus,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(DeviceTraitsConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IDeviceTraitsService {
        return ObjectUtility.getFromObjectPath<IDeviceTraitsService>(DeviceTraitsConstants.GLOBAL_KEY);
    }

    public async getTraits(): Promise<DeviceTraits> {
        return this.deviceTraits;
    }

    public async load(): Promise<void> {
        const traits = Object.assign({}, this.deviceTraits);
        for (const provider of this.getDeviceTraitsProviders()) {
            try {
                const providerTraits = await provider.getTraits();
                Object.assign(traits, providerTraits);
            } catch (e) {
                console.error(e);
            }
        }
        Object.assign(this.deviceTraits, traits);
        this.triggerDeviceTraitsUpdated();
    }

    public onDeviceTraitsUpdated(context: any, subscriberName: string, callback: DeviceTraitsChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<DeviceTraitsEvent>(this, subscriberName, DeviceTraitsEvents.DEVICE_TRAITS_CHANGED, (event) => {
            callback?.call(context, event.data);
        });
    }

    public async refresh(): Promise<void> {
        await this.load();
    }

    private getDeviceTraitsProviders(): IDeviceTraitsProvider[] {
        return this.serviceCollection
            .resolveAll<IDeviceTraitsProvider>(IDeviceTraitsProviderTypeName)
            .filter(p => p);
    }

    private triggerDeviceTraitsUpdated(): void {
        this.eventBus.dispatchBroadcast<DeviceTraitsEvent>(DeviceTraitsServiceTypeName, DeviceTraitsEvents.DEVICE_TRAITS_CHANGED, {
            deviceTraits: this.deviceTraits
        });
    }
}
