/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const BannerAlertTemplate = `
<div class="alert-item-container" >
    <sirius-icon shape="{{iconResource}}" size="24" class="alert-icon" failover aria-hidden="true"></sirius-icon>
    <span class="alert-text" role="alert" tabindex="0" aria-label="{{localizedText}}" >
        {{localizedText}}
    </span>
</div>
<div class="alert-actions">
    {{actions}}
 </div>
`;
