/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {IBeforePlatformReadyInit, IBeforePlatformReadyInitTypeName} from "./before-platform-ready-init.interface";
import {IAfterPlatformReadyInit, IAfterPlatformReadyInitTypeName} from "./after-platform-ready-init.interface";

export const ServicesInitializerTypeName = 'ServicesInitializer';

export class ServicesInitializer {
    private readonly serviceCollection: ITypedServiceCollection;

    public constructor(serviceCollection: ITypedServiceCollection) {
        this.serviceCollection = serviceCollection;
    }

    public async initializeServicesBeforePlatformReady(): Promise<void> {
        const services = this.serviceCollection.resolveAll<IBeforePlatformReadyInit>(IBeforePlatformReadyInitTypeName);
        for (const service of services) {
            try {
                await service.init()
            } catch (e) {
                console.error(e);
            }
        }
    }

    public async initializeServicesAfterPlatformReady(): Promise<void> {
        const services = this.serviceCollection.resolveAll<IAfterPlatformReadyInit>(IAfterPlatformReadyInitTypeName);
        for (const service of services) {
            try {
                await service.init()
            } catch (e) {
                console.error(e);
            }
        }
    }
}
