/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {css, html, LitElement} from "lit";
import {state} from "lit/decorators.js";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {TranslationService} from 'sirius-platform-support-library/shared/localization/translations/translation.service';
import {
    SupportedTranslationsLoaders
} from "sirius-platform-support-library/shared/localization/translations/loaders/translations-loaders.constants";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";

export class SkipToContentComponent extends LitElement {
    public static readonly CUSTOM_ELEMENT_NAME = 'sirius-skip-to-content';

    public static styles = css`
        :host {
            width: 100%;
        }
        #sirius-aria-entry {
            align-items: center;
            border-radius: 2px;
            opacity: 0;
            position: absolute;
            transform: translateY(-100%);
            z-index: 9999;
          }
          #sirius-aria-entry:focus {
            border: 2px solid var(--uxd-neutral-white, #fff);
            opacity: 1;
            outline-offset: -2px;
            transform: translateY(0);
            margin-top: 4px;
          }
          *:focus-visible {
          outline: none !important;
          box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px var(--uxd-primary-midnight,#00468b) !important;
          border-radius: 2px;
          text-decoration: underline;
         box-sizing: border-box;
        }`;

    @state()
    public buttonText: string;

    private translationService?: TranslationService;

    private buttonResource: string = "COMPONENTS.SKIP_TO_CONTENT";

    public constructor() {
        super();
    }

    public async connectedCallback() {
        super.connectedCallback();

        this.translationService = new TranslationService({
            publicPath: {
                provide: () => {
                    // @ts-ignore
                    return __webpack_public_path__;
                }
            },
            type: SupportedTranslationsLoaders.REMOTE_ASSETS,
            options: {
                prependPublicPath: false,
                baseUrl: '/assets/i18n/',
                extension: '.json'
            },
            localizedResources: {}
        });

        this.translationService.onLanguageChanged(this, this.translateProperties);

        await this.translateProperties();

        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'skip-to-content'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-dark-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        await styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(this.renderRoot, DomInjectionType.PREPEND);

        const themingHandlersManager = SharedDependencyContainer.getInstance().getServiceCollection().resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
        if (themingHandlersManager) {
            themingHandlersManager.registerThemeHandler(styleSheetsBasedThemeHandler);
            await themingHandlersManager.attach(ThemedComponent.SHELL_SKIP_TO_CONTENT, this.renderRoot, DomInjectionType.PREPEND);
        }
    }

    public render() {
        return html`
            <button class="btn btn-primary" id="sirius-aria-entry" @click="${this.skipToMainContent}" tabindex="0"
                    aria-label="${this.buttonText}">
                ${unsafeHTML(this.buttonText)}
            </button>
        `;
    }

    public skipToMainContent(): void {
        const contentElement = document.body.querySelector('#content-start') as HTMLElement;
        if (contentElement) {
            contentElement.focus();
        }
    }

    private async translateProperties(): Promise<void> {
        this.buttonText = await this.translationService.translate(this.buttonResource);
    }
}

if (!customElements.get(SkipToContentComponent.CUSTOM_ELEMENT_NAME)) {
    customElements.define(SkipToContentComponent.CUSTOM_ELEMENT_NAME, SkipToContentComponent);
}
