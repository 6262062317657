/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {Locale} from "sirius-platform-support-library/shared/localization/locale";
import {
    ILocalizationService,
    LocaleChangeAllowedStateChangeCallback,
    LocaleChangedCallback
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    ILocaleProvider,
    ILocaleProviderTypeName
} from "sirius-platform-support-library/shared/localization/providers/locale-provider.interface";
import {ILocaleStore} from "sirius-platform-support-library/shared/localization/providers/locale-store.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    ILocaleProviderPriorityAssigner,
    ILocaleProviderPriorityAssignerTypeName
} from "sirius-platform-support-library/shared/localization/providers/locale-provider-priority-assigner.interface";
import {
    LocaleCodeNormalizer
} from "sirius-platform-support-library/shared/localization/normalizers/locale-code-normalizer";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {LocaleChangedEvent} from "sirius-platform-support-library/shared/localization/events/locale-changed.event";
import {
    LegacyLocaleChangedEvent
} from "sirius-platform-support-library/shared/localization/events/legacy-locale-changed.event";
import {GLOBAL_LOCALES} from "sirius-platform-support-library/shared/localization/global-locales";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {ChangeLocaleEvent} from "sirius-platform-support-library/shared/localization/events/change-locale.event";
import {IEvent} from "sirius-platform-support-library/shared/event-bus/event.interface";
import {EventType} from "sirius-platform-support-library/shared/event-bus/event-type";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {LocalizationConstants} from "sirius-platform-support-library/shared/localization/localization-constants";
import {LocalizationEvents} from "sirius-platform-support-library/shared/localization/events/localization-events";
import {DEFAULT_LOCALIZATION} from "sirius-platform-support-library/shared/localization/default-localization";
import {ActionTypeEnum, SupportedLocale} from "sirius-platform-support-library/models/common";

import {v4 as uuidv4} from 'uuid';
import {
    LocaleChangeAllowedStateChangedEvent
} from "sirius-platform-support-library/shared/localization/events/locale-change-allowed-state-changed.event";
import {PlatformActionInteractionService} from "../browser-events/platform-action-interaction.service";
import {
    NavigationAndInteractionGateEventsReceiver
} from "../browser-events/navigation-and-interaction-gate.events-receiver";
import {VirtualActions} from "sirius-platform-support-library/shared/actions/virtual-actions";
import {pathToRegexp} from "path-to-regexp";

export const LocalizationServiceTypeName = 'LocalizationService';

export class LocalizationService implements ILocalizationService, IBeforePlatformReadyInit {
    public static readonly OVERRIDDEN_REFRESH_ON_LOCALE_CHANGE_STORE_KEY = 'dell.sirius.localization.overridden-refresh-on-locale-change';

    public static readonly CROSS_TAB_CHANGE_LOCALE_EVENT = 'dell.sirius.localization.cross-tab-change-locale';

    private static readonly BROADCASTER_ID = uuidv4().toLowerCase();

    private static readonly DEFAULT_LOCALE = 'en_US';
    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly eventBus: IEventBus;
    private readonly actionInteractionService: PlatformActionInteractionService;
    private readonly navigationAndInteractionGateEventsReceiver: NavigationAndInteractionGateEventsReceiver;
    private readonly serviceCollection: ITypedServiceCollection;
    private readonly localeCodeNormalizer: LocaleCodeNormalizer;
    private providers: ILocaleProvider[] = [];
    private tenantSupportedLocales: Record<string, SupportedLocale> = {};
    private tenantDefaultLocaleCode: string;
    private supportDerivedEnglishLocaleCodes: boolean = false;
    private refreshOnLocaleChange: boolean = false;
    private supportedLocales: Record<string, Locale> = {};
    private defaultLocaleCode: string;
    private currentLocaleCode: string;
    private overriddenRefreshOnLocaleChange?: boolean = undefined;
    private localeChangeAllowed: boolean = true;
    private routesWithPageRefreshOnLocaleChange: string[] = [];

    private constructor(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        actionInteractionService: PlatformActionInteractionService,
        navigationAndInteractionGateEventsReceiver: NavigationAndInteractionGateEventsReceiver,
        serviceCollection: ITypedServiceCollection
    ) {
        this.window = window;
        this.tenant = tenant;
        this.eventBus = eventBus;
        this.actionInteractionService = actionInteractionService;
        this.navigationAndInteractionGateEventsReceiver = navigationAndInteractionGateEventsReceiver;
        this.serviceCollection = serviceCollection;
        this.localeCodeNormalizer = new LocaleCodeNormalizer();

        this.initDefaults();
    }

    public static build(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        actionInteractionService: PlatformActionInteractionService,
        navigationAndInteractionGateEventsReceiver: NavigationAndInteractionGateEventsReceiver,
        serviceCollection: ITypedServiceCollection
    ): LocalizationService {
        let instance = ObjectUtility.getFromObjectPath<LocalizationService>(LocalizationConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new LocalizationService(
                window,
                tenant,
                eventBus,
                actionInteractionService,
                navigationAndInteractionGateEventsReceiver,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(LocalizationConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): ILocalizationService {
        return ObjectUtility.getFromObjectPath<ILocalizationService>(LocalizationConstants.GLOBAL_KEY);
    }

    public async preInit(): Promise<void> {
        try {
            this.clearOverriddenRefreshOnLocaleChangeCache();
            this.assignTenantLocalization();
            this.assignSupportedLocales();
            this.changeLocaleCodeInternal(this.getCurrentLocaleCodeFromProviders(), false, true, false, false, true);
        } catch (e) {
            console.error(e);
        }
    }

    public async init(): Promise<void> {
        try {
            this.assignAndSortProviders();
            this.assignTenantLocalization();
            this.assignSupportedLocales();
            this.bindEvents();
            this.changeLocaleCodeInternal(this.getCurrentLocaleCodeFromProviders(), true, true, false, false);
        } catch (e) {
            console.error(e);
        }
    }

    public getProvidersCodes(): string[] {
        return this.providers.map(p => p.getProviderCode());
    }

    public getDefaultLocaleCode(): string {
        return this.defaultLocaleCode;
    }

    public getCurrentLocaleCode(): string {
        return this.currentLocaleCode;
    }

    public getCurrentLocale(): Locale {
        return this.supportedLocales[this.currentLocaleCode];
    }

    public getSupportedLocales(): Locale[] {
        return Object.getOwnPropertyNames(this.supportedLocales).map(lk => this.supportedLocales[lk]);
    }

    public changeLocale(locale: Locale): boolean {
        return this.changeLocaleCode(locale?.localeCode);
    }

    public changeLocaleCode(localeCode: string): boolean {
        return this.changeLocaleCodeInternal(localeCode, true, true, true, true);
    }

    public isLocaleCodeSupported(localeCode: string): boolean {
        const normalizedLocaleCode = this.localeCodeNormalizer.normalize(localeCode);
        return this.isLocaleSupportedInternal(normalizedLocaleCode);
    }

    public configureProviderPriorities(priorityMap: Record<string, number>): void {
        const localeProviderPriorityAssigner = this.serviceCollection.resolve<ILocaleProviderPriorityAssigner>(ILocaleProviderPriorityAssignerTypeName);
        if (localeProviderPriorityAssigner) {
            localeProviderPriorityAssigner.configure(priorityMap);
        }
    }

    public overrideRefreshOnLocaleChange(refreshOnLocaleChange?: boolean): void {
        this.overriddenRefreshOnLocaleChange = refreshOnLocaleChange;
        this.storeOverriddenRefreshOnLocaleChangeCache();
    }

    public clearOverriddenRefreshOnLocaleChange(): void {
        this.overriddenRefreshOnLocaleChange = undefined;
        this.clearOverriddenRefreshOnLocaleChangeCache();
    }

    public isRefreshOnLocaleChangeOverridden(): boolean {
        this.loadOverriddenRefreshOnLocaleChangeCache();
        return this.overriddenRefreshOnLocaleChange === undefined;
    }

    public shouldRefreshOnLocaleChange(): boolean {
        this.loadOverriddenRefreshOnLocaleChangeCache();
        if (this.overriddenRefreshOnLocaleChange !== undefined) {
            return this.overriddenRefreshOnLocaleChange;
        }
        return this.refreshOnLocaleChange;
    }

    public normalize(localeCode: string): string {
        return this.localeCodeNormalizer.normalize(localeCode);
    }

    public onLocaleChanged(context: any, subscriberName: string, localeChangedCallback: LocaleChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<LocaleChangedEvent>(this, subscriberName, LocalizationEvents.LOCALE_CHANGED_EVENT, (busEvent) => {
            localeChangedCallback?.call(context, busEvent.data);
        });
    }

    public isLocaleChangeAllowed(): boolean {
        if (!this.tenant.getContext()?.behaviour?.languageSelector?.disableIfInteractionIsDisabled) {
            return true;
        }
        return this.actionInteractionService.isInteractionAllowed({
            code: VirtualActions.LOCALE_CHANGED,
            localizationCode: VirtualActions.LOCALE_CHANGED,
            type: ActionTypeEnum.EVENT,
            action: LocalizationEvents.CHANGE_LOCALE_EVENT
        });
    }

    public onLocaleChangeAllowedStateChanged(context: any, subscriberName: string, localeChangeAllowedStateChangeCallback: LocaleChangeAllowedStateChangeCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<LocaleChangeAllowedStateChangedEvent>(this, subscriberName, LocalizationEvents.LOCALE_CHANGE_ALLOWED_STATE_CHANGED_EVENT, (busEvent) => {
            localeChangeAllowedStateChangeCallback?.call(context, busEvent.data);
        });
    }

    private initDefaults(): void {
        this.clearOverriddenRefreshOnLocaleChangeCache();
        this.assignAndSortProviders();
        this.supportedLocales[LocalizationService.DEFAULT_LOCALE] = GLOBAL_LOCALES.find(locale => locale.localeCode === LocalizationService.DEFAULT_LOCALE);
        this.currentLocaleCode = LocalizationService.DEFAULT_LOCALE;
        this.defaultLocaleCode = LocalizationService.DEFAULT_LOCALE;
    }

    private assignAndSortProviders(): void {
        const unsortedProviders = this.serviceCollection.resolveAll<ILocaleProvider>(ILocaleProviderTypeName);
        const localeProviderPriorityAssigner = this.serviceCollection.resolve<ILocaleProviderPriorityAssigner>(ILocaleProviderPriorityAssignerTypeName);
        if (localeProviderPriorityAssigner) {
            try {
                this.providers = localeProviderPriorityAssigner.prioritize(unsortedProviders);
            } catch (e) {
                console.error('Failed to assign provider priorities due to an encountered exception.', e);
                this.providers = unsortedProviders;
            }
        } else {
            this.providers = unsortedProviders;
        }
    }

    private assignTenantLocalization(): void {
        const tenantLocalization = this.tenant.getContext()?.localization ?? DEFAULT_LOCALIZATION;

        this.supportDerivedEnglishLocaleCodes = !!tenantLocalization.supportDerivedEnglishLocaleCodes;
        this.refreshOnLocaleChange = !!tenantLocalization.refreshOnLocaleChange;
        this.overriddenRefreshOnLocaleChange = undefined;

        tenantLocalization.supportedLocales?.forEach(sl => {
            const normalizedLocaleCode = this.localeCodeNormalizer.normalize(sl.localeCode);
            const normalizedDefaultingLocaleCode = this.localeCodeNormalizer.normalize(sl.defaultingLocaleCode);
            this.tenantSupportedLocales[normalizedLocaleCode] = {
                regionCode: sl.regionCode,
                localeCode: normalizedLocaleCode,
                defaultingLocaleCode: normalizedDefaultingLocaleCode,
                label: sl.label
            }
        });

        this.tenantDefaultLocaleCode = this.localeCodeNormalizer.normalize(tenantLocalization.defaultLocaleCode);
        this.defaultLocaleCode = !this.isLocaleCodeSupported(this.tenantDefaultLocaleCode) ? DEFAULT_LOCALIZATION.defaultLocaleCode : this.tenantDefaultLocaleCode;
        this.currentLocaleCode = this.defaultLocaleCode;

        this.loadRoutesWithPageRefreshOnLocaleChange(tenantLocalization.routesWithPageRefreshOnLocaleChange || []);
    }

    private assignSupportedLocales(): void {
        Object.getOwnPropertyNames(this.tenantSupportedLocales).flatMap(tsl => {
            const normalizedLocaleCode = this.localeCodeNormalizer.normalize(tsl);
            return GLOBAL_LOCALES.filter(gl =>
                this.localeCodeNormalizer.normalize(gl.localeCode) === normalizedLocaleCode ||
                (this.supportDerivedEnglishLocaleCodes && this.localeCodeNormalizer.normalize(gl.defaultingLocaleCode) === normalizedLocaleCode));
        }).forEach(locale => {
            const tenantLocale = this.tenantSupportedLocales[locale.localeCode]
            if (tenantLocale?.label) {
                locale.label = tenantLocale.label;
            }
            this.supportedLocales[locale.localeCode] = locale;
        });
    }

    private isLocaleSupportedInternal(localeCode: string): boolean {
        return this.supportedLocales.hasOwnProperty(localeCode);
    }

    private bindEvents(): void {
        this.eventBus.registerBroadcast<ChangeLocaleEvent>(this, LocalizationServiceTypeName, LocalizationService.CROSS_TAB_CHANGE_LOCALE_EVENT, (event) => this.handleChangeLocaleEvent(event, false));
        this.eventBus.registerBroadcast<ChangeLocaleEvent>(this, LocalizationServiceTypeName, LocalizationEvents.CHANGE_LOCALE_EVENT, (event) => this.handleChangeLocaleEvent(event, true));

        const callback = this.onPossibleInteractionGateChange.bind(this);
        let handler = undefined;
        this.navigationAndInteractionGateEventsReceiver.onPossibleGateChange(this, LocalizationServiceTypeName, () => {
            if (handler) {
                clearTimeout(handler);
            }
            handler = setTimeout(() => {
                if (handler) {
                    clearTimeout(handler);
                }
                callback();
            }, 100)
        });
    }

    private dispatchEvents(event: LocaleChangedEvent, notifyCrossTabs: boolean = false): void {
        this.window.dispatchEvent(new LegacyLocaleChangedEvent(LocalizationEvents.LOCALE_CHANGED_EVENT, event));
        this.eventBus.dispatchBroadcast<LocaleChangedEvent>(LocalizationServiceTypeName, LocalizationEvents.LOCALE_CHANGED_EVENT, event);
        if (notifyCrossTabs) {
            this.eventBus.dispatch<ChangeLocaleEvent>(LocalizationServiceTypeName, EventType.BROADCAST, LocalizationService.CROSS_TAB_CHANGE_LOCALE_EVENT, {
                broadcasterId: LocalizationService.BROADCASTER_ID,
                localeCode: event.currentLocaleCode
            }, undefined, undefined, true, false);
        }
    }

    private handleChangeLocaleEvent(event: IEvent<ChangeLocaleEvent>, notifyCrossTabs: boolean = true): void {
        const changeLocaleEvent = event?.data;
        if (!changeLocaleEvent) {
            return;
        }
        if (changeLocaleEvent.broadcasterId === LocalizationService.BROADCASTER_ID) {
            return;
        }
        this.changeLocaleCodeInternal(event.data.localeCode, notifyCrossTabs, true, notifyCrossTabs, true);
    }

    private getCurrentLocaleCodeFromProviders(override: boolean = false): string {
        let currentLocaleCode = undefined;
        try {
            for (let i = 0; i < this.providers.length; i++) {
                const provider = this.providers[i];
                const localeCode = provider.getLocaleCode();
                if (localeCode) {
                    const normalizedLocaleCode = this.localeCodeNormalizer.normalize(localeCode);
                    if (override || this.isLocaleSupportedInternal(normalizedLocaleCode)) {
                        currentLocaleCode = normalizedLocaleCode;
                        console.debug(`Current locale code (${currentLocaleCode}) has been provided by '${provider.getProviderCode()}' locale provider.`)
                        break;
                    }
                }
            }
            if (!currentLocaleCode) {
                currentLocaleCode = this.defaultLocaleCode;
                console.debug(`Current locale code (${currentLocaleCode}) has been provided by the tenant default locale configuration.`)
            }
        } catch (e) {
            currentLocaleCode = this.defaultLocaleCode;
            console.error('Something wrong happened while trying get the current locale.', e);
            console.debug(`Current locale code (${this.defaultLocaleCode}) has been provided by the exception handling.`)
        }
        return currentLocaleCode;
    }

    private changeLocaleCodeInternal(localeCode: string, store: boolean = true, notify: boolean = true, notifyCrossTabs: boolean = true, pageRefreshAllowed: boolean = true, force: boolean = false): boolean {
        try {
            if (!localeCode) {
                return false;
            }

            const normalizedLocale = this.localeCodeNormalizer.normalize(localeCode);
            if (!force && this.currentLocaleCode === normalizedLocale) {
                return false;
            }

            if (!force && !this.isLocaleSupportedInternal(normalizedLocale)) {
                return false;
            }

            let locale = this.supportedLocales[normalizedLocale];
            if (!locale) {
                if (force) {
                    locale = {
                        regionCode: 'UNKNOWN',
                        localeCode: normalizedLocale,
                        defaultingLocaleCode: undefined,
                        label: normalizedLocale
                    };
                    this.supportedLocales[locale.localeCode] = locale;
                } else {
                    return false;
                }
            }

            localeCode = locale.defaultingLocaleCode ?? locale.localeCode ?? localeCode;

            if (store) {
                this.providers.forEach(provider => {
                    if (typeof provider['store'] != 'function') {
                        return;
                    }
                    try {
                        (provider as unknown as ILocaleStore)?.store(localeCode);
                    } catch (e) {
                        console.warn(`Failed to store new locale code using '${provider.getProviderCode()}'.`, e);
                    }
                });
            }

            const localeChangedEvent: LocaleChangedEvent = {
                previousLocaleCode: this.currentLocaleCode,
                currentLocaleCode: locale.localeCode,
                usedLocaleCode: locale.defaultingLocaleCode ?? locale.localeCode
            };

            this.currentLocaleCode = localeCode;

            if (notify) {
                this.dispatchEvents(localeChangedEvent, notifyCrossTabs);
            }

            if (pageRefreshAllowed &&
                (this.isRouteMatchedWithAnyRoutesWithPageRefreshOnLocaleChange(this.window.location.pathname) ||
                    this.shouldRefreshOnLocaleChange())) {
                this.window.setTimeout(() => {
                    this.window.location.reload();
                }, 100);
            }

            return true;
        } catch (e) {
            console.error(`Failed to change locale to '${localeCode}' due to an encountered exception.`, e);
            return false;
        }
    }

    private clearOverriddenRefreshOnLocaleChangeCache(): void {
        this.overriddenRefreshOnLocaleChange = undefined;
        this.window.localStorage.removeItem(LocalizationService.OVERRIDDEN_REFRESH_ON_LOCALE_CHANGE_STORE_KEY);
    }

    private loadOverriddenRefreshOnLocaleChangeCache(): void {
        const value = this.window.localStorage.getItem(LocalizationService.OVERRIDDEN_REFRESH_ON_LOCALE_CHANGE_STORE_KEY);
        this.overriddenRefreshOnLocaleChange = value === undefined || value === null ? undefined : value === 'true';
    }

    private storeOverriddenRefreshOnLocaleChangeCache(): void {
        if (this.overriddenRefreshOnLocaleChange === undefined) {
            this.clearOverriddenRefreshOnLocaleChangeCache();
            return;
        }
        this.window.localStorage.setItem(LocalizationService.OVERRIDDEN_REFRESH_ON_LOCALE_CHANGE_STORE_KEY, this.overriddenRefreshOnLocaleChange.toString());
    }

    private triggerLocaleChangeAllowedStateChanged(allowed: boolean): void {
        this.eventBus.dispatchBroadcast<LocaleChangeAllowedStateChangedEvent>(LocalizationServiceTypeName, LocalizationEvents.LOCALE_CHANGE_ALLOWED_STATE_CHANGED_EVENT, {
            allowed: allowed
        });
    }

    private onPossibleInteractionGateChange(): void {
        if (!this.tenant.getContext()?.behaviour?.languageSelector?.disableIfInteractionIsDisabled) {
            return;
        }
        const allowed = this.isLocaleChangeAllowed();
        if (this.localeChangeAllowed != allowed) {
            this.triggerLocaleChangeAllowedStateChanged(allowed);
            this.localeChangeAllowed = allowed;
        }
    }

    private loadRoutesWithPageRefreshOnLocaleChange(routes: string[]): void {
        while (this.routesWithPageRefreshOnLocaleChange.length > 0) {
            this.routesWithPageRefreshOnLocaleChange.pop();
        }
        routes.forEach(route => {
            this.routesWithPageRefreshOnLocaleChange.push(route);
        });
    }

    private isRouteMatchedWithAnyRoutesWithPageRefreshOnLocaleChange(route: string): boolean {
        return this.routesWithPageRefreshOnLocaleChange.some(template => this.matchesRoute(route, template));
    }

    private matchesRoute(route: string, template: string): boolean {
        try {
            const routeTemplate = template.replaceAll('*', '(.*)');
            const expression = pathToRegexp(routeTemplate, undefined, {end: true});
            return expression.test(route);
        } catch (e) {
            console.debug(`Failed to match route '${route}' with template '${template}'.`, e);
            return false;
        }
    }
}
