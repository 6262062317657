/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IBannerAlertsInteractionHandler} from "./banner-alerts-interaction-handler.interface";

export class BannerAlertsInteractionHandler implements IBannerAlertsInteractionHandler {
    private readonly context: any;

    public onPreviousCallback: () => Promise<void>;
    public onNextCallback: () => Promise<void>;
    public onDismissCallback: (bannerAlertId: string) => Promise<void>;
    public onActionCallback: (bannerAlertId: string, actionCode: string) => Promise<void>;
    public onRefreshCallback: (bannerAlertId: string) => Promise<void>;

    public constructor(context: any) {
        this.context = context;
    }

    public async handlePrevious(): Promise<void> {
        if (this.onPreviousCallback) {
            await this.onPreviousCallback.call(this.context);
        }
    }

    public async handleNext(): Promise<void> {
        if (this.onNextCallback) {
            await this.onNextCallback.call(this.context);
        }
    }

    public async handleDismiss(bannerAlertId: string, timeoutDismissal?: boolean): Promise<void> {
        if (this.onDismissCallback) {
            await this.onDismissCallback.call(this.context, bannerAlertId, timeoutDismissal);
        }
    }

    public async handleAction(bannerAlertId: string, actionCode: string) {
        if (this.onActionCallback) {
            await this.onActionCallback.call(this.context, bannerAlertId, actionCode);
        }
    }

    public async handleRefresh(bannerAlertId: string): Promise<void> {
        if (this.onRefreshCallback) {
            await this.onRefreshCallback.call(this.context, bannerAlertId);
        }
    }
}
