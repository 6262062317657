/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ITypedServiceCollection} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {MicroFrontendTypeEnum} from "sirius-platform-support-library/models/micro-frontends/micro-frontend-type.enum";
import {IMicroFrontendLoaderFactory} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loader-factory.interface";
import {
    IMicroFrontendLoader,
    IMicroFrontendLoaderTypeName
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loader.interface";

export const MicroFrontendLoaderFactoryTypeName = 'MicroFrontendLoaderFactory';

export class MicroFrontendLoaderFactory implements IMicroFrontendLoaderFactory {
    private readonly serviceCollection: ITypedServiceCollection;

    constructor(serviceCollection: ITypedServiceCollection) {
        this.serviceCollection = serviceCollection;
    }

    public get(microFrontendType: MicroFrontendTypeEnum): IMicroFrontendLoader {
        const loaders = this.serviceCollection.resolveAll<IMicroFrontendLoader>(IMicroFrontendLoaderTypeName, DependencyType.PRIVATE);
        return loaders.find(loader => loader.supportsMicroFrontendType(microFrontendType));
    }
}
