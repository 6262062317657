/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const ToastTemplate = `
<div class="toast__icon"  role="heading" aria-hidden="true">
        {{iconPlaceholder}}
</div>
<div class="toast__text"  role="heading" tabindex="0" aria-label="{{localizedText}}">
    {{localizedText}}
</div>
{{dismissButtonPlaceholder}}
`;
