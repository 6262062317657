/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {RequestedToast, ShownToast} from "sirius-platform-support-library/shared/toasts/toast";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IToastsService,
    ToastDismissedCallback,
    ToastShownCallback
} from "sirius-platform-support-library/shared/toasts/toasts-service.interface";
import {
    ToastDismissedEvent,
    ToastsEvents,
    ToastShownEvent
} from "sirius-platform-support-library/shared/toasts/toast-events";
import {ToastsConstants} from "sirius-platform-support-library/shared/toasts/toasts.constants";
import {ToastsConfig} from "sirius-platform-support-library/tenants/tenant-context";
import {RenderableToast} from "./renderable-toast";
import {ToastsDomHandler} from "./toasts-dom.handler";
import {
    IThemingHandlersManager
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";

export const ToastsServiceTypeName = 'ToastsService';


export class ToastsService implements IToastsService, IAfterPlatformReadyInit {
    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly localizationService: ILocalizationService;
    private readonly eventBus: IEventBus;
    private readonly serviceCollection: IServiceCollection;
    private readonly domHandler: ToastsDomHandler;
    private readonly config: ToastsConfig = {
        defaultAutoDismiss: 5,
        location: 'right',
        maximumDisplayedToasts: 5,
        pauseOnHover: true
    };

    private constructor(
        window: Window,
        document: Document,
        tenant: ITenant,
        localizationService: ILocalizationService,
        eventBus: IEventBus,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.localizationService = localizationService;
        this.eventBus = eventBus;
        this.serviceCollection = serviceCollection;
        this.tenant = tenant;

        this.config = Object.assign({}, this.config, this.tenant.getContext()?.behaviour?.toasts);

        this.domHandler = new ToastsDomHandler(
            window,
            document,
            styleSheetsBasedThemeHandler,
            themingHandlersManager,
            this.config,
            this.dispatchShownEvent.bind(this),
            this.dispatchDismissEvent.bind(this)
        );
    }

    public static build(
        window: Window,
        document: Document,
        tenant: ITenant,
        localizationService: ILocalizationService,
        eventBus: IEventBus,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
        serviceCollection: IServiceCollection
    ): ToastsService {
        let instance = ObjectUtility.getFromObjectPath<ToastsService>(ToastsConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new ToastsService(
                window,
                document,
                tenant,
                localizationService,
                eventBus,
                styleSheetsBasedThemeHandler,
                themingHandlersManager,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(ToastsConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IToastsService {
        return ObjectUtility.getFromObjectPath<IToastsService>(ToastsConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        await this.domHandler.init();
        this.bind();
    }

    public async show(context: any, toast: RequestedToast): Promise<ShownToast> {
        if (!context) {
            throw new Error('Please provide a valid context');
        }

        const renderableToast = new RenderableToast(toast, context, this.localizationService, this.serviceCollection, this.dismissById.bind(this), this.config.defaultAutoDismiss);

        await renderableToast.localize();
        await this.domHandler.show(renderableToast);

        return renderableToast.toShownToast();
    }

    public async dismiss(toast: ShownToast): Promise<void> {
        return await this.dismissById(toast.id);
    }

    public async dismissById(toastId: string): Promise<void> {
        return await this.domHandler.dismissById(toastId);
    }

    public onToastShown(context: any, subscriberName: string, toastShownCallback: ToastShownCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<ToastShownEvent>(this, subscriberName, ToastsEvents.TOAST_SHOWN, (busEvent) => {
            toastShownCallback?.call(context, busEvent.data);
        });
    }

    public onToastDismissed(context: any, subscriberName: string, toastDismissedCallback: ToastDismissedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<ToastDismissedEvent>(this, subscriberName, ToastsEvents.TOAST_DISMISSED, (busEvent) => {
            toastDismissedCallback?.call(context, busEvent.data);
        });
    }

    private dispatchDismissEvent(toast: RenderableToast) {
        this.eventBus.dispatchBroadcast('ToastService', ToastsEvents.TOAST_DISMISSED, {toast: toast.toShownToast()});
    }

    private dispatchShownEvent(toast: RenderableToast) {
        this.eventBus.dispatchBroadcast('ToastService', ToastsEvents.TOAST_SHOWN, {toast: toast.toShownToast});
    }

    private bind(): void {
        this.localizationService.onLocaleChanged(this, 'ToastService', async () => {
            await this.domHandler.updateAll();
        });
    }
}
