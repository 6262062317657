/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    LocaleCodeNormalizer
} from "sirius-platform-support-library/shared/localization/normalizers/locale-code-normalizer";
import {ILocaleProvider} from "sirius-platform-support-library/shared/localization/providers/locale-provider.interface";

export const BrowserLanguageLocaleProviderTypeName = 'BrowserLanguageLocaleProvider';

export class BrowserLanguageLocaleProvider implements ILocaleProvider {
    public static readonly CODE = 'BROWSER_LANGUAGE';

    private static readonly BROWSER_LANGUAGE_PROPERTY_KEYS = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

    private readonly window: Window;
    private readonly localeCodeNormalizer: LocaleCodeNormalizer;

    constructor(window: Window) {
        this.window = window;
        this.localeCodeNormalizer = new LocaleCodeNormalizer();
    }

    public getProviderCode(): string {
        return BrowserLanguageLocaleProvider.CODE;
    }

    public getLocaleCode(): string {
        const browserLanguage = this.getFirstBrowserLanguage();
        return this.localeCodeNormalizer.normalize(browserLanguage);
    }

    private getFirstBrowserLanguage(): string | undefined {
        const navigator = this.window.navigator;
        let language = undefined;
        if (navigator.languages?.length) {
            for (let i = 0; i < navigator.languages.length; i++) {
                language = navigator.languages[i];
                if (language && language.length && language.match("[a-z]{2}-[A-Z]{2}$")) {
                    return language;
                }
            }
        }
        for (let i = 0; i < BrowserLanguageLocaleProvider.BROWSER_LANGUAGE_PROPERTY_KEYS.length; i++) {
            language = navigator[BrowserLanguageLocaleProvider.BROWSER_LANGUAGE_PROPERTY_KEYS[i]];
            if (language && language.length) {
                return language;
            }
        }
        return undefined;
    }
}
