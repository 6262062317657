/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const DialogTemplate = `
    <div class="modal">
        <dialog class="modal-dialog fadeDown" role="dialog" open tabindex="0">
            {{dialogContent}}
        </dialog>
    </div>
`
