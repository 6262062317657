/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    PossibleGateChangedEvents
} from "sirius-platform-support-library/shared/browser-events/possible-gate-changed.events";
import {
    IPossibleGateChangedEventsReceiver,
    PossibleGateChangeCallback
} from "sirius-platform-support-library/shared/browser-events/possible-gate-changed.events-receiver";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";

export const NavigationAndInteractionGateEventsReceiverTypeName = 'NavigationAndInteractionGateEventsReceiver';

export class NavigationAndInteractionGateEventsReceiver implements IPossibleGateChangedEventsReceiver {
    private readonly eventBus: IEventBus;

    public constructor(
        eventBus: IEventBus
    ) {
        this.eventBus = eventBus;
    }

    public onPossibleGateChange(context: any, subscriberName: string, possibleGateChangeCallback: PossibleGateChangeCallback): IEventSubscription {
        const localContext: any = {};
        localContext.navigationGateChangedSubscription = this.eventBus.registerBroadcast<any>(this, subscriberName, PossibleGateChangedEvents.NAVIGATION_POSSIBLE_GATE_CHANGE, () => {
            possibleGateChangeCallback?.call(context);
        });
        localContext.actionInteractionGateChangedSubscription = this.eventBus.registerBroadcast<any>(this, subscriberName, PossibleGateChangedEvents.ACTION_INTERACTION_POSSIBLE_GATE_CHANGE, () => {
            possibleGateChangeCallback?.call(context);
        });
        return {
            subscriberContext: localContext,
            subscriberName: subscriberName,
            unregister: (() => {
                try {
                    localContext?.navigationGateChangedSubscription?.unregister();
                } catch (e) {
                    console.warn(e);
                }
                try {
                    localContext?.actionInteractionGateChangedSubscription?.unregister();
                } catch (e) {
                    console.warn(e);
                }
            }).bind(localContext)
        } as any as IEventSubscription;
    }
}
