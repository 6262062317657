/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {ContextualAction} from "sirius-platform-support-library/models/common";
import {
    IPlatformActionInteractionGateHandler,
    IPlatformActionInteractionGateHandlerTypeName
} from "./platform/platform-action-interaction-gate-handler.interface";
import {
    ActionInteractionGateHandlerRefreshRequestedCallback,
    IActionInteractionGateHandler,
    IActionInteractionGateHandlerTypeName
} from "sirius-platform-support-library/shared/browser-events/action-interaction-gate-handler.interface";
import {
    IPlatformActionInteractionEventsReceiver,
    IPlatformActionInteractionEventsReceiverTypeName
} from "./platform/platform-action-interaction-events-receiver.interface";
import {
    IActionInteractionEventsReceiver,
    IActionInteractionEventsReceiverTypeName
} from "sirius-platform-support-library/shared/browser-events/action-interaction-events-receiver.interface";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";

export const AggregatedActionInteractionGateHandlerTypeName = 'AggregatedActionInteractionGateHandler';

export class AggregatedActionInteractionGateHandler implements IBeforePlatformReadyInit {
    private readonly tenant: ITenant;
    private readonly serviceCollection: IServiceCollection;

    private actionInteractionGateHandlerRefreshRequestedCallback?: ActionInteractionGateHandlerRefreshRequestedCallback;

    public constructor(
        tenant: ITenant,
        serviceCollection: IServiceCollection
    ) {
        this.tenant = tenant;
        this.serviceCollection = serviceCollection;
    }

    public async init(): Promise<void> {
        this.getPlatformActionInteractionGateHandlers().forEach((handler: IPlatformActionInteractionGateHandler) => {
            if (handler.onActionInteractionGateHandlerRefreshRequested) {
                handler.onActionInteractionGateHandlerRefreshRequested(this.refreshActionInteractionGateHandler.bind(this));
            }
        });
        this.getTenantActionInteractionGateHandlers().forEach((handler: IActionInteractionGateHandler) => {
            if (handler.onActionInteractionGateHandlerRefreshRequested) {
                handler.onActionInteractionGateHandlerRefreshRequested(this.refreshActionInteractionGateHandler.bind(this));
            }
        });
    }

    public onBeforeInteraction(action: ContextualAction): Promise<void> | void {
        this.getPlatformActionInteractionEventsReceivers().forEach(handler => {
            try {
                const result = handler.onBeforeInteraction(action);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantActionInteractionEventsReceivers().forEach(handler => {
            try {
                const result = handler.onBeforeInteraction(action);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public isInteractionAllowed(action: ContextualAction): boolean {
        const platformIsInteractionAllowed = this.getPlatformActionInteractionGateHandlers()
            .map(handler => {
                try {
                    return handler.isInteractionAllowed(action);
                } catch (e) {
                    console.error(e);
                    return true;
                }
            })
            .reduce((acc, val) => acc && val, true);
        const tenantIsInteractionAllowed = this.getTenantActionInteractionGateHandlers()
            .map(handler => {
                try {
                    return handler.isInteractionAllowed(action);
                } catch (e) {
                    console.error(e);
                    return true;
                }
            })
            .reduce((acc, val) => acc && val, true);
        return platformIsInteractionAllowed && tenantIsInteractionAllowed;
    }

    public onAfterInteraction(action: ContextualAction): Promise<void> | void {
        this.getPlatformActionInteractionEventsReceivers().forEach(handler => {
            try {
                const result = handler.onAfterInteraction(action);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantActionInteractionEventsReceivers().forEach(handler => {
            try {
                const result = handler.onAfterInteraction(action);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public async onInteractionAllowed(action: ContextualAction): Promise<void> {
        this.getPlatformActionInteractionGateHandlers().forEach(handler => {
            try {
                const result = handler.onInteractionAllowed(action);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantActionInteractionGateHandlers().forEach(handler => {
            try {
                const result = handler.onInteractionAllowed(action);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public async onInteractionBlocked(action: ContextualAction): Promise<void> {
        this.getPlatformActionInteractionGateHandlers().forEach(handler => {
            try {
                const result = handler.onInteractionBlocked(action);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantActionInteractionGateHandlers().forEach(handler => {
            try {
                const result = handler.onInteractionBlocked(action);
                // @ts-ignore
                if (result?.catch) {
                    result.catch(e => {
                        console.error(e);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public onActionInteractionGateHandlerRefreshRequested?(callback: ActionInteractionGateHandlerRefreshRequestedCallback): void {
        this.actionInteractionGateHandlerRefreshRequestedCallback = callback;
    }

    private getPlatformActionInteractionGateHandlers(): IPlatformActionInteractionGateHandler[] {
        return this.serviceCollection.resolveAll<IPlatformActionInteractionGateHandler>(IPlatformActionInteractionGateHandlerTypeName);
    }

    private getTenantActionInteractionGateHandlers(): IActionInteractionGateHandler[] {
        return this.serviceCollection.resolveAll<IActionInteractionGateHandler>(IActionInteractionGateHandlerTypeName);
    }

    private getPlatformActionInteractionEventsReceivers(): IPlatformActionInteractionEventsReceiver[] {
        return this.serviceCollection.resolveAll<IPlatformActionInteractionEventsReceiver>(IPlatformActionInteractionEventsReceiverTypeName);
    }

    private getTenantActionInteractionEventsReceivers(): IActionInteractionEventsReceiver[] {
        return this.serviceCollection.resolveAll<IActionInteractionEventsReceiver>(IActionInteractionEventsReceiverTypeName);
    }

    private async refreshActionInteractionGateHandler(): Promise<void> {
        try {
            if (this.actionInteractionGateHandlerRefreshRequestedCallback) {
                await this.actionInteractionGateHandlerRefreshRequestedCallback();
            }
        } catch (e) {
            console.error(e);
        }
    }
}
