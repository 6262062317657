/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    PageCustomizationsOptions
} from "sirius-platform-support-library/models/page-customizations/page-customization.models";
import {
    StandardFeatures
} from "sirius-platform-support-library/shared/micro-frontends/features/standard-features.constants";

export class Customizations {
    public static readonly DEFAULTS: PageCustomizationsOptions = {
        bannerAlerts: {
            visible: true
        },
        toasts: {
            visible: true
        },
        header: {
            visible: true,
            features: [
                {
                    code: StandardFeatures.HEADER_LOGO,
                    enabled: true
                },
                {
                    code: StandardFeatures.HEADER_TITLE,
                    enabled: true,
                },
                {
                    code: StandardFeatures.HEADER_MENU,
                    enabled: true,
                },
                {
                    code: StandardFeatures.HEADER_WIDGETS,
                    enabled: true,
                }
            ]
        },
        navbar: {
            visible: true,
            features: [
                {
                    code: StandardFeatures.NAVBAR_HOME,
                    enabled: true
                },
                {
                    code: StandardFeatures.NAVBAR_MENU,
                    enabled: true,
                },
                {
                    code: StandardFeatures.NAVBAR_WIDGETS,
                    enabled: true,
                }
            ]
        },
        quicknav: {
            visible: true,
            features: [
                {
                    code: StandardFeatures.QUICKNAV_MENU,
                    enabled: true
                }
            ]
        },
        content: {
            backgroundColor: 'transparent'
        },
        footer: {
            visible: true,
            features: [
                {
                    code: StandardFeatures.FOOTER_TITLE,
                    enabled: true
                },
                {
                    code: StandardFeatures.FOOTER_DESCRIPTION,
                    enabled: true,
                },
                {
                    code: StandardFeatures.FOOTER_MENU,
                    enabled: true,
                },
                {
                    code: StandardFeatures.FOOTER_WIDGETS,
                    enabled: true,
                },
                {
                    code: StandardFeatures.FOOTER_COPYRIGHT_LINK,
                    enabled: true,
                },
                {
                    code: StandardFeatures.FOOTER_CONTACT_US_LINK,
                    enabled: true,
                },
                {
                    code: StandardFeatures.FOOTER_ABOUT_DELL_LINK,
                    enabled: true,
                }
                ,
                {
                    code: StandardFeatures.FOOTER_PRIVACY_LINK,
                    enabled: true,
                }
            ]
        }
    };
}
