/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const runtimeEnvironments = {
    LOCAL: {},
    SNAPSHOT: {},
    SELF_HOSTED: {},
    DEV: {},
    STAGING: {},
    INTEGRATION: {},
    PRODUCTION: {
        production: true
    }
}
