/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IMobileViewHandler,
    MobileMenuVisibilityChangedCallback,
    MobileViewHandlerCallback
} from "sirius-platform-support-library/shared/mobile-view/mobile-view-handler.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    MobileViewChangedEvent
} from "sirius-platform-support-library/shared/mobile-view/events/mobile-view-changed.event"
import {MobileViewEvents} from "sirius-platform-support-library/shared/mobile-view/events/mobile-view-events"
import {
    MobileMenuVisibilityChangedEvent
} from "sirius-platform-support-library/shared/mobile-view/events/mobile-menu-visibility-changed.event";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {MobileViewConstants} from "sirius-platform-support-library/shared/mobile-view/mobile-view.constants";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";

export const MobileViewHandlerTypeName = 'MobileViewHandler';

export class MobileViewHandler implements IMobileViewHandler {
    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly eventBus: IEventBus;

    private mobileViewEnabled: boolean;

    public constructor(
        window: Window,
        tenant: ITenant,
        event: IEventBus
    ) {
        this.window = window;
        this.tenant = tenant;
        this.eventBus = event;
    }

    public getTabletBreakpoint(): number {
        try {
            const tabletBreakpoint = this.tenant?.getContext()?.site?.tabletBreakpoint;
            if (ObjectUtility.isDefined(tabletBreakpoint)) {
                if (tabletBreakpoint < 0) {
                    return MobileViewConstants.DEFAULT_TABLET_BREAKPOINT;
                }
                return tabletBreakpoint;
            }
            return MobileViewConstants.DEFAULT_TABLET_BREAKPOINT;
        } catch (e) {
            console.error(e);
            return MobileViewConstants.DEFAULT_TABLET_BREAKPOINT;
        }
    }

    public setMobileView(mobileViewEnabled: boolean): void {
        this.mobileViewEnabled = mobileViewEnabled;
        this.eventBus.dispatchBroadcast<MobileViewChangedEvent>('MobileViewHandler', MobileViewEvents.MOBILE_VIEW_EVENT, {mobileViewEnabled: this.mobileViewEnabled}, undefined, false);
    }

    public isMobileView(): boolean {
        return this.mobileViewEnabled;
    }

    public onMobileViewStateChanged(context: any, subscriberName: string, callback: MobileViewHandlerCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<MobileViewChangedEvent>(this, subscriberName, MobileViewEvents.MOBILE_VIEW_EVENT, (event) => {
            callback?.call(context, !!event?.data?.mobileViewEnabled);
        });
    }

    public triggerMobileMenuVisibilityChangedEvent(broadcasterId: string, mobileMenuVisible: boolean): void {
        this.window.dispatchEvent(new CustomEvent(MobileViewEvents.NATIVE_MOBILE_MENU_VISIBLE_EVENT, {
            detail: {
                broadcasterId: broadcasterId,
                mobileMenuVisible: mobileMenuVisible
            } as MobileMenuVisibilityChangedEvent
        }));
    }

    public onMobileMenuVisibilityChanged(context: any, broadcasterId: string, callback: MobileMenuVisibilityChangedCallback): void {
        this.window.addEventListener(MobileViewEvents.NATIVE_MOBILE_MENU_VISIBLE_EVENT, (event: CustomEvent) => {
            const evt = event?.detail as MobileMenuVisibilityChangedEvent;
            if (broadcasterId == evt?.broadcasterId) {
                return;
            }
            callback.call(context, evt?.mobileMenuVisible);
        });
    }
}
