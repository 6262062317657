/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IActionInteractionEventsReceiver
} from "sirius-platform-support-library/shared/browser-events/action-interaction-events-receiver.interface";

export const IPlatformActionInteractionEventsReceiverTypeName = 'IPlatformActionInteractionEventsReceiver';

export interface IPlatformActionInteractionEventsReceiver extends IActionInteractionEventsReceiver {
}
