/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {SystemJs} from "../../dynamic-imports/system-js";
import {ITenantContextLoader} from "./tenant-context.loader.interface";
import {
    ITenantContextProviderModule
} from "sirius-shell-support-library/tenants/providers/tenant-context-provider-factory.interface";
import {TenantContext} from "sirius-platform-support-library/tenants/tenant-context";
import {ITenantContextProvider} from "sirius-platform-support-library/shared/tenants/tenant-context-provider";
import {RuntimeConfigurationLoader} from "../../runtime-configuration/runtime-configuration.loader";
import {retry} from "sirius-platform-support-library/utilities/promises/retry-promise";
import {
    IDependencyContainer
} from "sirius-platform-support-library/dependency-injection/generic/dependency-container.interface";
import {TenantContextLoaderError} from "../tenant-context-loader.error";
import {TENANT_CONTEXT_PROVIDER_BUNDLE_URL_PLACEHOLDER} from "../../../sirius.config.constants";

export const TenantContextLoaderTypeName = 'TenantContextLoader';

export class TenantContextLoader implements ITenantContextLoader {
    private static preloadingPromises: Record<string, Promise<any>> = {};
    private static preloadedTenantContextProviderModules: Record<string, ITenantContextProviderModule> = {};

    private readonly runtimeConfigurationLoader: RuntimeConfigurationLoader;
    private readonly systemJs: SystemJs;
    private readonly dependencyContainer: IDependencyContainer;

    constructor(
        runtimeConfigurationLoader: RuntimeConfigurationLoader,
        systemJs: SystemJs,
        dependencyContainer: IDependencyContainer
    ) {
        this.runtimeConfigurationLoader = runtimeConfigurationLoader;
        this.systemJs = systemJs;
        this.dependencyContainer = dependencyContainer;
    }

    public static async preload(systemJs: SystemJs, runtimeConfigurationLoader?: RuntimeConfigurationLoader, failFast: boolean = true): Promise<void> {
        const siriusConfig = (window as any)?.sirius?.config;
        const configuratorProvidedUrl = runtimeConfigurationLoader?.getTenantContextProviderConfigurator()?.getTenantContextProviderBundleUrl();
        const siriusConfigProvidedUrl = siriusConfig?.tenantContextProviderUrl !== TENANT_CONTEXT_PROVIDER_BUNDLE_URL_PLACEHOLDER ? siriusConfig?.tenantContextProviderUrl : undefined;
        const url = configuratorProvidedUrl ?? siriusConfigProvidedUrl;
        if (!url || TenantContextLoader.preloadingPromises[url] || TenantContextLoader.preloadedTenantContextProviderModules[url]) {
            return;
        }

        const localPreload = async (url: string) => {
            if (!TenantContextLoader.preloadedTenantContextProviderModules[url]) {
                try {
                    TenantContextLoader.preloadedTenantContextProviderModules[url] = await TenantContextLoader.getTenantContextProviderModule(systemJs, url, failFast);
                } catch (e) {
                    console.warn('Failed to preload tenant context provider module', e, url);
                    TenantContextLoader.preloadedTenantContextProviderModules[url] = undefined;
                }
            }

            return TenantContextLoader.preloadedTenantContextProviderModules[url];
        };

        if (!TenantContextLoader.preloadingPromises[url]) {
            TenantContextLoader.preloadingPromises[url] = localPreload(url);
        }
    }

    public static async getTenantContextProviderModule(systemJs: SystemJs, url: string, failFast: boolean = false): Promise<ITenantContextProviderModule | undefined> {
        return failFast ? await systemJs.import<ITenantContextProviderModule>(url) : await retry(() => systemJs.import<ITenantContextProviderModule>(url), {
            retries: 3,
            backoff: "EXPONENTIAL",
            maxBackOff: 5 * 1000
        });
    }

    public async load(failFast: boolean = false): Promise<TenantContext> {
        const configurator = this.runtimeConfigurationLoader?.getTenantContextProviderConfigurator();
        const defaultedDueToUnavailability = configurator?.defaultedDueToUnavailability;
        try {
            const tenantContextProviderUrl = configurator?.getTenantContextProviderBundleUrl();
            if (!tenantContextProviderUrl) {
                throw new Error('Could not get tenant context due to misconfiguration of the shell.');
            }

            let tenantContextProviderModule: ITenantContextProviderModule = TenantContextLoader.preloadedTenantContextProviderModules[tenantContextProviderUrl];
            if (!tenantContextProviderModule) {
                const loadPromise = TenantContextLoader.preloadingPromises[tenantContextProviderUrl];
                if (loadPromise) {
                    tenantContextProviderModule = await loadPromise;
                }
                if (!tenantContextProviderModule) {
                    tenantContextProviderModule = await TenantContextLoader.getTenantContextProviderModule(this.systemJs, tenantContextProviderUrl, failFast);
                }
            }

            if (!tenantContextProviderModule?.getProvider) {
                tenantContextProviderModule = await TenantContextLoader.getTenantContextProviderModule(this.systemJs, tenantContextProviderUrl, failFast);
            }

            const provider = await this.getTenantContextProvider(tenantContextProviderModule, failFast);
            if (!provider) {
                throw new Error(`Could not get the ITenantContextProvider from the module in ${tenantContextProviderUrl}.`);
            }

            return await provider.getContext();
        } catch (e) {
            throw new TenantContextLoaderError(e?.message ?? e, defaultedDueToUnavailability);
        }
    }

    private async getTenantContextProvider(tenantContextProviderModule: ITenantContextProviderModule, failFast: boolean = false): Promise<ITenantContextProvider> {
        if (!tenantContextProviderModule) {
            throw new Error(`Missing tenant context provider module.`);
        }
        const provider = tenantContextProviderModule.getProvider(this.dependencyContainer);
        if (!provider) {
            throw new Error(`Could not get the ITenantContextProvider from the module in given module`);
        }
        return provider;
    }
}
