/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {EventCallback} from "sirius-platform-support-library/shared/event-bus/event-callback";
import {IEvent} from "sirius-platform-support-library/shared/event-bus/event.interface";

import {v4 as uuidv4} from 'uuid';

export const CrossTabEventBusBridgeTypeName = 'CrossTabEventBusBridge';

export class CrossTabEventBusBridge {
    private static readonly BROADCASTER_ID_PLACEHOLDER = '{broadcaster_id}';
    private static readonly LOCAL_STORAGE_KEY_EVENT_BUS_PREFIX = `sirius_cross_tab_event_`;
    private static readonly LOCAL_STORAGE_KEY_TEMPLATE = `${CrossTabEventBusBridge.LOCAL_STORAGE_KEY_EVENT_BUS_PREFIX}${CrossTabEventBusBridge.BROADCASTER_ID_PLACEHOLDER}_broadcast`;

    private static readonly BROADCASTER_ID = uuidv4().toLowerCase();

    private window: Window;

    private callback: EventCallback<any>;

    constructor(window: Window) {
        this.window = window;
        this.bindBridge();
    }

    public dispatch<T>(busEvent: IEvent<T>): void {
        const customEvent = {
            broadcasterId: CrossTabEventBusBridge.BROADCASTER_ID,
            data: busEvent
        };
        const eventKey = this.getEventKey();
        this.window.localStorage.setItem(eventKey, JSON.stringify(customEvent));
        this.window.localStorage.removeItem(eventKey);
    }

    public subscribe<T>(callback: EventCallback<T>): void {
        this.callback = callback;
    }

    private bindBridge(): void {
        this.window.addEventListener('storage', this.handleMessage.bind(this));
    }

    private handleMessage(storageEvent: StorageEvent): void {
        try {
            const eventKey = storageEvent?.key?.toLowerCase();
            if (!eventKey?.startsWith(this.getEventBusEventKeyPrefix())) {
                return;
            }

            if (this.shouldIgnoreEvent(eventKey)) {
                return;
            }

            const parsedEvent = JSON.parse(storageEvent.newValue) as any;
            const broadcasterId = parsedEvent?.broadcasterId?.toLowerCase();
            if (broadcasterId === CrossTabEventBusBridge.BROADCASTER_ID) {
                return;
            }

            if (parsedEvent?.data) {
                parsedEvent.data.crossTabPropagation = false;
            }

            const busEvent = parsedEvent?.data as IEvent<any>;
            if (busEvent && this.callback) {
                this.callback(busEvent);
            }
        } catch (e) {
            console.error(e);
        }
    }

    private getEventKey() {
        return CrossTabEventBusBridge.LOCAL_STORAGE_KEY_TEMPLATE
            .replaceAll(CrossTabEventBusBridge.BROADCASTER_ID_PLACEHOLDER, CrossTabEventBusBridge.BROADCASTER_ID)
            .toLowerCase();
    }

    private getEventBusEventKeyPrefix() {
        return CrossTabEventBusBridge.LOCAL_STORAGE_KEY_EVENT_BUS_PREFIX
            .toLowerCase();
    }

    private shouldIgnoreEvent(eventKey: string): boolean {
        const segments = eventKey.replaceAll(CrossTabEventBusBridge.LOCAL_STORAGE_KEY_EVENT_BUS_PREFIX, '').split('_');
        if (segments.length !== 2) {
            return true;
        }
        const broadcasterId = segments[0]?.toLowerCase();
        if (!broadcasterId || broadcasterId === CrossTabEventBusBridge.BROADCASTER_ID) {
            return true;
        }
        return false;
    }
}
