/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    DEFAULT_PLACEHOLDERS_PRIORITIES,
    IPlaceholderReplacerPriorityAssigner
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholder-replacer-priority-assigner.interface";
import {
    IPlaceholderReplacer
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholder-replacer.interface";

export const DefaultPlaceholderReplacerPriorityAssignerTypeName = 'DefaultPlaceholderReplacerPriorityAssigner';

export class DefaultPlaceholderReplacerPriorityAssigner implements IPlaceholderReplacerPriorityAssigner {
    private priorityMap: Record<string, number> = DEFAULT_PLACEHOLDERS_PRIORITIES;

    public configure(priorityMap: Record<string, number>): void {
        if (priorityMap) {
            this.priorityMap = priorityMap;
        }
    }

    public reset(): void {
        this.priorityMap = DEFAULT_PLACEHOLDERS_PRIORITIES;
    }

    public prioritize(providers: IPlaceholderReplacer[]): IPlaceholderReplacer[] {

        return providers.sort((a, b) => {
            const aPriority = this.priorityMap[a.getPlaceholderReplacerCode()] ?? 0;
            const bPriority = this.priorityMap[b.getPlaceholderReplacerCode()] ?? 0;
            if (aPriority < bPriority) {
                return 1;
            }
            if (aPriority > bPriority) {
                return -1;
            }
            return 0;
        });
    }
}
