/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {Event} from "./event";
import {IEventsSubscriptionsCollection} from "./events-subscriptions-collection.interface";
import {IEventSubscriptionHandler} from "./event-subscription-handler.interface";
import {EventSubscriptionHandler} from "./event-subscription.handler";
import {EventSubscription} from "./event-subscription";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {EventType} from "sirius-platform-support-library/shared/event-bus/event-type";
import {EventCallback} from "sirius-platform-support-library/shared/event-bus/event-callback";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {CrossTabEventBusBridge} from "./cross-tabs/cross-tab-event-bus-bridge";
import {IEvent} from "sirius-platform-support-library/shared/event-bus/event.interface";
import {IBeforePlatformReadyInit} from "../../initializer/before-platform-ready-init.interface";
import {EventOptions} from "sirius-platform-support-library/shared/event-bus/event.options";

export const EventBusTypeName = 'EventBus';

export class EventBus implements IEventBus, IBeforePlatformReadyInit {
    public static readonly GLOBAL_KEY = 'window.sirius.shared.eventBus';
    private readonly crossTabEventBusBridge: CrossTabEventBusBridge;
    private readonly eventsSubscriptions: IEventsSubscriptionsCollection;
    private readonly subscriptionHandler: IEventSubscriptionHandler;
    private readonly eventsQueue: Record<string, Record<string, Event<any>>> = {};
    private readonly worker: Worker;

    private constructor(crossTabEventBusBridge: CrossTabEventBusBridge) {
        this.crossTabEventBusBridge = crossTabEventBusBridge;
        this.eventsSubscriptions = {};
        this.subscriptionHandler = new EventSubscriptionHandler(this.eventsSubscriptions);
        this.worker = new Worker("/runtime/event-bus-worker.js");
    }

    public static build(crossTabEventBusBridge: CrossTabEventBusBridge): EventBus {
        let instance = ObjectUtility.getFromObjectPath<EventBus>(EventBus.GLOBAL_KEY);
        if (!instance) {
            instance = new EventBus(crossTabEventBusBridge);
            ObjectUtility.assignOnObjectPath(EventBus.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): EventBus {
        return ObjectUtility.getFromObjectPath<EventBus>(EventBus.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        try {
            this.crossTabEventBusBridge.subscribe<any>(this.propagateCrossTabEvent.bind(this));
        } catch (e) {
            console.error(e);
        }
    }

    public dispatchDirect<T>(dispatcherName: string, subscriberName: string, event: string, data?: T, channel?: string, crossTabPropagation?: boolean, loggingDisable?: boolean, ttl?: number): void {
        this.dispatch<T>(dispatcherName, EventType.DIRECT, event, data, subscriberName, channel, crossTabPropagation, loggingDisable, ttl);
    }

    public dispatchDirectWithOptions<T>(dispatcherName: string, subscriberName: string, event: string, data?: T, options?: EventOptions): void {
        this.dispatchWithOptions<T>(dispatcherName, EventType.DIRECT, event, data, subscriberName, options);
    }

    public dispatchBroadcast<T>(dispatcherName: string, event: string, data?: T, channel?: string, crossTabPropagation?: boolean, loggingDisable?: boolean, ttl?: number): void {
        this.dispatch<T>(dispatcherName, EventType.BROADCAST, event, data, undefined, channel, crossTabPropagation, loggingDisable, ttl);
    }

    public dispatchBroadcastWithOptions<T>(dispatcherName: string, event: string, data?: T, options?: EventOptions): void {
        this.dispatchWithOptions<T>(dispatcherName, EventType.BROADCAST, event, data, undefined, options);
    }

    public dispatch<T>(dispatcherName: string, eventType: EventType, event: string, data?: T, subscriberName?: string, channel?: string, crossTabPropagation?: boolean, loggingDisable?: boolean, ttl?: number): void {
        try {
            if (!eventType) {
                throw new Error('Please provide a valid event type');
            }
            const wrappedEvent = new Event<T>(this.dequeueEvent.bind(this), dispatcherName, eventType, event, data, subscriberName, channel, crossTabPropagation, loggingDisable, ttl);
            const subscriptions = this.getSuitableSubscriptions<T>(wrappedEvent, this.eventsSubscriptions);
            subscriptions.forEach((subscription) => {
                try {
                    if (!this.isSuitableSubscriber<T>(wrappedEvent, subscription)) {
                        return;
                    }
                    if (subscription.callback) {
                        const result = subscription.callback.apply(subscription.subscriberContext, [wrappedEvent]);
                        if (result?.catch) {
                            result.catch(e => {
                                console.error('Something went wrong while consuming event.', e);
                            });
                        }
                    }
                } catch (e) {
                    console.warn(`Encountered an exception while executing subscriber (${subscription.id}/${subscription.subscriberName}) callback.`);
                    console.error(e);
                }
            });
            if (!loggingDisable) {
                const clonedWrappedEvent = ObjectUtility.deepClone(wrappedEvent.toSerializableEvent());
                this.worker.postMessage(clonedWrappedEvent);
            }
            if (wrappedEvent.crossTabPropagation) {
                this.crossTabEventBusBridge.dispatch<T>(wrappedEvent);
            }
            if (!subscriptions.length) {
                this.enqueueEvent(wrappedEvent);
            }
        } catch (e) {
            console.error(e);
        }
    }

    public dispatchWithOptions<T>(dispatcherName: string, eventType: EventType, event: string, data?: T, subscriberName?: string, options?: EventOptions): void {
        this.dispatch<T>(dispatcherName, EventType.DIRECT, event, data, subscriberName, options?.channel, options?.crossTabPropagation, options?.loggingDisable, options?.ttl);
    }

    public registerDirect<T>(subscriberContext: any, subscriberName: string, event: string, callback: EventCallback<T>, channel?: string): IEventSubscription {
        return this.register<T>(subscriberContext, subscriberName, EventType.DIRECT, event, callback, channel);
    }

    public registerBroadcast<T>(subscriberContext: any, subscriberName: string, event: string, callback: EventCallback<T>, channel?: string): IEventSubscription {
        return this.register<T>(subscriberContext, subscriberName, EventType.BROADCAST, event, callback, channel);
    }

    public register<T>(subscriberContext: any, subscriberName: string, eventType: EventType, event: string, callback: EventCallback<T>, channel?: string): IEventSubscription {
        const context = subscriberContext || this;
        if (eventType === EventType.DIRECT && !subscriberName) {
            throw new Error('Please provide a subscriber name if eventType is set to DIRECT');
        }
        if (!this.eventsSubscriptions[event]) {
            this.eventsSubscriptions[event] = {};
        }
        const subscription = new EventSubscription<T>(context, subscriberName, eventType, event, callback, this.subscriptionHandler, channel);
        this.eventsSubscriptions[event][subscription.id] = subscription;
        this.processQueuedEvents(subscription);
        return subscription;
    }

    private getSuitableSubscriptions<T>(event: Event<T>, eventSubscriptions: IEventsSubscriptionsCollection): Array<IEventSubscription> {
        const eventName = event.event;
        return Object.keys(eventSubscriptions)
            .filter(subscribedEventName => this.matchesEvent(eventName, subscribedEventName))
            .map(eventName => eventSubscriptions[eventName])
            .flatMap((eventSubscription => {
                return Object.keys(eventSubscription).map(subscriberId => {
                    return eventSubscription[subscriberId]
                });
            }));
    }

    private isSuitableSubscriber<T>(event: Event<T>, eventSubscription: IEventSubscription): boolean {
        if (event.channel != eventSubscription.channel) {
            return false;
        }
        if (event.type === eventSubscription.eventType) {
            if (event.type === EventType.DIRECT && event.subscriberName !== eventSubscription.subscriberName) {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }

    private matchesEvent(eventName: string, subscribedEventName: string) {
        if (eventName === subscribedEventName) {
            return true;
        } else if (subscribedEventName.indexOf('*') >= 0) {
            subscribedEventName = subscribedEventName.replaceAll(/\*\*/, '([^.]+.?)\\b');
            subscribedEventName = subscribedEventName.replaceAll(/\*/g, '[^.]+');

            const match = eventName.match(subscribedEventName);
            if (match && eventName === match[0]) {
                return true;
            }
        }
        return false;
    }

    private propagateCrossTabEvent<T>(event: IEvent<T>): void {
        this.dispatch<any>(event.dispatcherName, event.type, event.event, event.data, event.subscriberName, event.channel, false, true);
    }

    private findQueuedEvents(eventName: string): Event<any>[] {
        return [];
    }

    private enqueueEvent(event: Event<any>): void {
        if (!event?.event || !event?.id) {
            return;
        }
        if (!this.eventsQueue.hasOwnProperty(event.event)) {
            this.eventsQueue[event.event] = {};
        }
        this.eventsQueue[event.event][event.id] = event;
        event.startTtlTimer();
    }

    private dequeueEvent(event: Event<any>, reason?: string): void {
        if (!event?.event || !event?.id) {
            return;
        }
        event.stopTtlTimer();
        const eventName = event.event;
        const eventId = event.id;
        const queue = this.eventsQueue[eventName];
        if (!queue) {
            return;
        }
        const queuedEvent = queue[eventId];
        if (!queuedEvent) {
            return;
        }
        delete this.eventsQueue[eventName][eventId];
        if (!Object.keys(this.eventsQueue[event.event]).length) {
            delete this.eventsQueue[event.event];
        }
    }

    private processQueuedEvents(subscription: IEventSubscription): void {
        if (!subscription.callback) {
            return;
        }
        const suitableQueuedEventNames = Object.keys(this.eventsQueue)
            .filter(eventName => this.matchesEvent(eventName, subscription.event));
        if (!suitableQueuedEventNames.length) {
            return;
        }
        const queuedEvents = suitableQueuedEventNames
            .flatMap(eventName => Object.keys(this.eventsQueue[eventName])
                .map(eventId => this.eventsQueue[eventName][eventId]));
        if (!queuedEvents.length) {
            return;
        }
        queuedEvents
            .filter(event => this.isSuitableSubscriber(event, subscription))
            .forEach(event => {
                try {
                    const result = subscription.callback.apply(subscription.subscriberContext, [event]);
                    if (result?.catch) {
                        result.catch(e => {
                            console.error('Something went wrong while consuming event.', e);
                        });
                    }
                } catch (e) {
                    console.warn(`Encountered an exception while executing subscriber (${subscription.id}/${subscription.subscriberName}) callback.`);
                    console.error(e);
                } finally {
                    this.dequeueEvent(event, 'PROCESSED');
                }
            });
    }
}
