/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {SectionMarkerCodes} from "sirius-platform-support-library/shared/page-customizations/section-marker-codes";
import {BaseSectionPageCustomizationHandler} from "./base-section-page-customization.handler";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export const ContentSectionPageCustomizationHandlerTypeName = 'ContentSectionPageCustomizationHandler';

export class ContentSectionPageCustomizationHandler extends BaseSectionPageCustomizationHandler {
    private readonly tenant: ITenant;

    public constructor(
        window: Window,
        tenant: ITenant
    ) {
        super(window);
        this.tenant = tenant;
    }

    public getSectionMarkerCode(): string {
        return SectionMarkerCodes.CONTENT_MARKER;
    }

    public getDefaultBackgroundColor(): string {
       return this.tenant?.getContext()?.site?.backgroundColor ?? 'var(--uxd-neutral-white, #FFFFFF)';
    }
}
