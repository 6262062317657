/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {TenantContext} from "sirius-platform-support-library/tenants/tenant-context";

export const IApplicationTypeName = 'IApplication';

export interface IApplication {
    bootstrap(tenantContext: TenantContext): Promise<void>;

    start(): Promise<void>;
}
