/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {css, html, LitElement} from "lit";
import {property, state} from "lit/decorators.js";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {when} from 'lit/directives/when.js';
import {TranslationService} from 'sirius-platform-support-library/shared/localization/translations/translation.service';
import {
    SupportedTranslationsLoaders
} from "sirius-platform-support-library/shared/localization/translations/loaders/translations-loaders.constants";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";
import {createNodeElement} from "sirius-platform-support-library/utilities/dom-helper";

export class ProgressIndicatorNonBlockingComponent extends LitElement {
    public static readonly CUSTOM_ELEMENT_NAME = 'sirius-progress-indicator-non-blocking';

    public static styles = css`
      :host {
        width: 100%;
      }

      .progress-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-start;
      }

      .border {
        border-bottom: 1px solid var(--uxd-neutral-granite,#d2d2d2);
      }

      .si-progress {
        max-height: 8px !important;
        min-height: 8px !important;
        margin: 0;
        padding: 0;
      }

      .progress-info {
        display: flex;
        flex: 1;
        align-self: stretch;
        justify-content: space-between;
      }

      .progress-info span {
        margin: 0 !important;
        padding: 4px 32px !important;
        font-size: 14px !important;
        line-height: 16px !important;
      }
      
      progress::-webkit-progress-value {
       background-color: var(--uxd-primary-dellblue, #0672CB) !important;
      }
      
      .progress {
        background-color: var(--progress-indicator, #0672CB) !important;
      }

      *:focus-visible {
        outline: none !important;
        box-shadow: 0 0 0 0 var(--uxd-neutral-white,#FFFFFF), 0 0 0 2px var(--uxd-primary-midnight,#00468b) !important;
        border-radius: 2px;
        text-decoration: underline;
        box-sizing: border-box;
      }`;

    @property({type: Number})
    progress = 0;
    @property({type: String, attribute: 'title-text'})
    title = "";
    @property({type: Boolean})
    deterministic = false;
    @property({type: Boolean})
    border = false;
    @property({type: Boolean, attribute: 'show-elapsed-time'})
    showElapsedTime = false;
    @property({type: Boolean, attribute: 'show-progress'})
    showProgress = false;
    @property({type: String})
    txtcolor = "var(--uxd-neutral-carbon, #444444)";
    @property({type: String})
    bgcolor = "transparent"
    @state()
    private elapsedTimeToken = "ELAPSED_TIME";
    private translationService?: TranslationService;
    private startTime: number;
    private elapsedTimeText = "";
    private timerId: any | undefined
    private elapsedTime = 0;

    private referenceStyleSheet?: Element | ParentNode;

    public constructor() {
        super();
        this.startTime = Date.now();
    }

    public async connectedCallback() {
        super.connectedCallback();

        if (this.showElapsedTime) {
            this.startTimer();
        }

        this.translationService = new TranslationService({
            publicPath: {
                provide: () => {
                    // @ts-ignore
                    return __webpack_public_path__;
                }
            },
            type: SupportedTranslationsLoaders.REMOTE_ASSETS,
            options: {
                prependPublicPath: false,
                baseUrl: '/assets/i18n/',
                extension: '.json'
            },
            localizedResources: {}
        });

        this.translationService.onLanguageChanged(this, this.translateProperties);

        await this.translateProperties();

        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'progress-indicators',
                suffix: 'non-blocking'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-dark-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        if (this.referenceStyleSheet) {
            await styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(this.referenceStyleSheet, DomInjectionType.AFTER);

            const themingHandlersManager = SharedDependencyContainer.getInstance().getServiceCollection().resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
            if (themingHandlersManager) {
                themingHandlersManager.registerThemeHandler(styleSheetsBasedThemeHandler);
                await themingHandlersManager.attach(ThemedComponent.SHELL_PROGRESS_INDICATORS_NONBLOCKING, this.referenceStyleSheet, DomInjectionType.AFTER);
            }
        }
    }

    public async disconnectedCallback() {
        super.disconnectedCallback();

        if (this.timerId) {
            this.clearTimer();
        }

        this.clearTimer()
    }

    public render() {
        return html`
            <div class="progress-wrapper ${this.border ? 'border' : ''}">
                <div class="progress ${this.deterministic ? '' : 'loop'} si-progress">
                    <progress max="100" value="${this.progress}"></progress>
                </div>
                <div class="progress-info" style="${this.getColors()}">
                    <span role="heading" tabindex="0"
                          aria-label="${this.title}">${this.title}</span>
                    <span role="heading" tabindex="${this.showProgress ? '0' : ''}"
                          aria-label="${this.showProgress ? `${this.progress}%` : ''}">${when(!!this.showProgress, () => html`${this.progress}%`, () => html``)}</span>
                    <span role="heading" tabindex="0"
                          aria-label="${this.timeTemplate()}">${unsafeHTML(this.timeTemplate())}</span>
                </div>
            </div>`
    }

    protected createRenderRoot() {
        const root = super.createRenderRoot();

        this.referenceStyleSheet = root.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/libs/@clr/ui/clr-ui.min.css'
        }));

        return root;
    }

    private async translateProperties(): Promise<void> {
        this.elapsedTimeText = await this.translationService.translate(this.elapsedTimeToken);
    }

    private timeTemplate() {
        if (this.showElapsedTime) {
            const elapsed = new Date(this.elapsedTime);
            const hh = elapsed.getUTCHours() < 10 ? `0${elapsed.getUTCHours()}` : elapsed.getUTCHours();
            const mm = elapsed.getMinutes() < 10 ? `0${elapsed.getMinutes()}` : elapsed.getMinutes();
            const ss = elapsed.getSeconds() < 10 ? `0${elapsed.getSeconds()}` : elapsed.getSeconds();
            return `${this.elapsedTimeText} ${hh}:${mm}:${ss}`
        }
        return ``;
    }

    private getColors() {
        return `background-color: ${this.bgcolor}; color: ${this.txtcolor}`;
    }

    private startTimer() {
        this.timerId = setInterval(() => {
            const now = Date.now();
            this.elapsedTime = (now - this.startTime);
            this.requestUpdate();
        }, 1000);
    }

    private clearTimer() {
        clearInterval(this.timerId);
    }
}

if (!customElements.get(ProgressIndicatorNonBlockingComponent.CUSTOM_ELEMENT_NAME)) {
    customElements.define(ProgressIndicatorNonBlockingComponent.CUSTOM_ELEMENT_NAME, ProgressIndicatorNonBlockingComponent);
}
