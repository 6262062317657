/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ButtonStyle, ButtonType, CustomizableButton} from "sirius-platform-support-library/models/common";
import {IButtonStyleProcessor} from "../button-style.processor.interface";

export const ClarityButtonStyleProcessorTypeName = 'ClarityButtonStyleProcessor';

export class ClarityButtonStyleProcessor implements IButtonStyleProcessor {
    private static readonly CLARITY_BUTTON_TYPE_PLACEHOLDER = '{{clarityButtonType}}';
    private static readonly CLARITY_BUTTON_STYLE_PLACEHOLDER = '{{clarityButtonStyle}}';
    private static readonly CLARITY_CLASS_DEFAULT_TEMPLATE = 'btn{{clarityButtonStyle}}{{clarityButtonType}}';
    private static readonly CLARITY_CLASS_LINK_TEMPLATE = 'btn-link';

    private static readonly CLARITY_BUTTON_TYPE_MAP: Record<ButtonType, string> = {
        [ButtonType.UNSET]: '',
        [ButtonType.FLAT]: '-link',
        [ButtonType.OUTLINE]: '-outline',
        [ButtonType.SOLID]: ''
    }

    private static readonly CLARITY_BUTTON_STYLE_MAP: Record<ButtonStyle, string> = {
        [ButtonStyle.UNSET]: '',
        [ButtonStyle.ERROR]: '-danger',
        [ButtonStyle.WARNING]: '-warning',
        [ButtonStyle.SUCCESS]: '-success',
        [ButtonStyle.INFO]: '-info',
        [ButtonStyle.PRIMARY]: '-primary'
    }

    public getClassesByProperties(buttonType?: ButtonType, buttonStyle?: ButtonStyle): string[] {
        const clarityButtonType = ClarityButtonStyleProcessor.CLARITY_BUTTON_TYPE_MAP[buttonType] ?? '';
        const clarityButtonStyle = ClarityButtonStyleProcessor.CLARITY_BUTTON_STYLE_MAP[buttonStyle] ?? '';

        let template = ClarityButtonStyleProcessor.CLARITY_CLASS_DEFAULT_TEMPLATE;
        if (buttonType === ButtonType.FLAT) {
            template = ClarityButtonStyleProcessor.CLARITY_CLASS_LINK_TEMPLATE;
        }

        const clazz = template
            .replaceAll(ClarityButtonStyleProcessor.CLARITY_BUTTON_TYPE_PLACEHOLDER, clarityButtonType)
            .replaceAll(ClarityButtonStyleProcessor.CLARITY_BUTTON_STYLE_PLACEHOLDER, clarityButtonStyle);

        return [
            clazz
        ];
    }

    public getClasses(customizableButton: CustomizableButton): string[] {
        return this.getClassesByProperties(customizableButton.buttonType, customizableButton.buttonStyle);
    }
}
