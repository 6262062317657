/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {LocalizedResources} from "sirius-platform-support-library/models/common";
import {
    ProgressIndicatorBlockingComponent
} from "../../site/components/progress-indicator/blocking/progress-indicator.blocking.web-component";
import {IStandaloneProgressIndicatorsService} from "./standalone-progress-indicators-service.interface";
import {StandaloneProgressIndicatorsOptions} from "./standalone-progress-indicators.options";

export const StandaloneProgressIndicatorsServiceTypeName = 'StandaloneProgressIndicatorsService';

export class StandaloneProgressIndicatorsService implements IStandaloneProgressIndicatorsService {
    private readonly containerSelector: string;
    private readonly window: Window;
    private readonly document: Document;

    private readonly defaultOptions: StandaloneProgressIndicatorsOptions;

    public constructor(
        containerSelector: string,
        window: Window,
        document: Document
    ) {
        this.containerSelector = containerSelector;
        this.window = window;
        this.document = document;
        this.defaultOptions = {
            showTitle: true,
            titleLocalizationCode: 'PROGRESS_INDICATORS.SITE_PRELOAD.MESSAGE'
        };
    }

    public isShown(): boolean {
        const containerElement = this.document.querySelector(this.containerSelector);
        const progressIndicatorsElement = containerElement?.querySelector('.progress-indicators');
        return !!progressIndicatorsElement?.querySelector('sirius-progress-indicator-blocking[splash]')
    }

    public show(text: string, backgroundColor?: string, textColor?: string): void {
        try {
            if (this.isShown()) {
                return;
            }
            const containerElement = this.document.querySelector(this.containerSelector);
            if (!containerElement) {
                return;
            }
            const progressIndicatorsElement = this.document.createElement("div");
            progressIndicatorsElement.classList.add('progress-indicators');
            containerElement.appendChild(progressIndicatorsElement);
            const progressIndicatorElement = this.createProgressIndicatorElement(text, backgroundColor, textColor);
            progressIndicatorsElement.appendChild(progressIndicatorElement);
            containerElement.classList.add('active');
        } catch (e) {
            console.error(e);
        }
    }

    public showWithOptions(options: StandaloneProgressIndicatorsOptions, backgroundColor?: string, textColor?: string): void {
        const opts = Object.assign({}, this.defaultOptions, options ?? {});
        if (!opts.showTitle) {
            opts.titleLocalizationCode = undefined;
        }
        this.storeLocalizedResources(opts?.localizedResources);
        this.show(opts?.titleLocalizationCode, backgroundColor, textColor);
    }

    public hide(): void {
        try {
            if (!this.isShown()) {
                return;
            }
            const containerElement = this.document.querySelector(this.containerSelector);
            if (!containerElement) {
                return;
            }
            containerElement.classList.remove('active');
            const progressIndicatorsElement = containerElement.querySelector('.progress-indicators');
            if (progressIndicatorsElement) {
                progressIndicatorsElement.innerHTML = '';
            }
        } catch (e) {
            console.error(e);
        }
    }

    private createProgressIndicatorElement(text: string, backgroundColor?: string, textColor?: string): Element {
        const progressIndicatorElement = this.document.createElement(ProgressIndicatorBlockingComponent.CUSTOM_ELEMENT_NAME);
        progressIndicatorElement.setAttribute('progress', '0');
        progressIndicatorElement.setAttribute('title-text', text ?? '');
        progressIndicatorElement.setAttribute('bgcolor', backgroundColor || 'var(--uxd-neutral-quartz, #efefef)');
        progressIndicatorElement.setAttribute('txtcolor', textColor || '#808080');
        progressIndicatorElement.setAttribute('splash', String(true));
        return progressIndicatorElement;
    }

    private storeLocalizedResources(localizedResources: LocalizedResources): void {
        if (localizedResources) {
            this.window.sessionStorage.setItem('sirius.standalone-progress-indicators.localized-resources', JSON.stringify(localizedResources));
        }
    }
}
