/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    PushedBannerAlert,
    RequestedBannerAlert
} from "sirius-platform-support-library/shared/banner-alerts/banner-alert";
import {BannerAlertType} from "sirius-platform-support-library/shared/banner-alerts/banner-alert-type";
import {
    ITranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations-cache.interface";
import {
    LocalizableResource,
    TranslationsRequestedCallback
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {RenderableBannerAlertAction} from "./renderable-banner-alert-action";
import {ILocalizable} from "sirius-platform-support-library/shared/localization/localizable.interface";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    LocalizableResourceTranslationService
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource-translation.service";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";
import {BannerAlertTemplate} from "./templates/banner-alert.template";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {IBannerAlertsItemInteractionHandler} from "./handlers/banner-alerts-dismissal-interaction-handler.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";

import {v4 as uuidv4} from 'uuid';
import {RoutingUtilities} from "sirius-platform-support-library/utilities/routing-utilities";
import {IActionHandler} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {IButtonStyleProcessor} from "../common-ui/buttons/button-style.processor.interface";

export class RenderableBannerAlert implements PushedBannerAlert, LocalizableResource, ILocalizable {
    public static readonly HOURS_PLACEHOLDER = '{{hh}}';
    public static readonly MINUTES_PLACEHOLDER = '{{mm}}';
    public static readonly SECONDS_PLACEHOLDER = '{{ss}}';

    public static readonly ICON_RESOURCE_PLACEHOLDER = '{{iconResource}}';
    public static readonly LOCALIZED_TEXT_PLACEHOLDER = '{{localizedText}}';
    public static readonly ACTIONS_PLACEHOLDER = '{{actions}}';
    public readonly context: any;
    public id: string;
    public timestamp: number;
    public owner?: string;
    public reference?: string;
    public type: BannerAlertType;
    public iconResource: string;
    public highPriority: boolean;
    public actions: RenderableBannerAlertAction[];
    public routeOverriddenHighPriority?: string[];
    public dismissButtonVisible: boolean;
    public dismissIn?: number;
    public dismissHandler?: NodeJS.Timeout;
    public localizationCode: string;
    public localizedText: string;
    public localizedResources?: Record<string, any>;
    public namespace?: string;
    public onTranslationsRequested?: TranslationsRequestedCallback;
    private readonly interactionHandler: IBannerAlertsItemInteractionHandler;
    private readonly translationsCache: ITranslationsCache;
    private readonly translationService: ITranslationService;
    private readonly actionHandler: IActionHandler;
    private readonly buttonStyleProcessor: IButtonStyleProcessor;
    private readonly window: Window;

    private countDownTimer?: number;
    private countDownTimerHandler?: NodeJS.Timeout;


    public constructor(
        window: Window,
        alert: RequestedBannerAlert,
        context: any,
        interactionHandler: IBannerAlertsItemInteractionHandler,
        localizationService: ILocalizationService,
        actionHandler: IActionHandler,
        buttonStyleProcessor: IButtonStyleProcessor,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.context = context;

        this.localizedResources = alert.localizedResources || {};
        this.onTranslationsRequested = alert.onTranslationsRequested;

        this.interactionHandler = interactionHandler;
        this.translationsCache = new TranslationsCache(context, this);
        this.translationService = new LocalizableResourceTranslationService(
            localizationService,
            this.translationsCache,
            serviceCollection
        );
        this.actionHandler = actionHandler;
        this.buttonStyleProcessor = buttonStyleProcessor;

        this.id = (alert.namespace && alert.id) ? `${alert.namespace}::${alert.id}` : uuidv4().toLowerCase();
        this.timestamp = Math.round(Date.now() / 1000);
        this.owner = alert.owner;
        this.reference = alert.reference;
        this.type = alert.type;
        this.iconResource = alert.iconResource;
        this.highPriority = alert.highPriority;
        this.actions = (alert.actions || [])
            .filter(action => action)
            .map(action => new RenderableBannerAlertAction(action, this.translationService, this.actionHandler, this.buttonStyleProcessor));

        this.dismissButtonVisible = !ObjectUtility.isDefined(alert.dismissButtonVisible) ? true : alert.dismissButtonVisible;
        this.dismissIn = alert.dismissIn;

        this.localizationCode = alert.localizationCode;
        this.localizedText = this.localizationCode;

        this.namespace = alert.namespace;

        this.routeOverriddenHighPriority = alert.routeOverriddenHighPriority;

        if (ObjectUtility.isDefined(this.dismissIn)) {
            this.countDownTimer = this.dismissIn * 1000;
            this.countDownTimerHandler = setInterval(async () => {
                this.countDownTimer -= 1000;
                await this.localize();
                await this.interactionHandler.handleRefresh(this.id);
            }, 1000);

            this.dismissHandler = setTimeout(async () => {
                this.dismissHandler = undefined;
                await this.interactionHandler.handleDismiss(this.id, true);
            }, this.dismissIn * 1000);
        }
    }

    public update(alert: PushedBannerAlert): RenderableBannerAlert {
        if (alert?.id !== this.id) {
            throw new Error('Cannot update banner alert with different id');
        }

        this.type = alert.type;
        this.iconResource = alert.iconResource;
        this.highPriority = alert.highPriority;
        this.actions = (alert.actions || [])
            .filter(action => action)
            .map(action => new RenderableBannerAlertAction(action, this.translationService, this.actionHandler, this.buttonStyleProcessor));

        this.dismissButtonVisible = !ObjectUtility.isDefined(alert.dismissButtonVisible) ? true : alert.dismissButtonVisible;

        this.localizationCode = alert.localizationCode;
        this.localizedText = this.localizationCode;

        return this;
    }

    public async localize(): Promise<void> {
        if (!this.localizationCode) {
            return;
        }
        try {
            await Promise.allSettled(this.actions.map(action => action.localize()));
            await this.localizeText();
        } catch (e) {
            console.warn('Could not localize banner alert', this, e);
        }
    }

    public getActionByCode(actionCode: string): RenderableBannerAlertAction {
        return this.actions.find(action => action.code === actionCode);
    }

    public stopCountDownTimer() {
        if (this.countDownTimerHandler) {
            clearInterval(this.countDownTimerHandler);
        }

        if (this.dismissHandler) {
            clearTimeout(this.dismissHandler);
        }
    }

    public toHtml(): string {
        const actionsHtml = this.actions.map(a => a.toHtml());
        return BannerAlertTemplate
            .replaceAll(RenderableBannerAlert.ICON_RESOURCE_PLACEHOLDER, this.iconResource)
            .replaceAll(RenderableBannerAlert.LOCALIZED_TEXT_PLACEHOLDER, this.localizedText)
            .replaceAll(RenderableBannerAlert.ACTIONS_PLACEHOLDER, actionsHtml.join('\n'));
    }

    public isHighPriority(): boolean {
        return this.highPriority || (this.routeOverriddenHighPriority || []).some(route => RoutingUtilities.routeEqual(this.window.location.pathname, route, true));
    }

    public toPushedBannerAlert(): PushedBannerAlert {
        return {
            id: this.id,
            timestamp: this.timestamp,
            owner: this.owner,
            reference: this.reference,
            type: this.type,
            localizationCode: this.localizationCode,
            iconResource: this.iconResource,
            highPriority: this.highPriority,
            dismissIn: this.dismissIn,
            actions: this.actions.map(action => action.toBannerAlertAction()),
            routeOverriddenHighPriority: this.routeOverriddenHighPriority,
            namespace: this.namespace
        }
    }

    private async localizeText(): Promise<void> {
        this.localizedText = await this.translationService.translate(this.localizationCode ?? '');

        if (ObjectUtility.isDefined(this.countDownTimer)) {
            const formattedTime = this.getFormattedTime(this.countDownTimer);

            this.localizedText = this.localizedText
                .replace(RenderableBannerAlert.HOURS_PLACEHOLDER, formattedTime.hours)
                .replace(RenderableBannerAlert.MINUTES_PLACEHOLDER, formattedTime.minutes)
                .replace(RenderableBannerAlert.SECONDS_PLACEHOLDER, formattedTime.seconds);
        }
    }

    private getFormattedTime(countDownTimer: number): { hours: string; minutes: string; seconds: string } {
        // Convert milliseconds to hours, minutes, and seconds
        let seconds = Math.floor(countDownTimer / 1000);
        let minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        // Adjust minutes and seconds to remain within their ranges
        minutes = minutes % 60;
        seconds = seconds % 60;

        // Pad function to ensure each time component is at least two digits
        const pad = (num: number) => (num < 10 ? `0${num}` : `${num}`);

        // Return the hours, minutes, and seconds as padded strings
        return {
            hours: pad(hours),
            minutes: pad(minutes),
            seconds: pad(seconds)
        };
    }
}
