/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {LocalizedMenuItem} from "sirius-platform-support-library/shared/dynamic-menu/localized-menu-item";
import {ILocalizable} from "sirius-platform-support-library/shared/localization/localizable.interface";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";
import {Action, BreakpointOptions, MenuItem, MenuItemButtonStyle} from "sirius-platform-support-library/models/common";
import {DynamicMenuItem} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-item";
import {v4 as uuidv4} from "uuid";
import {ActionMetadata} from "sirius-platform-support-library/shared/actions/action-metadata";

export class InternalMenuItem implements DynamicMenuItem, LocalizedMenuItem, ILocalizable {
    private readonly _parent?: DynamicMenuItem;

    private _translationService: ITranslationService;

    public localizationCode: string;
    public index?: number;
    public action?: Action;
    public id?: string;
    public code?: string;
    public iconCode?: string;
    public iconSet?: string;
    public data?: any;
    public alternativeRoutes?: string[];
    public buttonStyle?: MenuItemButtonStyle;
    public options?: BreakpointOptions | any;

    public items: DynamicMenuItem[];

    private _localizedText: string;

    public get localizedText(): string {
        return this._localizedText;
    }

    public set localizedText(value: string) {
        this._localizedText = value;
    }

    public get translationService(): ITranslationService {
        return this._translationService;
    }

    public set translationService(value: ITranslationService) {
        this._translationService = value;
    }

    public get parent(): DynamicMenuItem {
        return this._parent;
    }

    public constructor(
        item: MenuItem,
        parent?: DynamicMenuItem,
        translationService?: ITranslationService,
        id?: string
    ) {
        this.id = id ?? uuidv4().toLowerCase();
        this.code = item.code;
        this.localizationCode = item.localizationCode;
        this.index = item.index;
        this.iconCode = item.iconCode;
        this.iconSet = item.iconSet;
        this.alternativeRoutes = item.alternativeRoutes;
        this.action = item.action;
        this.data = item.data;
        this.buttonStyle = item.buttonStyle;
        this.options = item.options;

        this._parent = parent;

        this._localizedText = this.localizationCode;

        this._translationService = translationService;

        if (this.action) {
            const metadata: ActionMetadata = {
                source: 'menu-item',
                sourceId: this.id
            }
            this.action.data = Object.assign({}, this.action.data, metadata);
        }
    }

    public async localize(): Promise<void> {
        try {
            if (!this.localizationCode) {
                return;
            }
            this._localizedText = await this._translationService.translate(this.localizationCode);
        } catch (e) {
            console.warn('Could not localize menu item.', this, e);
        }
    }

    public asSerializable(): DynamicMenuItem {
        return {
            id: this.id,
            code: this.code,
            localizationCode: this.localizationCode,
            index: this.index,
            iconCode: this.iconCode,
            iconSet: this.iconSet,
            alternativeRoutes: this.alternativeRoutes,
            action: this.action,
            data: this.data,
            buttonStyle: this.buttonStyle,
            options: this.options,
            items: (this.items ?? []).map(i => (i as InternalMenuItem).asSerializable()),
            localizedText: this.localizedText
        };
    }

    public asMenuItem(): MenuItem {
        return {
            localizationCode: this.localizationCode,
            index: this.index,
            action: this.action,
            items: (this.items ?? []).map(item => (item as InternalMenuItem)?.asMenuItem() ?? undefined).filter(item => item),
            code: this.code,
            iconCode: this.iconCode,
            iconSet: this.iconSet,
            data: this.data,
            alternativeRoutes: [...(this.alternativeRoutes ?? [])],
            buttonStyle: this.buttonStyle,
            options: this.options
        };
    }

    public async clone(parent: DynamicMenuItem): Promise<DynamicMenuItem> {
        const instance = new InternalMenuItem(
            this.asMenuItem(),
            parent,
            this._translationService,
            this.id
        )

        await instance.localize();

        if (this.items?.length) {
            instance.items = [];
            for (const item of this.items) {
                const clone = await (item as InternalMenuItem)?.clone(instance);
                if (clone) {
                    instance.items.push(clone);
                }
            }
        }

        return instance;
    }
}
