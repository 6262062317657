/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {
    IThemingService,
    ThemeChangedCallback
} from "sirius-platform-support-library/shared/theming/theming-service.interface";
import {DefaultThemes, ThemingConstants} from "sirius-platform-support-library/shared/theming/theming.constants";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {
    IThemesProvider,
    IThemesProviderTypeName
} from "sirius-platform-support-library/shared/theming/providers/themes-provider.interface";
import {ThemingConfig} from "sirius-platform-support-library/models/theming/theming-config.model";
import {InternalThemeVariant} from "./models/internal-theme-variant.model";
import {RuntimeThemeVariant} from "sirius-platform-support-library/shared/theming/models/runtime-theme-variant.model";
import {
    IThemesFilter,
    IThemesFilterTypeName
} from "sirius-platform-support-library/shared/theming/filters/themes-filter.interface";
import {
    IThemeVariantSelector,
    IThemeVariantSelectorTypeName
} from "sirius-platform-support-library/shared/theming/selectors/theme-variant-selector.interface";
import _ from "lodash";
import {SelectorContainer} from "./selectors/selector.container";
import {
    IThemeVariantSelectorStore
} from "sirius-platform-support-library/shared/theming/selectors/theme-variant-selector-store.interface";
import {CurrentThemeResult} from "./selectors/current-theme.result";
import {Theme} from "sirius-platform-support-library/models/theming/theme.model";
import {ThemeVariant} from "sirius-platform-support-library/models/theming/theme-variant.model";
import {
    IThemeVariantSelectorsPriorityAssigner
} from "sirius-platform-support-library/shared/theming/selectors/theme-variant-selectors-priority-assigner.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    ThemeChangedEvent,
    ThemeChangeEvent,
    ThemingEvents
} from "sirius-platform-support-library/shared/theming/events/theming.events";
import {IEvent} from "sirius-platform-support-library/shared/event-bus/event.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IThemeVariantPropertiesCustomizer,
    IThemeVariantPropertiesCustomizerTypeName
} from "sirius-platform-support-library/shared/theming/customizers/theme-variant-properties-customizer.interface";
import {v4 as uuidv4} from "uuid";

export const ThemingServiceTypeName = 'ThemingService';

export class ThemingService implements IThemingService, IBeforePlatformReadyInit {
    private static readonly BROADCASTER_ID = uuidv4().toLowerCase();

    private readonly eventBus: IEventBus;
    private readonly selectorsPriorityAssigner: IThemeVariantSelectorsPriorityAssigner;
    private readonly serviceCollection: IServiceCollection;

    private themeChangedSubscription?: IEventSubscription = undefined;
    private themeChangeSubscription?: IEventSubscription = undefined;
    private themingConfig?: ThemingConfig = undefined;
    private readonly selectors: Record<string, SelectorContainer> = {};
    private themes: Record<string, InternalThemeVariant> = {};
    private currentThemeVariant?: RuntimeThemeVariant = undefined;

    private defaultThemeVariantDisplayFormat?: string;

    public constructor(
        eventBus: IEventBus,
        selectorsPriorityAssigner: IThemeVariantSelectorsPriorityAssigner,
        serviceCollection: IServiceCollection
    ) {
        this.eventBus = eventBus;
        this.selectorsPriorityAssigner = selectorsPriorityAssigner;
        this.serviceCollection = serviceCollection;
    }

    public static build(
        eventBus: IEventBus,
        selectorsPriorityAssigner: IThemeVariantSelectorsPriorityAssigner,
        serviceCollection: ITypedServiceCollection
    ): ThemingService {
        let instance = ObjectUtility.getFromObjectPath<ThemingService>(ThemingConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new ThemingService(
                eventBus,
                selectorsPriorityAssigner,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(ThemingConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public async preInit(): Promise<void> {
        await this.load();
        this.bind();
        await this.applyThemeVariantCustomizers();
    }

    public async init(): Promise<void> {
        await this.load();
        this.bind();
        await this.applyTenantSpecificConfig()
        await this.applyThemeVariantCustomizers();
    }

    public getAvailableThemes(): RuntimeThemeVariant[] {
        return Object.values(this.themes);
    }

    public async setCurrentThemeById(themeVariantId: string): Promise<RuntimeThemeVariant | undefined> {
        const theme = this.themes[themeVariantId];
        if (!theme) {
            return undefined;
        }
        return await this.setCurrentTheme(theme);
    }

    public async setCurrentThemeByCodes(themeCode: string, variantCode: string): Promise<RuntimeThemeVariant | undefined> {
        const theme = Object.values(this.themes).find(t => t.theme.code === themeCode && t.variant.code === variantCode);
        if (!theme) {
            return undefined;
        }
        return await this.setCurrentTheme(theme);
    }

    public async setCurrentTheme(theme: RuntimeThemeVariant): Promise<RuntimeThemeVariant | undefined> {
        return await this.internalSetCurrentTheme(theme, true, true);
    }

    public getCurrentTheme(): RuntimeThemeVariant | undefined {
        return this.currentThemeVariant;
    }

    public onThemeChanged(context: any, subscriberName: string, callback: ThemeChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<ThemeChangedEvent>(this, subscriberName, ThemingEvents.THEME_CHANGED_EVENT, (event: IEvent<ThemeChangedEvent>) => {
            callback?.call(context, event?.data);
        });
    }

    public setSelectorsPriorities(priorityMap: Record<string, number>): void {
        this.selectorsPriorityAssigner?.configure(priorityMap);
    }

    public getSelectorsPriorities(): Record<string, number> {
        return this.selectorsPriorityAssigner?.getPriorities() ?? {};
    }

    private getProviders(): IThemesProvider[] {
        return this.serviceCollection.resolveAll<IThemesProvider>(IThemesProviderTypeName);
    }

    private getFilters(): IThemesFilter[] {
        return this.serviceCollection.resolveAll<IThemesFilter>(IThemesFilterTypeName);
    }

    private getSelectors(): IThemeVariantSelector[] {
        return this.serviceCollection.resolveAll<IThemeVariantSelector>(IThemeVariantSelectorTypeName);
    }

    private getCustomizers(): IThemeVariantPropertiesCustomizer[] {
        return this.serviceCollection.resolveAll<IThemeVariantPropertiesCustomizer>(IThemeVariantPropertiesCustomizerTypeName);
    }

    private getTenant(): ITenant {
        return this.serviceCollection.resolve<ITenant>(ITenantTypeName);
    }

    private async loadSelectors(): Promise<void> {
        const callback = this.onSelectedThemeVariantChanged.bind(this);
        const selectors = this.sortSelectors(this.getSelectors());
        for (const selector of selectors) {
            try {
                const uniqueCode = selector.getUniqueCode();
                if (!uniqueCode) {
                    continue;
                }
                if (!this.selectors[uniqueCode]) {
                    this.selectors[uniqueCode] = {
                        uniqueCode: uniqueCode.toLowerCase(),
                        selector: selector,
                    };
                    if ((selector as any).setSelectedThemeVariant) {
                        this.selectors[uniqueCode].store = selector as IThemeVariantSelectorStore;
                    }
                    if (this.selectors[uniqueCode].store?.onSelectedThemeVariantChanged) {
                        await this.selectors[uniqueCode].store.onSelectedThemeVariantChanged(callback);
                    }
                }
            } catch (e) {
                console.error('Error loading selector', selector, e);
            }
        }
    }

    private sortSelectors(selectors: IThemeVariantSelector[]): IThemeVariantSelector[] {
        let sortedSelectors = selectors;
        if (this.selectorsPriorityAssigner) {
            try {
                sortedSelectors = this.selectorsPriorityAssigner.prioritize(selectors);
            } catch (e) {
                console.error('Error prioritizing selectors', e);
                sortedSelectors = selectors;
            }
        }
        return sortedSelectors;
    }

    private async load(): Promise<void> {
        await this.loadSelectors();
        this.clearThemingContext();
        this.themingConfig = await this.loadThemingConfig();
        this.themingConfig = _.cloneDeep(await this.filterThemingConfig(_.cloneDeep(this.themingConfig)));
        this.loadThemes();
        let activeThemeVariant: RuntimeThemeVariant | undefined;
        const selectionResult = await this.selectCurrentTheme(this.getAvailableThemes());
        if (selectionResult) {
            activeThemeVariant = selectionResult.themeVariant;
        } else {
            activeThemeVariant = this.getDefaultedThemeVariant(this.themingConfig, this.getAvailableThemes());
        }
        await this.internalSetCurrentTheme(activeThemeVariant, false, false, selectionResult?.selector?.getUniqueCode());
    }

    private bind(): void {
        if (!this.themeChangedSubscription) {
            this.themeChangedSubscription = this.eventBus.registerBroadcast<ThemeChangedEvent>(this, ThemingServiceTypeName, ThemingEvents.THEME_CHANGED_EVENT, async (event: IEvent<ThemeChangedEvent>) => {
                if (event.data.broadcasterId !== ThemingService.BROADCASTER_ID) {
                    await this.internalSetCurrentTheme(event.data.theme, false, false);
                }
            });
        }

        if (!this.themeChangeSubscription) {
            this.themeChangeSubscription = this.eventBus.registerBroadcast<ThemeChangeEvent>(this, ThemingServiceTypeName, ThemingEvents.THEME_CHANGE_EVENT, async (event: IEvent<ThemeChangeEvent>) => {
                await this.internalSetCurrentTheme(event.data.theme, true, true);
            });
        }
    }

    private async applyTenantSpecificConfig(): Promise<void> {
        const tenant = this.getTenant();
        const themingBehaviourConfig = tenant?.getContext()?.behaviour?.theming;
        this.defaultThemeVariantDisplayFormat = themingBehaviourConfig?.themeVariantDisplayFormat;
        Object.values(this.themes).forEach((tv) => {
            tv.setDefaultFormat(this.defaultThemeVariantDisplayFormat);
        });
        (this.currentThemeVariant as InternalThemeVariant).setDefaultFormat(this.defaultThemeVariantDisplayFormat);
    }

    private async applyThemeVariantCustomizers(): Promise<void> {
        const customizers = this.getCustomizers();
        const variants = [
            ...Object.values(this.themes),
            this.currentThemeVariant
        ];
        variants.forEach((tv) => {
            const filteredCustomizers = customizers.filter(c => c.getThemeCode() === tv.theme.code);
            if (filteredCustomizers.length) {
                filteredCustomizers.forEach(async (c) => {
                    await this.runCustomizer(c, tv);
                });
            }
        });
    }

    private async runCustomizer(customizer: IThemeVariantPropertiesCustomizer, themeVariant: RuntimeThemeVariant): Promise<void> {
        try {
            await customizer.customize(themeVariant);
            (themeVariant as InternalThemeVariant)?.setProperties(themeVariant.variant.properties);
        } catch (e) {
            console.error('Error customizing theme variant', themeVariant, e);
        }
    }

    private async loadThemingConfig(): Promise<ThemingConfig> {
        let themingConfig: ThemingConfig = {};

        const providers = this.getProviders();
        for (const provider of providers) {
            try {
                const providerThemingConfig = _.cloneDeep(await provider.getThemingConfig());
                if (!providerThemingConfig) {
                    continue;
                }

                providerThemingConfig.enabledThemes = Array.from(
                    new Set([
                        ...themingConfig.enabledThemes ?? [],
                        ...providerThemingConfig.enabledThemes ?? [],
                        ...Object.keys(providerThemingConfig.themes ?? {})
                    ])
                );

                themingConfig = _.merge({}, themingConfig, providerThemingConfig) as ThemingConfig;

                themingConfig.enabledThemes = Array.from(
                    new Set([
                        ...themingConfig.enabledThemes ?? [],
                        ...Object.keys(themingConfig.themes ?? {})
                    ])
                );

                if (!providerThemingConfig.defaultThemeCode || (providerThemingConfig.defaultThemeCode && !themingConfig.enabledThemes.some(t => t === providerThemingConfig.defaultThemeCode))) {
                    themingConfig.defaultThemeCode = themingConfig.enabledThemes.slice(-1)[0];
                } else {
                    themingConfig.defaultThemeCode = providerThemingConfig.defaultThemeCode;
                }
            } catch (e) {
                console.error('Error loading theming config from provider', provider, e);
            }
        }

        return themingConfig;
    }

    private async filterThemingConfig(themingConfig: ThemingConfig): Promise<ThemingConfig> {
        try {
            const filters = this.getFilters();

            const definedThemes = Object.keys(themingConfig.themes ?? {});
            let filteredThemes = Array.from(definedThemes);
            for (const filter of filters) {
                const backupDefinedThemes = _.cloneDeep(definedThemes);
                const backupEnabledThemes = _.cloneDeep(themingConfig.enabledThemes);
                try {
                    filteredThemes = await filter.filter(definedThemes) ?? [];
                    themingConfig.enabledThemes = await filter.filter(themingConfig.enabledThemes) ?? [];
                } catch (e) {
                    console.error('Error filtering themes with filter: ', filter, e);
                    filteredThemes = backupDefinedThemes;
                    themingConfig.enabledThemes = backupEnabledThemes;
                }
            }

            if (themingConfig.enabledThemes.length === 0) {
                themingConfig.enabledThemes = Array.from(
                    new Set([
                        ...themingConfig.enabledThemes ?? [],
                        ...Object.keys(themingConfig.themes ?? {})
                    ])
                );
            }

            const excludedThemes = definedThemes.filter(dt => !filteredThemes.includes(dt));
            for (const excludedThemeCode of excludedThemes) {
                delete themingConfig.themes[excludedThemeCode];
            }

            if (excludedThemes.find(et => et === themingConfig.defaultThemeCode)) {
                themingConfig.defaultThemeCode = themingConfig.enabledThemes.slice(-1)[0];
                if (!themingConfig.defaultThemeCode) {
                    themingConfig.defaultThemeCode = Object.keys(themingConfig.themes ?? {})[0] ?? DefaultThemes[0];
                }
            }

            return themingConfig;
        } catch (e) {
            console.error('Error filtering theming config', e);
            return themingConfig;
        }
    }

    private loadThemes(): void {
        const enabledThemes = (this.themingConfig.enabledThemes ?? []).map(et => et.toLowerCase());
        if (enabledThemes.length === 0) {
            return;
        }

        let index = 0;
        for (const themeCode of enabledThemes) {
            const existingThemeCode = Object.keys(this.themingConfig.themes).find(tc => tc.toLowerCase() === themeCode);
            if (!existingThemeCode) {
                console.warn(`Theme with code ${themeCode} is not defined`);
                continue;
            }

            const theme = this.themingConfig.themes[existingThemeCode];
            const variants = Object.values(theme.variants ?? {});
            if (variants.length === 0) {
                console.warn(`Theme with code ${themeCode} has no variants`);
                continue;
            }

            for (const variant of variants) {
                const internalThemeVariant = new InternalThemeVariant(
                    index,
                    theme,
                    variant
                );
                this.themes[internalThemeVariant.id] = internalThemeVariant;
                index++;
            }
        }
    }

    private async selectCurrentTheme(themes: RuntimeThemeVariant[]): Promise<CurrentThemeResult | undefined> {
        let activeSelector: IThemeVariantSelector | undefined = undefined;
        let activeThemeVariant: RuntimeThemeVariant | undefined = undefined;
        for (const selector of Object.values(this.selectors)) {
            try {
                const themeVariant = await selector.selector.getSelectedThemeVariant(themes);
                if (ObjectUtility.isDefined(themeVariant)) {
                    if (!themes.some(t => t.id === themeVariant?.id)) {
                        return undefined;
                    }
                    activeSelector = selector.selector;
                    activeThemeVariant = themeVariant;
                }
            } catch (e) {
                console.error('Error selecting current theme', selector, e);
            }
        }

        if (!activeSelector || !activeThemeVariant) {
            return undefined;
        }

        return {
            selector: activeSelector,
            themeVariant: activeThemeVariant
        };
    }

    private getDefaultedThemeVariant(themingConfig: ThemingConfig, themes: RuntimeThemeVariant[]): RuntimeThemeVariant {
        let defaultThemeCode = themingConfig.defaultThemeCode;
        if (!defaultThemeCode || !themingConfig.enabledThemes.some(t => t === defaultThemeCode)) {
            defaultThemeCode = themingConfig.enabledThemes.slice(-1)[0] ?? Object.keys(themingConfig.themes)[0];
        }
        const defaultTheme = (Object.values(themingConfig.themes).find(t => t.code === defaultThemeCode) ?? Object.values(themingConfig)[0]) as Theme;
        const defaultVariantCode = defaultTheme.defaultVariantCode ?? Object.keys(defaultTheme.variants ?? {})[0];
        const defaultVariant = (Object.values(defaultTheme.variants ?? {}).find(v => v.code === defaultVariantCode) ?? Object.values(defaultTheme.variants ?? {})[0]) as ThemeVariant;
        return themes.find(tv => tv.theme.code === defaultTheme.code && tv.variant.code === defaultVariant.code);
    }

    private async internalSetCurrentTheme(themeVariant?: RuntimeThemeVariant | undefined, notify?: boolean, store?: boolean, storeUniqueCode?: string): Promise<RuntimeThemeVariant | undefined> {
        if (themeVariant?.id === this.currentThemeVariant?.id) {
            return themeVariant;
        }

        this.currentThemeVariant = _.cloneDeep(themeVariant);

        if (store) {
            await this.storeSelectedThemeVariant(themeVariant, storeUniqueCode);
        }

        if (notify) {
            await this.notifyThemeChanged(this.currentThemeVariant);
        }

        return themeVariant;
    }

    private async storeSelectedThemeVariant(themeVariant?: RuntimeThemeVariant, storeUniqueCode?: string): Promise<void> {
        for (const selector of Object.values(this.selectors)) {
            if (storeUniqueCode && selector.uniqueCode === storeUniqueCode.toLowerCase()) {
                continue;
            }
            try {
                await selector.store?.setSelectedThemeVariant(themeVariant, storeUniqueCode);
            } catch (e) {
                console.error('Failed to store selected theme variant', e);
            }
        }
    }

    private async notifyThemeChanged(themeVariant?: RuntimeThemeVariant): Promise<void> {
        this.eventBus.dispatchBroadcast<ThemeChangedEvent>(ThemingServiceTypeName, ThemingEvents.THEME_CHANGED_EVENT, {
            broadcasterId: ThemingService.BROADCASTER_ID,
            theme: themeVariant
        }, undefined, true);
    }

    private async onSelectedThemeVariantChanged(storeUniqueCode: string, themeVariant?: RuntimeThemeVariant): Promise<void> {
        await this.internalSetCurrentTheme(themeVariant, true, true, storeUniqueCode);
    }

    private clearThemingContext(): void {
        this.themingConfig = {};
        this.themes = {};
    }
}
