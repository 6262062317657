/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {MicroFrontendTypeEnum} from "sirius-platform-support-library/models/micro-frontends/micro-frontend-type.enum";
import {
    IMicroFrontendLoader
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loader.interface";
import {
    MicroFrontendLoadingOptions
} from "sirius-platform-support-library/shared/micro-frontends/dynamic-loading/micro-frontend-loading.options";

/* Webpack types */
type Factory = () => any;

interface Container {
    init(shareScope: any): void;

    get(module: string): Factory;
}

declare const __webpack_init_sharing__: (shareScope: string) => Promise<void>;
declare const __webpack_share_scopes__: { default: any };

export const FederatedModuleMicroFrontendLoaderTypeName = 'FederatedModuleMicroFrontendLoader';

export class FederatedModuleMicroFrontendLoader implements IMicroFrontendLoader {
    private static readonly resourceMap: Record<string, boolean> = {};

    public get supportedMicroFrontendTypes(): MicroFrontendTypeEnum[] {
        return [MicroFrontendTypeEnum.FEDERATED_MODULE];
    }

    public supportsMicroFrontendType(microFrontendType: MicroFrontendTypeEnum): boolean {
        return this.supportedMicroFrontendTypes.indexOf(microFrontendType, 0) > -1
    }

    public async load(options: MicroFrontendLoadingOptions): Promise<any> {
        await this.loadRemoteEntry(options.remoteName, options.remoteEntry);
        return await this.findExposedModule<any>(
            options.remoteName,
            options.component
        );
    }

    private findExposedModule = async <T>(remoteName: string, moduleName: string): Promise<T | undefined> => {
        await __webpack_init_sharing__('default');
        const container: Container = (window as any)[remoteName]; // or get the container somewhere else
        await container.init(__webpack_share_scopes__.default);
        const factory = await container.get(moduleName); // moduleName
        return factory();
    }

    private loadRemoteEntry(remoteName: string, remoteEntry: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (FederatedModuleMicroFrontendLoader.resourceMap[remoteEntry]) {
                resolve();
                return;
            }

            const scriptNode = document.createElement("script");
            scriptNode.src = remoteEntry;
            scriptNode.type = 'text/javascript';
            scriptNode.async = true;
            scriptNode.setAttribute('module', remoteName);

            scriptNode.onerror = (error: string | Event) => {
                console.error(error, 'Unable to load remote entry');
                reject(error);
            }
            scriptNode.onload = () => {
                FederatedModuleMicroFrontendLoader.resourceMap[remoteEntry] = true;
                resolve();
            };

            document.head.append(scriptNode);
        });
    }
}
