/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {HistoryEntry} from "./history-entry";
import {PlatformBrowserNavigationService} from "./platform-browser-navigation.service";
import {NavigationShim} from "./shims/navigation.shim";
import {sanitizeUrl} from "@braintree/sanitize-url";

export const BrowserEventsInterceptorTypeName = 'BrowserEventsInterceptor';

export class BrowserEventsInterceptor implements IAfterPlatformReadyInit {

    private readonly window: Window;
    private readonly platformBrowserNavigationService: PlatformBrowserNavigationService;

    // Flag to allow initial routing
    private initialLoad: boolean = false;

    // Flag to signal manual event processing
    private eventHandled: boolean = false;
    private patchState: boolean = false;

    private previousNavigationId: number = 0;
    private previousUrl: string = '';
    private previousRequestedUrl: string = ''

    public constructor(
        window: Window,
        platformBrowserNavigationService: PlatformBrowserNavigationService,
    ) {
        this.window = window;
        NavigationShim.apply(this.window);
        this.platformBrowserNavigationService = platformBrowserNavigationService;
    }

    public async init(): Promise<void> {
        this.setupProxies();
        this.bind();
    }

    public isNavigationAllowed(url?: string | URL | undefined, hardNavigation?: boolean): boolean {
        try {
            return this.platformBrowserNavigationService.isNavigationAllowed(url?.toString(), hardNavigation);
        } catch (e) {
            console.error(e);
            return true;
        } finally {
            this.previousRequestedUrl = url?.toString() || '';
        }
    }

    private setupProxies(): void {
        const self = this;

        (function (proxied) {
            self.window.history.pushState = (data, unused, url) => {
                self.beforeNavigation(url?.toString());
                if (self.initialLoad && !self.isNavigationAllowed(url)) {
                    return;
                }
                const result = proxied.apply(self.window.history, [data, unused, url]);
                self.afterNavigation(url?.toString());
                return result;
            };
        })(this.window.history.pushState);

        (function (proxied) {
            self.window.history.replaceState = (data, unused, url) => {
                if (!self.eventHandled) {
                    self.beforeNavigation(url?.toString());
                }
                if (self.initialLoad && !self.eventHandled && !self.isNavigationAllowed(url)) {
                    return;
                }
                const result = proxied.apply(self.window.history, [data, unused, url]);
                if (!self.eventHandled) {
                    self.afterNavigation(url?.toString());
                }
                return result;
            };
        })(this.window.history.replaceState);

        (function (proxied) {
            self.window.history.go = (delta) => {
                const targetEntry = self.getHistoryEntry(delta);
                if (!self.eventHandled) {
                    self.beforeNavigation(targetEntry?.url);
                }
                if (self.initialLoad && !self.eventHandled && targetEntry && !self.isNavigationAllowed(targetEntry?.url)) {
                    return;
                }
                const result = proxied.apply(self.window.history, [delta]);
                if (!self.eventHandled) {
                    self.afterNavigation(targetEntry?.url);
                }
                return result;
            };
        })(this.window.history.go);

        (function (proxied) {
            self.window.history.back = () => {
                const targetEntry = self.getHistoryEntry(-1);
                self.beforeNavigation(targetEntry?.url);
                if (self.initialLoad && targetEntry && !self.isNavigationAllowed(targetEntry?.url)) {
                    return;
                }
                const result = proxied.apply(self.window.history, []);
                self.afterNavigation(targetEntry?.url);
                return result;
            };
        })(this.window.history.back);

        (function (proxied) {
            self.window.history.forward = () => {
                const targetEntry = self.getHistoryEntry(1);
                self.beforeNavigation(targetEntry?.url);
                if (self.initialLoad && targetEntry && !self.isNavigationAllowed(targetEntry?.url)) {
                    return;
                }
                const result = proxied.apply(self.window.history, []);
                self.afterNavigation(targetEntry?.url);
                return result;
            };
        })(this.window.history.forward);
    }

    private bind(): void {
        this.window.addEventListener('single-spa:routing-event', () => {
            this.initialLoad = true;
        });

        this.window.addEventListener('hashchange', () => {
            this.previousUrl = sanitizeUrl(this.window.location.href);
        });

        this.window.addEventListener('popstate', async (event: PopStateEvent) => {
            if (this.eventHandled) {
                return;
            }
            const currentUrl = sanitizeUrl(this.window.location.href);
            const currentNavigationId = this.getCurrentHistoryEntry()?.index ?? -1;
            let direction = 0;
            if (this.previousNavigationId < currentNavigationId) {
                direction = 1;
            } else if (this.previousNavigationId > currentNavigationId) {
                direction = -1;
            }
            if (this.previousUrl === currentUrl) {
                this.previousNavigationId = currentNavigationId;
                return;
            }
            this.beforeNavigation(currentUrl);
            if (!this.isNavigationAllowed(currentUrl)) {
                this.eventHandled = true;
                if (direction !== 0) {
                    this.patchState = true;
                    this.window.history.go(direction * -1);
                }
                this.eventHandled = false;
                return;
            } else if (this.patchState) {
                this.eventHandled = true;
                this.patchState = false;
                this.window.history.replaceState(null, null, currentUrl);
                this.eventHandled = false;
            }
            this.afterNavigation(currentUrl);
            this.previousUrl = currentUrl;
            this.previousNavigationId = currentNavigationId;
        });

        this.window.onload = () => {
            this.previousUrl = sanitizeUrl(this.window.location.href);
            this.window.document.body.click();
        };

        this.window.addEventListener("beforeunload", (event: BeforeUnloadEvent) => {
            const url = sanitizeUrl(this.window.location.href);
            this.beforeNavigation(url);
            const navigationAllowed = this.isNavigationAllowed(url, true);
            if (!navigationAllowed) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                return event.returnValue = "Prompt";
            }
            this.afterNavigation(url);
        }, {capture: true});

        this.window.onpagehide = (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
            const url = sanitizeUrl(this.window.location.href);
            this.platformBrowserNavigationService.onPageUnload(url);
        }
    }

    private beforeNavigation(url?: string): void {
        this.platformBrowserNavigationService.dispatchBeforeNavigationEvent(url);
    }

    private afterNavigation(url?: string): void {
        this.platformBrowserNavigationService.dispatchAfterNavigationEvent(url);
    }

    private getCurrentHistoryEntry(): HistoryEntry | undefined {
        // @ts-ignore
        return this.window.navigation.currentEntry as HistoryEntry;
    }

    private getHistoryEntry(delta: number): HistoryEntry | undefined {
        const currentEntry = this.getCurrentHistoryEntry();
        if (!currentEntry) {
            return undefined;
        }
        const index = currentEntry.index + delta;
        // @ts-ignore
        return this.window.navigation.entries().find(e => e.index === index) as HistoryEntry;
    }
}
